<svg
  width="161"
  height="161"
  viewBox="0 0 161 161"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M107.261 11.5565C107.261 11.5565 83.6105 4.59167 60.2558 18.6759C36.9011 32.7602 24.9936 59.0322 14.7765 80.1071C4.55945 101.182 10.2234 121.832 27.9206 130.503C45.6177 139.175 57.7925 124.511 82.7153 122.601C107.638 120.692 114.316 123.796 135.082 109.206C155.848 94.6164 159.799 62.3681 143.644 39.4449C127.489 16.5218 107.261 11.5565 107.261 11.5565Z"
    fill="var(--store-primary-color)" />
  <path
    opacity="0.7"
    d="M107.261 11.5565C107.261 11.5565 83.6105 4.59167 60.2558 18.6759C36.9011 32.7602 24.9936 59.0322 14.7765 80.1071C4.55945 101.182 10.2234 121.832 27.9206 130.503C45.6177 139.175 57.7925 124.511 82.7153 122.601C107.638 120.692 114.316 123.796 135.082 109.206C155.848 94.6164 159.799 62.3681 143.644 39.4449C127.489 16.5218 107.261 11.5565 107.261 11.5565Z"
    fill="white" />
  <path d="M147.031 72.3403H17.6968V78.336H147.031V72.3403Z" fill="#263238" />
  <path d="M148.764 33.1658H15.7778V75.9918H148.764V33.1658Z" fill="white" />
  <mask
    id="mask0_3590_584"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="15"
    y="33"
    width="134"
    height="43">
    <path d="M148.764 33.1658H15.7778V75.9918H148.764V33.1658Z" fill="white" />
  </mask>
  <g mask="url(#mask0_3590_584)">
    <path
      d="M59.0418 33.1658V54.6206H55.7252C51.5392 55.0618 49.0953 51.7484 49.9808 46.664C50.8663 41.5796 36.4986 49.5395 33.1852 46.664C30.8379 44.6322 39.1197 37.2874 44.159 33.1658H59.0418ZM124.347 44.79C126.797 50.586 152.731 34.4538 146.951 43.7403C141.171 53.0267 135.211 59.3347 148.178 56.5333L148.764 56.3949V33.1658H136.46C130.577 36.2344 122.785 41.1127 124.347 44.79Z"
      fill="var(--store-primary-color)" />
    <path
      opacity="0.5"
      d="M59.0418 33.1658V54.6206H55.7252C51.5392 55.0618 49.0953 51.7484 49.9808 46.664C50.8663 41.5796 36.4986 49.5395 33.1852 46.664C30.8379 44.6322 39.1197 37.2874 44.159 33.1658H59.0418ZM124.347 44.79C126.797 50.586 152.731 34.4538 146.951 43.7403C141.171 53.0267 135.211 59.3347 148.178 56.5333L148.764 56.3949V33.1658H136.46C130.577 36.2344 122.785 41.1127 124.347 44.79Z"
      fill="white" />
  </g>
  <path
    d="M148.764 33.1658H15.7778V75.9918H148.764V33.1658Z"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path d="M105.581 33.1658H58.9585V75.9918H105.581V33.1658Z" fill="white" />
  <mask
    id="mask1_3590_584"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="58"
    y="33"
    width="48"
    height="43">
    <path d="M105.581 33.1658H58.9585V75.9918H105.581V33.1658Z" fill="white" />
  </mask>
  <g mask="url(#mask1_3590_584)">
    <path
      d="M90.0249 56.028C84.9148 58.7586 70.1575 70.6758 63.666 75.992H105.584V33.6941C100.11 35.9223 79.2476 44.6228 78.0981 47.978C76.7714 51.842 96.6517 52.486 90.0249 56.028Z"
      fill="var(--store-primary-color)" />
    <path
      opacity="0.5"
      d="M90.0249 56.028C84.9148 58.7586 70.1575 70.6758 63.666 75.992H105.584V33.6941C100.11 35.9223 79.2476 44.6228 78.0981 47.978C76.7714 51.842 96.6517 52.486 90.0249 56.028Z"
      fill="white" />
  </g>
  <path
    d="M105.581 33.1658H58.9585V75.9918H105.581V33.1658Z"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M108.655 49.4623C110.037 52.4955 113.92 54.8204 115.791 55.3839C115.791 55.3839 120.801 57.1355 124.559 54.2311C128.317 51.3267 129.144 45.6917 125.599 40.2048C122.054 34.7179 114.48 35.8996 111.234 38.9876C107.989 42.0756 107.277 46.429 108.655 49.4623ZM119.14 44.4359C120.36 45.4985 120.428 47.0119 119.22 47.7428C118.881 47.9372 118.495 48.0362 118.104 48.0294C117.713 48.0226 117.331 47.9102 116.998 47.7042C116.619 47.4983 116.331 47.1579 116.191 46.7502C116.051 46.3424 116.069 45.8969 116.242 45.5017C116.596 44.6387 117.929 43.37 119.14 44.4359Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M111.412 39.416C110.942 40.9197 110.713 41.348 109.937 42.0886C109.161 42.8292 108.749 44.0624 108.842 44.7129C108.953 45.4847 108.988 46.2656 108.945 47.0442C108.928 47.5781 109.035 48.1087 109.259 48.5939C109.482 49.0791 109.816 49.5054 110.233 49.8391C110.92 50.3396 111.51 50.9604 111.975 51.6713C112.655 52.6083 112.432 52.8208 113.45 53.3328C114.467 53.8448 116.39 54.8688 116.39 54.8688C116.39 54.8688 118.586 55.8348 119.571 55.4355C120.557 55.0362 121.729 53.5969 122.495 53.291C123.261 52.9851 123.619 53.8062 124.633 52.325C125.647 50.8438 125.944 49.7039 126.565 49.1211C127.186 48.5382 127.335 46.4871 126.491 45.6016C125.639 44.7812 125.142 43.6602 125.106 42.4782C124.981 41.0131 124.569 40.134 123.013 39.6092C121.458 39.0843 120.296 37.7545 119.713 37.2521C119.13 36.7498 117.555 36.8722 116.567 37.3874C115.578 37.9026 114.68 37.0654 113.64 37.5323C113.318 37.6804 112.996 37.7738 112.742 37.88C112.466 38.0414 112.199 38.2177 111.943 38.4081C111.698 38.7037 111.517 39.0469 111.412 39.416ZM116.377 44.758C117.021 44.8803 117.378 43.6986 118.515 43.6728C119.652 43.6471 118.988 44.2814 119.159 44.4456C119.424 44.6735 119.648 44.9446 119.822 45.2474C120.016 45.4955 120.147 45.7874 120.202 46.0975C120.184 46.6256 120.342 47.1448 120.653 47.5722C121.017 48.0681 120.206 48.1035 119.497 48.2484C118.789 48.3933 118.737 48.3354 118.183 48.754C117.63 49.1726 116.837 48.4513 116.837 48.4513C116.837 48.4513 116.354 48.2774 115.888 47.4531C115.421 46.6288 115.913 46.4871 115.495 45.8173C115.076 45.1476 115.73 44.6163 116.377 44.758Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M116.68 40.7876C116.99 40.7876 117.241 40.5367 117.241 40.2273C117.241 39.9178 116.99 39.667 116.68 39.667C116.371 39.667 116.12 39.9178 116.12 40.2273C116.12 40.5367 116.371 40.7876 116.68 40.7876Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M113.315 41.8311C113.449 41.8311 113.557 41.723 113.557 41.5896C113.557 41.4563 113.449 41.3481 113.315 41.3481C113.182 41.3481 113.074 41.4563 113.074 41.5896C113.074 41.723 113.182 41.8311 113.315 41.8311Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M114.716 39.1842C114.915 39.1842 115.077 39.0227 115.077 38.8235C115.077 38.6244 114.915 38.4629 114.716 38.4629C114.517 38.4629 114.355 38.6244 114.355 38.8235C114.355 39.0227 114.517 39.1842 114.716 39.1842Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M112.993 44.3942C112.993 44.5216 112.955 44.6461 112.885 44.752C112.814 44.8579 112.713 44.9405 112.596 44.9892C112.478 45.038 112.348 45.0507 112.223 45.0259C112.099 45.001 111.984 44.9397 111.894 44.8496C111.804 44.7596 111.742 44.6448 111.717 44.5199C111.693 44.395 111.705 44.2655 111.754 44.1478C111.803 44.0301 111.885 43.9295 111.991 43.8588C112.097 43.788 112.222 43.7502 112.349 43.7502C112.52 43.7502 112.684 43.8181 112.804 43.9389C112.925 44.0596 112.993 44.2234 112.993 44.3942Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M120.447 42.3525C120.447 42.4398 120.421 42.5251 120.372 42.5976C120.324 42.6702 120.255 42.7267 120.174 42.7601C120.094 42.7935 120.005 42.8022 119.92 42.7852C119.834 42.7682 119.755 42.7262 119.694 42.6645C119.632 42.6028 119.59 42.5242 119.573 42.4386C119.556 42.353 119.565 42.2643 119.598 42.1837C119.631 42.1031 119.688 42.0342 119.761 41.9857C119.833 41.9373 119.918 41.9114 120.006 41.9114C120.064 41.911 120.121 41.9221 120.175 41.9441C120.229 41.9661 120.277 41.9986 120.318 42.0396C120.36 42.0807 120.392 42.1295 120.414 42.1832C120.436 42.2369 120.447 42.2945 120.447 42.3525Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M120.328 40.066C120.528 40.066 120.689 39.9045 120.689 39.7054C120.689 39.5062 120.528 39.3447 120.328 39.3447C120.129 39.3447 119.968 39.5062 119.968 39.7054C119.968 39.9045 120.129 40.066 120.328 40.066Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M123.648 42.7514C123.648 42.8546 123.617 42.9554 123.56 43.0412C123.503 43.127 123.421 43.1938 123.326 43.2333C123.23 43.2728 123.126 43.2831 123.024 43.263C122.923 43.2429 122.83 43.1932 122.757 43.1202C122.684 43.0473 122.635 42.9543 122.615 42.8531C122.594 42.752 122.605 42.6471 122.644 42.5518C122.684 42.4564 122.751 42.375 122.836 42.3177C122.922 42.2603 123.023 42.2297 123.126 42.2297C123.264 42.2306 123.396 42.2858 123.494 42.3835C123.592 42.4811 123.647 42.6133 123.648 42.7514Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M124.292 47.1599C124.292 47.2471 124.266 47.3324 124.218 47.405C124.169 47.4775 124.1 47.5341 124.02 47.5674C123.939 47.6008 123.85 47.6096 123.765 47.5926C123.679 47.5755 123.601 47.5335 123.539 47.4718C123.477 47.4101 123.435 47.3315 123.418 47.246C123.401 47.1604 123.41 47.0717 123.443 46.9911C123.477 46.9105 123.533 46.8416 123.606 46.7931C123.678 46.7446 123.764 46.7188 123.851 46.7188C123.968 46.7188 124.08 46.7652 124.163 46.848C124.245 46.9307 124.292 47.0429 124.292 47.1599Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M121.57 51.6873C121.571 51.783 121.543 51.8767 121.491 51.9565C121.438 52.0364 121.363 52.0988 121.275 52.1358C121.186 52.1729 121.089 52.1829 120.995 52.1646C120.901 52.1464 120.815 52.1006 120.747 52.0332C120.679 51.9658 120.633 51.8797 120.614 51.7859C120.595 51.6922 120.604 51.5949 120.641 51.5064C120.677 51.418 120.739 51.3424 120.819 51.2891C120.898 51.2359 120.992 51.2075 121.087 51.2075C121.215 51.2075 121.337 51.258 121.428 51.3478C121.518 51.4377 121.57 51.5598 121.57 51.6873Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M115.959 53.1296C116.357 53.1296 116.68 52.8066 116.68 52.4083C116.68 52.0099 116.357 51.687 115.959 51.687C115.561 51.687 115.238 52.0099 115.238 52.4083C115.238 52.8066 115.561 53.1296 115.959 53.1296Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M113.273 48.4027C113.472 48.4027 113.634 48.2412 113.634 48.042C113.634 47.8429 113.472 47.6814 113.273 47.6814C113.074 47.6814 112.913 47.8429 112.913 48.042C112.913 48.2412 113.074 48.4027 113.273 48.4027Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M117.164 49.8452C117.164 49.9089 117.145 49.9711 117.109 50.0241C117.074 50.077 117.024 50.1183 116.965 50.1427C116.906 50.1671 116.841 50.1734 116.779 50.161C116.716 50.1486 116.659 50.1179 116.614 50.0729C116.569 50.0278 116.538 49.9705 116.526 49.908C116.513 49.8455 116.52 49.7808 116.544 49.722C116.568 49.6631 116.61 49.6128 116.663 49.5775C116.716 49.5421 116.778 49.5232 116.842 49.5232C116.927 49.5232 117.009 49.5571 117.069 49.6175C117.13 49.6779 117.164 49.7598 117.164 49.8452Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M110.829 46.3678C110.829 46.4232 110.812 46.4774 110.782 46.5234C110.751 46.5695 110.707 46.6054 110.656 46.6266C110.605 46.6478 110.548 46.6534 110.494 46.6425C110.44 46.6317 110.39 46.6051 110.351 46.5659C110.311 46.5267 110.285 46.4768 110.274 46.4224C110.263 46.3681 110.269 46.3118 110.29 46.2606C110.311 46.2094 110.347 46.1656 110.393 46.1349C110.439 46.1041 110.493 46.0876 110.549 46.0876C110.623 46.0876 110.694 46.1172 110.747 46.1697C110.799 46.2222 110.829 46.2935 110.829 46.3678Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M113.074 39.1068C113.074 39.1545 113.06 39.2012 113.033 39.2409C113.007 39.2806 112.969 39.3116 112.925 39.3299C112.881 39.3482 112.832 39.3529 112.785 39.3436C112.738 39.3343 112.695 39.3113 112.662 39.2775C112.628 39.2437 112.605 39.2007 112.595 39.1539C112.586 39.107 112.591 39.0585 112.609 39.0143C112.627 38.9702 112.658 38.9325 112.698 38.906C112.738 38.8794 112.785 38.8653 112.832 38.8653C112.864 38.8648 112.896 38.8708 112.925 38.8828C112.955 38.8947 112.982 38.9125 113.004 38.935C113.027 38.9575 113.044 38.9843 113.056 39.0138C113.068 39.0433 113.074 39.0749 113.074 39.1068Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M116.039 42.3525C116.039 42.3787 116.034 42.4047 116.024 42.4289C116.014 42.4531 115.999 42.4751 115.981 42.4937C115.962 42.5122 115.94 42.5269 115.916 42.537C115.892 42.547 115.866 42.5522 115.839 42.5522C115.79 42.5459 115.745 42.5221 115.713 42.485C115.68 42.448 115.662 42.4003 115.662 42.3509C115.662 42.3015 115.68 42.2538 115.713 42.2168C115.745 42.1797 115.79 42.1559 115.839 42.1497C115.893 42.1505 115.943 42.1723 115.981 42.2102C116.018 42.2482 116.039 42.2993 116.039 42.3525Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M121.889 45.1571C121.889 45.2125 121.873 45.2667 121.842 45.3127C121.811 45.3588 121.768 45.3947 121.716 45.4159C121.665 45.4371 121.609 45.4427 121.555 45.4318C121.5 45.421 121.45 45.3944 121.411 45.3552C121.372 45.316 121.345 45.2661 121.334 45.2117C121.324 45.1574 121.329 45.1011 121.35 45.0499C121.372 44.9987 121.408 44.9549 121.454 44.9242C121.5 44.8934 121.554 44.877 121.609 44.877C121.646 44.877 121.682 44.8842 121.716 44.8983C121.75 44.9124 121.781 44.933 121.807 44.959C121.833 44.985 121.854 45.0159 121.868 45.0499C121.882 45.0839 121.889 45.1203 121.889 45.1571Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M124.537 49.6844C124.537 49.78 124.51 49.8737 124.457 49.9536C124.404 50.0334 124.329 50.0959 124.241 50.1329C124.153 50.17 124.055 50.18 123.962 50.1617C123.868 50.1434 123.781 50.0977 123.713 50.0303C123.646 49.9629 123.599 49.8768 123.58 49.783C123.561 49.6892 123.571 49.592 123.607 49.5035C123.644 49.4151 123.706 49.3394 123.785 49.2862C123.865 49.233 123.958 49.2046 124.054 49.2046C124.117 49.2042 124.18 49.2163 124.238 49.2402C124.297 49.2641 124.35 49.2994 124.395 49.344C124.44 49.3886 124.476 49.4416 124.5 49.5C124.524 49.5584 124.537 49.6211 124.537 49.6844Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M114.996 45.7239C114.996 45.8113 114.97 45.8967 114.921 45.9693C114.873 46.0419 114.804 46.0984 114.723 46.1317C114.642 46.165 114.553 46.1736 114.468 46.1563C114.382 46.139 114.303 46.0967 114.242 46.0346C114.18 45.9726 114.139 45.8937 114.122 45.8079C114.105 45.7222 114.114 45.6334 114.148 45.5528C114.182 45.4723 114.239 45.4036 114.312 45.3555C114.385 45.3074 114.471 45.2821 114.558 45.2827C114.616 45.2827 114.673 45.2942 114.726 45.3164C114.78 45.3386 114.828 45.3711 114.869 45.4121C114.91 45.4531 114.942 45.5018 114.964 45.5553C114.985 45.6088 114.996 45.6661 114.996 45.7239Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M112.993 49.6845C112.993 49.7482 112.974 49.8105 112.939 49.8634C112.903 49.9164 112.853 49.9577 112.794 49.982C112.736 50.0064 112.671 50.0128 112.608 50.0004C112.546 49.9879 112.488 49.9573 112.443 49.9122C112.398 49.8672 112.368 49.8098 112.355 49.7474C112.343 49.6849 112.349 49.6202 112.374 49.5613C112.398 49.5025 112.439 49.4522 112.492 49.4168C112.545 49.3814 112.607 49.3625 112.671 49.3625C112.757 49.3625 112.838 49.3965 112.899 49.4569C112.959 49.5172 112.993 49.5991 112.993 49.6845Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M111.232 43.6725C111.233 43.7522 111.21 43.8303 111.166 43.897C111.122 43.9636 111.059 44.0157 110.986 44.0466C110.913 44.0776 110.831 44.086 110.753 44.0708C110.675 44.0557 110.603 44.0176 110.546 43.9614C110.49 43.9053 110.451 43.8336 110.435 43.7554C110.419 43.6772 110.427 43.5962 110.458 43.5224C110.488 43.4487 110.54 43.3856 110.606 43.3413C110.672 43.2969 110.75 43.2732 110.83 43.2732C110.936 43.2732 111.038 43.3152 111.113 43.3899C111.189 43.4647 111.231 43.5663 111.232 43.6725Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M118.444 38.5432C118.445 38.5911 118.431 38.6381 118.405 38.6782C118.379 38.7184 118.341 38.7498 118.297 38.7686C118.253 38.7874 118.204 38.7926 118.157 38.7837C118.11 38.7747 118.067 38.752 118.033 38.7183C117.999 38.6847 117.975 38.6417 117.966 38.5947C117.956 38.5478 117.961 38.4991 117.979 38.4548C117.997 38.4105 118.028 38.3725 118.068 38.3459C118.108 38.3192 118.155 38.3049 118.202 38.3049C118.266 38.3049 118.327 38.3299 118.372 38.3745C118.417 38.4191 118.443 38.4797 118.444 38.5432Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M36.7497 48.5735C36.6203 48.561 36.4971 48.512 36.3945 48.4322C36.2918 48.3524 36.214 48.245 36.1701 48.1227C35.7848 47.057 35.1022 46.1241 34.2033 45.4342C33.3044 44.7443 32.2266 44.3264 31.0976 44.2298C29.9685 44.1333 28.8355 44.3622 27.8324 44.8894C26.8294 45.4167 25.9984 46.2202 25.4378 47.205C25.3745 47.3181 25.2804 47.4109 25.1663 47.4725C25.0523 47.534 24.9231 47.5619 24.7938 47.5527L24.05 47.4883C23.9308 47.4775 23.8109 47.4984 23.7024 47.5488C23.5938 47.5991 23.5005 47.6773 23.4319 47.7754C23.3633 47.8734 23.3218 47.9878 23.3116 48.107C23.3014 48.2263 23.3228 48.3461 23.3738 48.4543L24.7036 51.42C24.8099 51.6615 24.9162 51.903 25.0063 52.1509C25.4149 53.2985 26.1669 54.2925 27.1602 54.9978C28.1535 55.7031 29.3399 56.0854 30.5581 56.0928C31.7762 56.1001 32.9671 55.7323 33.9689 55.0391C34.9707 54.3459 35.7347 53.3611 36.1572 52.2185C36.3633 51.655 36.5822 51.0915 36.8366 50.5473L37.3035 49.5491C37.3529 49.45 37.3772 49.3401 37.3742 49.2294C37.3711 49.1186 37.341 49.0103 37.2863 48.9139C37.2316 48.8175 37.154 48.7361 37.0604 48.6767C36.9669 48.6174 36.8602 48.5819 36.7497 48.5735Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M36.7496 48.5736C36.6202 48.5611 36.497 48.5121 36.3944 48.4323C36.2917 48.3525 36.2139 48.2452 36.17 48.1228C36.0201 47.7105 35.8246 47.3163 35.5871 46.9475L34.4537 49.8069C32.5559 51.8786 30.5355 53.8345 28.4033 55.664C29.0994 55.9392 29.8412 56.0802 30.5897 56.0794H30.8409C32.7405 54.875 34.5399 53.5193 36.2215 52.0255C36.4115 51.5231 36.6047 51.024 36.8333 50.541L37.3002 49.5428C37.3481 49.4441 37.3713 49.3352 37.3678 49.2256C37.3643 49.1159 37.3341 49.0087 37.2799 48.9133C37.2257 48.8179 37.1491 48.7371 37.0567 48.678C36.9643 48.6188 36.8589 48.583 36.7496 48.5736Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M30.5963 52.7435C35.4637 52.7435 39.4095 48.7977 39.4095 43.9303C39.4095 39.063 35.4637 35.1172 30.5963 35.1172C25.729 35.1172 21.7832 39.063 21.7832 43.9303C21.7832 48.7977 25.729 52.7435 30.5963 52.7435Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M30.5968 51.5679C34.8151 51.5679 38.2347 48.1483 38.2347 43.9301C38.2347 39.7118 34.8151 36.2922 30.5968 36.2922C26.3786 36.2922 22.959 39.7118 22.959 43.9301C22.959 48.1483 26.3786 51.5679 30.5968 51.5679Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M30.5963 49.8615C33.872 49.8615 36.5275 47.206 36.5275 43.9303C36.5275 40.6545 33.872 37.999 30.5963 37.999C27.3205 37.999 24.665 40.6545 24.665 43.9303C24.665 47.206 27.3205 49.8615 30.5963 49.8615Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M26.8291 39.7958L19.5809 36.125L18.998 37.9185L25.8566 41.4927C26.0217 41.6057 26.2169 41.6663 26.4169 41.6666C26.6495 41.6838 26.8804 41.6164 27.0671 41.4766C27.2538 41.3369 27.3836 41.1344 27.4326 40.9064C27.4817 40.6785 27.4466 40.4405 27.3339 40.2363C27.2212 40.0322 27.0385 39.8757 26.8194 39.7958H26.8291Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M19.9867 36.9399C19.9867 37.1309 19.93 37.3177 19.8239 37.4766C19.7177 37.6354 19.5669 37.7592 19.3904 37.8323C19.2138 37.9055 19.0196 37.9246 18.8322 37.8873C18.6448 37.85 18.4727 37.758 18.3376 37.6229C18.2025 37.4878 18.1105 37.3157 18.0732 37.1283C18.036 36.9409 18.0551 36.7467 18.1282 36.5702C18.2013 36.3937 18.3251 36.2428 18.484 36.1367C18.6429 36.0305 18.8296 35.9739 19.0207 35.9739C19.2769 35.9739 19.5226 36.0757 19.7038 36.2568C19.8849 36.438 19.9867 36.6837 19.9867 36.9399Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 38.5852H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 38.5852H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 41.8855H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 41.8855H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 45.1892H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 45.1892H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 48.4932H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 48.4932H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 51.7966H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 51.7966H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 58.5649H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 58.5649H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 61.8689H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 61.8689H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 65.1726H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 65.1726H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 68.4763H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 68.4763H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M66.3286 71.7771H85.6518"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M91.1001 71.7771H99.192"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M110.588 58.5649H129.911"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M135.359 58.5649H143.451"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M110.588 61.8689H129.911"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M135.359 61.8689H143.451"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M110.588 65.1726H129.911"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M135.359 65.1726H143.451"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M110.588 68.4763H129.911"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M135.359 68.4763H143.451"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M110.588 71.7771H129.911"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M135.359 71.7771H143.451"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M20.2539 58.8967H39.5739"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M45.0254 58.8967H53.1173"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M20.2539 62.2007H39.5739"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M45.0254 62.2007H53.1173"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M20.2539 65.5044H39.5739"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M45.0254 65.5044H53.1173"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M20.2539 68.8044H39.5739"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M45.0254 68.8044H53.1173"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M20.2539 72.1086H39.5739"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M45.0254 72.1086H53.1173"
    stroke="#263238"
    stroke-width="0.322"
    stroke-miterlimit="10" />
  <path
    d="M32.3289 89.8378C32.3289 89.8378 32.3289 88.5981 30.5965 86.8657C28.8642 85.1334 27.7468 84.1416 27.7468 84.1416C27.7468 84.1416 26.8807 86.9913 28.4907 88.7237C29.6749 89.9426 30.9588 91.0606 32.3289 92.066V89.8378Z"
    fill="var(--store-primary-color)" />
  <path
    d="M29.8138 97.5658C29.8138 97.5658 29.8138 96.3293 28.0782 94.5969C26.3426 92.8646 25.2317 91.8728 25.2317 91.8728C25.2317 91.8728 24.3655 94.7193 25.9755 96.4516C27.1588 97.6715 28.4428 98.7896 29.8138 99.794V97.5658Z"
    fill="var(--store-primary-color)" />
  <path
    d="M32.2194 91.9407C32.2194 91.9407 32.3482 90.7107 34.2545 89.1715C36.1607 87.6323 37.3747 86.7629 37.3747 86.7629C37.3747 86.7629 37.9349 89.6867 36.1511 91.2387C34.8466 92.3275 33.4528 93.3044 31.9844 94.1593L32.2194 91.9407Z"
    fill="var(--store-primary-color)" />
  <path
    d="M29.1798 101.072C29.1798 101.072 29.4406 99.8615 31.4982 98.5284C33.5558 97.1953 34.8534 96.4644 34.8534 96.4644C34.8534 96.4644 35.0982 99.4268 33.1662 100.786C31.7538 101.731 30.264 102.555 28.7129 103.249L29.1798 101.072Z"
    fill="var(--store-primary-color)" />
  <path
    d="M29.7524 111.025C29.7524 111.025 29.7524 109.786 31.4848 108.053C33.2172 106.321 34.3313 105.332 34.3313 105.332C34.3313 105.332 35.1975 108.179 33.5875 109.911C32.4048 111.13 31.1219 112.248 29.7524 113.253V111.025Z"
    fill="var(--store-primary-color)" />
  <path
    d="M29.7239 113.453C29.7239 113.453 29.8977 112.23 28.4359 110.269C26.974 108.308 26.0048 107.168 26.0048 107.168C26.0048 107.168 24.7425 109.863 26.0885 111.805C27.086 113.183 28.1976 114.476 29.4115 115.669L29.7239 113.453Z"
    fill="var(--store-primary-color)" />
  <path
    d="M28.0142 120.653C28.0142 120.653 28.0142 119.417 29.7465 117.684C31.4789 115.952 32.593 114.96 32.593 114.96C32.593 114.96 33.4592 117.807 31.8524 119.539C30.6691 120.759 29.3851 121.877 28.0142 122.881V120.653Z"
    fill="var(--store-primary-color)" />
  <path
    d="M29.1702 127.19C29.1702 127.19 28.8482 125.992 30.0943 123.877C31.3405 121.761 32.1584 120.521 32.1584 120.521C32.1584 120.521 33.7201 123.056 32.6027 125.139C31.4854 127.222 29.4214 128.855 29.4214 128.855L29.1702 127.19Z"
    fill="var(--store-primary-color)" />
  <path
    d="M32.483 89.5156C32.3961 90.8049 32.238 92.0884 32.0097 93.3603C31.3238 96.9023 29.6108 99.1821 28.9282 102.376C28.2455 105.571 30.2162 109.689 29.6816 113.798C29.1471 117.906 27.5564 120.186 28.0137 122.926C28.4709 125.667 30.2677 134.274 30.2677 134.274"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M32.2031 92.7647L34.0675 91.3318"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M32.2866 91.1129L31.5073 89.7251"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M29.0317 102.232C29.0317 102.232 30.5934 101.06 32.2034 99.8877"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M29.8138 99.7906C29.0379 98.285 28.1111 96.862 27.0479 95.5435"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M29.814 112.787C30.7083 111.14 31.8375 109.633 33.166 108.311"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M29.5082 114.523C29.0353 113.403 28.4687 112.326 27.8145 111.303C26.9032 109.957 26.7744 109.783 26.7744 109.783"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M28.0142 122.93C28.4112 121.946 28.8816 120.993 29.4213 120.08C29.9587 119.236 30.5651 118.438 31.2342 117.694"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M29.3018 129.624C29.4644 128.578 29.7711 127.56 30.213 126.597C30.857 125.335 31.6041 123.699 31.9067 123.036"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M18.1864 106.369C18.1864 106.369 18.0512 105.593 18.9496 104.318C19.848 103.043 20.4404 102.302 20.4404 102.302C20.4404 102.302 21.2937 103.993 20.4726 105.255C19.8629 106.148 19.1789 106.988 18.4279 107.767L18.1864 106.369Z"
    fill="var(--store-primary-color)" />
  <path
    d="M20.6079 110.945C20.6079 110.945 20.4759 110.169 21.3742 108.894C22.2726 107.619 22.8619 106.875 22.8619 106.875C22.8619 106.875 23.7152 108.568 22.8941 109.827C22.2839 110.721 21.5999 111.563 20.8494 112.342L20.6079 110.945Z"
    fill="var(--store-primary-color)" />
  <path
    d="M18.4827 107.68C18.4827 107.68 18.267 106.923 16.9049 106.163C15.5428 105.403 14.6895 104.991 14.6895 104.991C14.6895 104.991 14.6541 106.885 15.9421 107.667C16.8789 108.207 17.859 108.668 18.8723 109.045L18.4827 107.68Z"
    fill="var(--store-primary-color)" />
  <path
    d="M21.3808 113.073C21.3808 113.073 21.0846 112.342 19.642 111.734C18.1995 111.125 17.314 110.8 17.314 110.8C17.314 110.8 17.4782 112.687 18.8402 113.328C19.8286 113.766 20.8518 114.122 21.8992 114.39L21.3808 113.073Z"
    fill="var(--store-primary-color)" />
  <path
    d="M22.1023 119.378C22.1023 119.378 21.9671 118.602 20.692 117.704C19.4169 116.805 18.6119 116.306 18.6119 116.306C18.6119 116.306 18.3768 118.183 19.5779 119.095C20.452 119.732 21.3784 120.294 22.3471 120.776L22.1023 119.378Z"
    fill="var(--store-primary-color)" />
  <path
    d="M22.3861 120.898C22.3861 120.898 22.1414 120.151 22.8498 118.76C23.5582 117.369 24.0379 116.564 24.0379 116.564C24.0379 116.564 25.1231 118.119 24.4887 119.484C24.0119 120.454 23.4551 121.383 22.824 122.26L22.3861 120.898Z"
    fill="var(--store-primary-color)" />
  <path
    d="M24.5589 127.161C24.5589 127.161 24.6491 126.378 25.8695 125.406C27.0899 124.434 27.8691 123.893 27.8691 123.893C27.8691 123.893 28.2104 125.754 27.0673 126.733C26.2303 127.418 25.3374 128.032 24.3979 128.568L24.5589 127.161Z"
    fill="var(--store-primary-color)" />
  <path
    d="M24.2366 125.229C24.2366 125.229 24.1014 124.453 22.8263 123.555C21.5511 122.656 20.7461 122.154 20.7461 122.154C20.7461 122.154 20.5111 124.034 21.7121 124.946C22.5872 125.581 23.5134 126.144 24.4813 126.626L24.2366 125.229Z"
    fill="var(--store-primary-color)" />
  <path
    d="M24.2208 129.444C24.2208 129.444 24.2852 128.658 23.2805 127.467C22.2759 126.276 21.6222 125.58 21.6222 125.58C21.6222 125.58 20.9203 127.338 21.8476 128.526C22.5328 129.363 23.2868 130.141 24.1016 130.851L24.2208 129.444Z"
    fill="var(--store-primary-color)" />
  <path
    d="M18.0547 106.189C18.2482 106.988 18.4868 107.774 18.7695 108.546C19.581 110.691 20.9044 111.937 21.6675 113.869C22.4307 115.801 21.6675 118.592 22.4339 121.11C23.2002 123.628 24.4721 124.888 24.4721 126.655C24.4721 128.423 23.6639 133.63 23.6639 133.63"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M18.5825 108.195L17.2559 107.496"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M18.3506 107.168L18.6887 106.211"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M21.5997 113.785C21.5997 113.785 20.492 113.221 19.3457 112.661"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M20.8433 112.343C21.1707 111.313 21.6023 110.319 22.1313 109.377"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M22.2532 120.489C21.5174 119.555 20.6496 118.733 19.6772 118.048"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M22.6333 121.545C22.8109 120.794 23.05 120.058 23.3481 119.346C23.7732 118.402 23.8344 118.28 23.8344 118.28"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M24.4243 127.995C24.8557 127.46 25.333 126.965 25.8508 126.513C26.1811 126.271 26.4912 126.001 26.7781 125.708"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M24.4723 126.655C24.1165 126.082 23.7183 125.536 23.2809 125.023C22.8487 124.556 22.3792 124.125 21.877 123.735"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M24.1013 130.861C24.0541 130.262 23.8485 129.686 23.5056 129.193C22.9615 128.471 22.3175 127.518 22.0566 127.142"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M84.7472 51.52C84.7472 51.52 93.6344 60.1078 94.0337 64.4C94.433 68.6923 87.8416 72.1892 89.7414 73.5867C91.6412 74.9842 96.8254 78.6807 94.8322 81.875C92.8391 85.0692 91.8376 85.871 92.5364 86.5697C93.2351 87.2685 94.9321 87.8577 94.8322 89.5643C94.7324 91.2709 92.736 92.736 92.736 92.736H61.18C61.18 92.736 59.4831 92.8358 59.6827 90.4402C59.8824 88.0445 63.2762 86.7468 62.6773 84.9468C62.0784 83.1469 57.6831 81.7526 57.6831 78.1559C57.6831 74.5591 62.877 73.065 63.6755 71.3681C64.4741 69.6712 61.0802 68.5699 60.3815 66.3739C59.6827 64.1778 63.2795 60.3815 65.4723 58.3851C67.6651 56.3887 76.4589 50.5959 79.9623 50.3962C83.4656 50.1966 84.7472 51.52 84.7472 51.52Z"
    fill="#263238" />
  <mask
    id="mask2_3590_584"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="57"
    y="50"
    width="39"
    height="43">
    <path
      d="M84.7472 51.52C84.7472 51.52 93.6344 60.1078 94.0337 64.4C94.433 68.6923 87.8416 72.1892 89.7414 73.5867C91.6412 74.9842 96.8254 78.6807 94.8322 81.875C92.8391 85.0692 91.8376 85.871 92.5364 86.5697C93.2351 87.2685 94.9321 87.8577 94.8322 89.5643C94.7324 91.2709 92.736 92.736 92.736 92.736H61.18C61.18 92.736 59.4831 92.8358 59.6827 90.4402C59.8824 88.0445 63.2762 86.7468 62.6773 84.9468C62.0784 83.1469 57.6831 81.7526 57.6831 78.1559C57.6831 74.5591 62.877 73.065 63.6755 71.3681C64.4741 69.6712 61.0802 68.5699 60.3815 66.3739C59.6827 64.1778 63.2795 60.3815 65.4723 58.3851C67.6651 56.3887 76.4589 50.5959 79.9623 50.3962C83.4656 50.1966 84.7472 51.52 84.7472 51.52Z"
      fill="white" />
  </mask>
  <g mask="url(#mask2_3590_584)">
    <path
      d="M87.1303 53.8447C87.1303 53.8447 91.9603 62.2843 91.1972 65.5977C90.434 68.9111 85.3207 72.6817 87.2784 75.2416C89.2362 77.8015 92.1084 78.8577 90.8977 82.0229C90.1786 83.9166 89.1042 85.6555 87.7324 87.146"
      stroke="#858585"
      stroke-width="0.322"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M65.5817 65.4497C65.5817 65.4497 68.5957 70.1187 67.8357 71.4743C67.0758 72.8299 62.7127 74.4882 63.3277 77.953C63.9428 81.4177 70.2604 86.8434 70.2604 87.4487"
      stroke="#858585"
      stroke-width="0.322"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </g>
  <path
    d="M84.7472 51.52C84.7472 51.52 93.6344 60.1078 94.0337 64.4C94.433 68.6923 87.8416 72.1892 89.7414 73.5867C91.6412 74.9842 96.8254 78.6807 94.8322 81.875C92.8391 85.0692 91.8376 85.871 92.5364 86.5697C93.2351 87.2685 94.9321 87.8577 94.8322 89.5643C94.7324 91.2709 92.736 92.736 92.736 92.736H61.18C61.18 92.736 59.4831 92.8358 59.6827 90.4402C59.8824 88.0445 63.2762 86.7468 62.6773 84.9468C62.0784 83.1469 57.6831 81.7526 57.6831 78.1559C57.6831 74.5591 62.877 73.065 63.6755 71.3681C64.4741 69.6712 61.0802 68.5699 60.3815 66.3739C59.6827 64.1778 63.2795 60.3815 65.4723 58.3851C67.6651 56.3887 76.4589 50.5959 79.9623 50.3962C83.4656 50.1966 84.7472 51.52 84.7472 51.52Z"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M96.1558 87.9932C96.1558 87.9932 108.231 106.83 110.002 109.084C111.773 111.338 109.841 115.041 107.104 116.973C104.367 118.905 99.7847 117.218 97.4084 114.761C95.032 112.304 92.6138 106.508 92.6138 106.508L96.1558 87.9932Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M75.8695 81.7137C75.8695 81.7137 64.5995 85.0947 61.0575 86.3827C57.5155 87.6707 56.5495 87.8317 56.5495 89.1197C56.5495 89.8699 59.2382 104.46 58.9645 116.973C58.7681 126.014 55.5835 134.2 55.5835 134.2H96.6385C96.6385 134.2 93.2704 126.52 93.2575 117.295C93.2382 104.366 96.5322 88.5594 96.1555 87.9927C95.5115 87.0267 77.3185 81.5527 75.8695 81.7137Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M93.3803 120.334C91.9055 119.185 89.9896 117.295 89.0719 114.719C87.6454 110.723 86.5957 89.2807 86.3768 84.4507C85.6136 84.2189 84.8473 83.9871 84.0938 83.7649L84.4158 103.932H70.0868L68.8342 83.8679C68.0711 84.103 67.3015 84.3445 66.548 84.5828C66.5641 88.6561 66.5641 107.657 65.4178 113.109C64.5387 117.295 60.8776 120.119 58.6558 121.481C58.1171 125.818 57.0942 130.081 55.6064 134.19H96.6614C94.876 129.763 93.7698 125.092 93.3803 120.334Z"
    fill="#263238" />
  <mask
    id="mask3_3590_584"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="55"
    y="83"
    width="42"
    height="52">
    <path
      d="M93.3803 120.334C91.9055 119.185 89.9896 117.295 89.0719 114.719C87.6454 110.723 86.5957 89.2807 86.3768 84.4507C85.6136 84.2189 84.8473 83.9871 84.0938 83.7649L84.4158 103.932H70.0868L68.8342 83.8679C68.0711 84.103 67.3015 84.3445 66.548 84.5828C66.5641 88.6561 66.5641 107.657 65.4178 113.109C64.5387 117.295 60.8776 120.119 58.6558 121.481C58.1171 125.818 57.0942 130.081 55.6064 134.19H96.6614C94.876 129.763 93.7698 125.092 93.3803 120.334Z"
      fill="white" />
  </mask>
  <g mask="url(#mask3_3590_584)">
    <path
      d="M67.0053 84.4348L66.5352 84.5829C66.5513 88.6562 66.5513 107.657 65.405 113.109C65.3406 113.412 65.2601 113.701 65.1699 113.988V134.2H67.0053V84.4348Z"
      fill="#424242" />
    <path d="M74.7495 103.932H72.9141V134.2H74.7495V103.932Z" fill="#424242" />
    <path
      d="M57.4258 128.066V134.184H59.2483V121.072C59.0293 121.217 58.8168 121.355 58.6204 121.478C58.3505 123.695 57.9516 125.895 57.4258 128.066Z"
      fill="#424242" />
    <path
      d="M63.131 117.652C62.5741 118.329 61.9612 118.958 61.2988 119.533V134.2H63.131V117.652Z"
      fill="#424242" />
    <path
      d="M69.0405 87.4136V134.2H70.8759V103.932H70.0741L69.0405 87.4136Z"
      fill="#424242" />
    <path
      d="M88.3989 111.467V134.2H90.2311V117.005C89.7535 116.293 89.3642 115.525 89.0719 114.719C88.7547 113.656 88.5296 112.568 88.3989 111.467Z"
      fill="#424242" />
    <path
      d="M92.2695 119.385V134.197H94.1049V125.58C93.762 123.847 93.5201 122.096 93.3804 120.335C93.0295 120.061 92.6527 119.742 92.2695 119.385Z"
      fill="#424242" />
    <path
      d="M86.3605 84.4476C85.7487 84.2577 85.1337 84.0741 84.5283 83.8938V134.2H86.3605V84.4476Z"
      fill="#424242" />
    <path d="M82.4902 103.932H80.6548V134.2H82.4902V103.932Z" fill="#424242" />
    <path d="M78.6196 103.932H76.7842V134.2H78.6196V103.932Z" fill="#424242" />
  </g>
  <path
    d="M93.3803 120.334C91.9055 119.185 89.9896 117.295 89.0719 114.719C87.6454 110.723 86.5957 89.2807 86.3768 84.4507C85.6136 84.2189 84.8473 83.9871 84.0938 83.7649L84.4158 103.932H70.0868L68.8342 83.8679C68.0711 84.103 67.3015 84.3445 66.548 84.5828C66.5641 88.6561 66.5641 107.657 65.4178 113.109C64.5387 117.295 60.8776 120.119 58.6558 121.481C58.1171 125.818 57.0942 130.081 55.6064 134.19H96.6614C94.876 129.763 93.7698 125.092 93.3803 120.334Z"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M74.5625 78.7673C74.5625 78.7673 70.3765 80.2646 69.5683 81.9616C68.7601 83.6585 69.169 90.3336 68.4703 92.3461C67.7715 94.3586 66.0746 95.843 66.9762 95.9557C67.8778 96.0684 70.5697 91.9597 71.9672 92.4588C73.3647 92.9579 77.161 98.1517 77.4605 98.1517C77.76 98.1517 80.658 94.658 81.2569 93.56C81.8558 92.462 84.0518 94.1589 85.05 96.6544C86.0482 99.15 86.338 98.2644 86.338 97.3532C86.338 96.4419 85.1402 92.5586 85.3398 91.8599C85.5395 91.1611 85.24 87.6739 85.24 84.1705C85.2607 82.792 84.7894 81.4513 83.9107 80.3891C83.032 79.3268 81.8034 78.6125 80.4454 78.3745C78.2462 77.9656 74.5625 78.7673 74.5625 78.7673Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M72.6626 76.2688V87.5549C72.6626 87.5549 76.3592 99.7362 77.6568 98.8249C78.9545 97.9136 81.9523 88.0411 81.9523 88.0411V76.3686C81.9523 76.3686 77.3574 78.3682 72.6626 76.2688Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M72.6626 76.2688V78.6162C74.5302 79.6884 76.7295 80.4129 78.7098 80.4129C79.8379 80.3991 80.9477 80.1247 81.9523 79.6112V76.3686C81.9523 76.3686 77.3574 78.3682 72.6626 76.2688Z"
    fill="#263238"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M68.8696 63.9878C68.8696 63.9878 66.6735 61.0898 65.3726 63.186C64.0718 65.2822 67.4721 69.1784 68.4703 69.0786C69.4685 68.9788 68.8696 63.9878 68.8696 63.9878Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M86.5307 63.9878C86.5307 63.9878 88.7268 61.0898 90.0276 63.186C91.3285 65.2822 87.9282 69.1784 86.93 69.0786C85.9318 68.9788 86.5307 63.9878 86.5307 63.9878Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M68.5863 59.9305C68.5863 59.9305 67.4787 69.9125 68.4479 72.8105C69.4171 75.7085 74.5433 78.9059 78.7004 78.9059C82.8574 78.9059 86.3157 74.8906 86.8727 72.5335C87.4298 70.1765 86.1772 55.0811 85.3464 53.4196C84.5157 51.7581 79.2542 51.6196 73.9895 54.2504C68.7248 56.8811 68.5863 59.9305 68.5863 59.9305Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M78.954 61.502C78.954 61.502 78.0556 63.9009 78.8542 65.2983C79.6528 66.6958 79.1537 67.694 78.2553 67.694C77.3569 67.694 76.9673 66.1967 76.9673 66.1967"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M73.8638 68.6797C73.8638 68.6797 78.0498 71.5777 81.7528 68.6797"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M79.856 60.0915C79.856 60.0915 81.6559 58.0951 83.7522 60.2911"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M75.7607 59.8917C75.7607 59.8917 73.9639 57.9951 71.7646 59.692"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M81.5525 64.2841C81.8833 64.2841 82.1515 63.7478 82.1515 63.0863C82.1515 62.4247 81.8833 61.8884 81.5525 61.8884C81.2218 61.8884 80.9536 62.4247 80.9536 63.0863C80.9536 63.7478 81.2218 64.2841 81.5525 64.2841Z"
    fill="#263238" />
  <path
    d="M74.0633 64.2841C74.3941 64.2841 74.6622 63.7478 74.6622 63.0863C74.6622 62.4247 74.3941 61.8884 74.0633 61.8884C73.7325 61.8884 73.4644 62.4247 73.4644 63.0863C73.4644 63.7478 73.7325 64.2841 74.0633 64.2841Z"
    fill="#263238" />
  <path
    d="M71.1621 64.7862C72.1877 64.6172 73.2341 64.6172 74.2597 64.7862"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M81.3535 64.8861C82.3434 64.7036 83.3583 64.7036 84.3481 64.8861"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M63.7754 65.6879C63.7754 65.6879 79.4536 59.1964 83.25 56.1986C87.0464 53.2007 88.0414 47.4112 79.1542 47.7106C70.267 48.0101 60.8807 58.3978 60.0821 63.1892C59.2835 67.9805 62.4681 66.9759 62.4681 66.9759"
    fill="#263238" />
  <mask
    id="mask4_3590_584"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="59"
    y="47"
    width="28"
    height="21">
    <path
      d="M63.7754 65.6879C63.7754 65.6879 79.4536 59.1964 83.25 56.1986C87.0464 53.2007 88.0414 47.4112 79.1542 47.7106C70.267 48.0101 60.8807 58.3978 60.0821 63.1892C59.2835 67.9805 62.4681 66.9759 62.4681 66.9759"
      fill="white" />
  </mask>
  <g mask="url(#mask4_3590_584)">
    <path
      d="M85.1693 47.978C81.5456 52.3927 77.1565 56.1186 72.2121 58.9775C63.9238 63.8075 58.6494 64.7059 58.6494 64.7059"
      stroke="#858585"
      stroke-width="0.322"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M79.2925 46.4617C79.2925 46.4617 71.909 58.0537 58.1982 62.5842"
      stroke="#858585"
      stroke-width="0.322"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </g>
  <path
    d="M63.7754 65.6879C63.7754 65.6879 79.4536 59.1964 83.25 56.1986C87.0464 53.2007 88.0414 47.4112 79.1542 47.7106C70.267 48.0101 60.8807 58.3978 60.0821 63.1892C59.2835 67.9805 62.4681 66.9759 62.4681 66.9759"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M97.1218 101.597C97.1218 101.597 95.2703 100.792 93.0968 100.068C90.9233 99.3432 89.7158 100.792 90.6013 102.08C91.4868 103.368 94.6263 103.61 96.1558 104.978C97.6853 106.347 98.8928 110.774 100.422 111.579C101.432 112.084 102.521 112.411 103.642 112.545V101.597H97.1218Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M106.592 114.761H97.4084L95.354 95.8176H108.646L106.592 114.761Z"
    fill="var(--store-primary-color)"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M108.961 92.8486H95.0347L93.8208 95.3989H110.323L108.961 92.8486Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M95.5536 96.2235H108.733L109.883 95.3992H94.2656L95.5536 96.2235Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M107.155 109.567L108.333 98.6992H95.666L96.8445 109.567H107.155Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M110.162 102C110.162 102 105.896 102.08 105.091 102.886C104.286 103.691 104.527 104.656 106.057 104.817C107.586 104.978 109.84 105.3 111.048 104.978C112.255 104.656 112.255 102 110.162 102Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M109.184 110.613C109.184 110.613 105.796 110.678 105.159 111.318C104.521 111.959 104.711 112.722 105.925 112.851C107.139 112.98 108.926 113.234 109.886 112.977C110.845 112.719 110.842 110.613 109.184 110.613Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M110.968 105.294C110.968 105.294 105.413 105.294 104.045 105.536C102.676 105.777 102.193 107.146 104.206 107.548C106.218 107.951 110.324 108.192 111.612 108.031C112.9 107.87 112.98 105.22 110.968 105.294Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M110.645 108.192C110.645 108.192 106.217 108.031 104.688 108.594C103.158 109.158 103.722 109.963 105.171 110.285C106.62 110.607 109.84 111.009 111.047 110.607C112.255 110.204 112.255 108.037 110.645 108.192Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M42.1824 79.785C42.1824 79.785 41.3291 80.297 41.928 82.8601L42.5237 85.42L46.1945 84.7374C46.1945 84.7374 44.5845 81.8394 43.889 80.5514C43.1935 79.2634 42.3659 79.4437 42.1824 79.785Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M39.2842 84.4832C39.2842 84.4832 50.9793 82.519 54.4826 81.3243C57.118 80.4723 59.7965 79.7597 62.5069 79.1895C63.2073 79.0277 63.923 78.9413 64.6417 78.9319C64.6417 78.9319 64.9831 78.9319 64.8993 80.3841C64.8156 81.8363 66.7766 100.706 68.5701 106.511C70.3637 112.317 71.4681 115.051 71.4681 115.051C71.4681 115.051 60.9677 117.185 51.2337 117.185C45.8525 117.221 40.4795 116.763 35.182 115.817C34.8406 115.817 39.368 98.751 39.1941 92.9357C39.0331 87.0559 39.2842 84.4832 39.2842 84.4832Z"
    fill="var(--store-primary-color)"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M68.5859 106.511C66.7924 100.715 64.8282 81.8363 64.9151 80.3841C65.0021 78.9319 64.6575 78.9319 64.6575 78.9319C64.2569 78.9376 63.857 78.9666 63.4597 79.0188C63.4146 79.3867 63.4146 79.7587 63.4597 80.1265C63.4597 80.1265 63.3695 89.4355 63.1119 100.464C62.8994 109.602 63.0379 114.857 63.0926 116.384C67.9516 115.782 71.4646 115.067 71.4646 115.067C71.4646 115.067 70.373 112.317 68.5859 106.511Z"
    fill="var(--store-primary-color)"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M64.4803 79.3601V83.6299C64.4803 84.9951 64.7379 85.6778 64.2227 85.6778C63.7075 85.6778 59.4442 86.4474 52.5277 87.8126C48.1322 88.6898 43.6811 89.2601 39.2065 89.5192C38.6945 89.5192 39.2935 84.4832 39.2935 84.4832C39.2935 84.4832 52.2701 82.432 56.4528 81.0667C58.9417 80.2515 61.4799 79.5954 64.052 79.1025"
    fill="var(--store-primary-color)" />
  <path
    d="M64.4803 79.3601V83.6299C64.4803 84.9951 64.7379 85.6778 64.2227 85.6778C63.7075 85.6778 59.4442 86.4474 52.5277 87.8126C48.1322 88.6898 43.6811 89.2601 39.2065 89.5192C38.6945 89.5192 39.2935 84.4832 39.2935 84.4832C39.2935 84.4832 52.2701 82.432 56.4528 81.0667C58.9417 80.2515 61.4799 79.5954 64.052 79.1025"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M49.5398 76.2849C49.5398 76.2849 50.3931 74.3207 52.6986 75.6023C55.0042 76.8839 56.5401 81.4949 56.1988 82.4319C55.8575 83.3689 54.7466 83.6298 53.8063 82.6895C52.8661 81.7493 50.4768 78.3361 49.8811 77.3958C49.2854 76.4556 49.5398 76.2849 49.5398 76.2849Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M46.9767 77.4794C46.9767 77.4794 51.2915 83.7713 52.0997 84.3123C52.9079 84.8532 54.4117 85.1624 53.977 83.2883C53.568 81.5077 51.9291 78.0784 50.8182 76.8837C49.7073 75.6891 48.9409 75.5184 48.0007 76.0304C47.0604 76.5424 46.806 76.7968 46.9767 77.4794Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M47.3343 77.1385C47.3343 77.1385 51.3464 82.2615 51.691 83.4594C52.0355 84.6572 51.3464 86.6182 49.5561 85.5073C47.7658 84.3964 46.1397 80.7256 45.1157 79.444C44.0918 78.1625 45.866 76.2015 47.3343 77.1385Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M44.9287 78.4199C44.9287 78.4199 42.3527 78.7612 42.0307 80.1265C41.7087 81.4918 43.9079 84.4832 45.7015 84.9952C47.495 85.5071 48.007 84.0549 47.495 82.8603C46.983 81.6657 45.782 78.6775 44.9287 78.4199Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M138.128 128.32H110.269L116.786 98.0681H144.645L138.128 128.32Z"
    fill="#263238"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M144.646 98.0681H116.787C116.787 98.0681 132.561 101.877 144.646 98.0681Z"
    fill="#6B6B6B"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M125.715 111.125L122.778 120.157L127.785 123.532L129.743 117.874L125.715 111.125Z"
    fill="#3B3B3B"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M125.715 111.125H135.401L136.924 116.895L129.744 117.874L125.715 111.125Z"
    fill="#3B3B3B"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M134.303 133.434C133.804 126.745 131.758 120.263 128.326 114.5L120.688 133.434H134.303Z"
    fill="#3B3B3B"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M146.321 133.434C146.52 124.595 141.932 114.5 141.932 114.5H128.327C131.759 120.263 133.805 126.745 134.303 133.434H146.321Z"
    fill="#6B6B6B"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M150.796 132.429H13.7461V135.884H150.796V132.429Z"
    fill="white"
    stroke="#263238"
    stroke-width="0.322"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
