import { Inject, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { DomainService } from '@data-access/domain/domain.service';
import { HomeServiceImpl } from '@home/data-access/home.service-impl';
import { QRInfo } from '@models/domain.model';
import { StoreInfo } from '@models/home.model';
import { Response } from '@models/response';
import { Observable, catchError, of, switchMap, tap, throwError } from 'rxjs';
import { DataStorageService } from '../data-storage/data-storage.service';
import { ViewService } from '@data-access/view/view.service';
import { MicroSentryData } from '@utils/const';
import { LogService } from '../log/log.service';
import { NgxSpinnerService } from 'ngx-spinner';

export const StoreInfoResolver: ResolveFn<
  Response<QRInfo> | Response<StoreInfo> > = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  _domainService: DomainService = inject(DomainService),
  _homeService: HomeServiceImpl = inject(HomeServiceImpl),
  _dataStorage: DataStorageService = inject(DataStorageService),
  _router: Router = inject(Router),
  _viewService: ViewService = inject(ViewService),
  _logService: LogService = inject(LogService),
  _spinner: NgxSpinnerService = Inject(NgxSpinnerService)
): Observable<
  Response<QRInfo> | Response<StoreInfo>
> => {
  let storeSlug = route.params['storeSlug'] as string;
  _dataStorage.setData('storeSlug-param', storeSlug);
  _dataStorage.setData('queryParams', route.queryParams);

  if (!route.queryParams?.['qr']) {
    _router.navigate(['/e/404']);
  }

  if (!_dataStorage.getData('qrInfoResponse') || !_dataStorage.getData('storeInfoResponse')) {
    // Get QR info
    return _domainService.getQRInfo(route.queryParams?.['qr']).pipe(
      // Use switchMap to switch to the result of getStoreInfo with the obtained data
      switchMap((qrInfo) => {
        // Assuming qrInfo has a property like 'storeSlug'
        const qrInfoData = qrInfo.data;
        _dataStorage.setData('qrInfoResponse', qrInfo);
        _viewService.setQRInfo(qrInfoData)
  
        // Get store info using the dynamically obtained storeSlug
        return _homeService.getStoreInfo(qrInfoData.storeSlug).pipe(
          tap((storeInfo) => {  
            const storeInfoData = storeInfo.data;
            _dataStorage.setData('storeInfoResponse', storeInfo);
            _viewService.setStoreInfo(storeInfoData)
  
            return storeInfoData
          }),
          catchError((error) => {
            // Handle the error here as needed
            console.error('Error fetching Store info:', error);
  
            if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
              _router.navigate(['/e/table-management-requires-activation'])
            } else {
              _router.navigate(['/e/404'])
            }
    
            _spinner.hide()
    
            _logService.error({ 
              type: 'ERROR',
              merchantId: 0,
              storeId: 0,
              qr: route.queryParams?.['qr'],
              qrType: '',
              dateTime: `${new Date()}`,
              body: JSON.stringify({
                infoUser: {
                  event: MicroSentryData.StoreInfo.message,
                  storeName: '',
                  className: 'AppComponent',
                  methodName: 'getStoreInfo',
                  deviceId: _viewService.getDeviceId(qrInfoData.storeSlug),
                  customerDeviceId: _viewService.getCustomerDeviceId(qrInfoData.storeSlug),
                  serviceType: _viewService.getServiceType(qrInfoData.storeSlug),
                  userAgent: navigator.userAgent,
                },
                data: error
              })
            })
      
            if (error.error.status === 404) {
              _router.navigate(['/e/404']);
            }
      
            return throwError(() => error);
          })
        );
      }),
      catchError((error) => {
        // Handle the error here as needed
        console.error('Error fetching QR info:', error);
  
        if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
          _router.navigate(['/e/table-management-requires-activation'])
        } else if (error.error.error === 'MERCHANT_TABLE_QR_NOT_FOUND') {
          _router.navigate(['/e/404'])
        } else if (error.error.error === 'MERCHANT_TABLE_QR_EXPIRED') {
          _router.navigate(['/e/qr-expired'])
        } else {
          _router.navigate(['/e/404']);
        }
  
        // Optionally rethrow the error to propagate it further
        return throwError(() => error);
      })
    );
  } else {
    return of(_dataStorage.getData('storeInfoResponse'))
  }

};
