<style>
  svg#freepik_stories-hamburger:not(.animated) .animable {
    opacity: 0;
  }
  svg#freepik_stories-hamburger.animated
    #freepik--background-complete--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--Shadow--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--bread-2--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--burger-2--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--cheese-2--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--Onions--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--burger-1--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--cheese-1--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--Lettuce--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--Tomatoes--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
    animation-delay: 0s;
  }
  svg#freepik_stories-hamburger.animated #freepik--bread-1--inject-145 {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
    animation-delay: 0s;
  }
  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slideRight {
    0% {
      opacity: 0;
      transform: translateX(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>
<svg
  class="animated"
  id="freepik_stories-hamburger"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 500 500"
  version="1.1"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:svgjs="http://svgjs.com/svgjs">
  <g
    id="freepik--background-complete--inject-145"
    class="animable"
    style="transform-origin: 250.005px 252.705px">
    <path
      d="M132.66,275.18c-13.29,3.78-31.77,2.49-39.93-6.18-1.76-1.87-1.62-6.49-1.4-8.46.79-7.25,12.3-12,22.55-14.84,13-3.68,34-3.73,41.08,4.8,1.72,2.08,3.06,2.85,2.85,5.29C157.28,262,149.41,270.41,132.66,275.18Z"
      style="fill: rgb(199, 199, 199); transform-origin: 124.517px 260.265px"
      id="elcdawhlgds5n"
      class="animable"></path>
    <path
      d="M133.42,272.15c-13.29,3.79-31.77,2.49-39.94-6.18s8.12-19.62,21.16-23.3,34-3.72,41.08,4.8C161.85,254.86,156.72,265.52,133.42,272.15Z"
      style="fill: rgb(219, 219, 219); transform-origin: 124.69px 257.239px"
      id="elloxdn5rjd0r"
      class="animable"></path>
    <path
      d="M131.92,269.39c-10.85,3.09-25.95,2-32.62-5.05s6.63-16,17.28-19,27.78-3.05,33.56,3.92C155.15,255.26,151,264,131.92,269.39Z"
      style="fill: rgb(235, 235, 235); transform-origin: 124.798px 257.219px"
      id="el4rz2kb6wrxp"
      class="animable"></path>
    <path
      d="M137.75,257.9c0-.56,3.82-1.42,3.75-.34C141.44,258.46,137.76,258.63,137.75,257.9Z"
      style="fill: rgb(255, 255, 255); transform-origin: 139.625px 257.7px"
      id="elsi5n915kf9"
      class="animable"></path>
    <path
      d="M129.06,250.62c-1,0-.88-2.1,1.06-2C131.75,248.67,130.4,250.64,129.06,250.62Z"
      style="fill: rgb(255, 255, 255); transform-origin: 129.655px 249.618px"
      id="elltf1jzud3kj"
      class="animable"></path>
    <path
      d="M138.41,248.72c-.72-.33,1.55-2.26,2.86-1.59C142.37,247.69,139.35,249.15,138.41,248.72Z"
      style="fill: rgb(255, 255, 255); transform-origin: 139.89px 247.894px"
      id="elt5kewm6mjr"
      class="animable"></path>
    <path
      d="M143.32,259.7c.78-.47,4,.36,2.45,1.23C144.49,261.66,142.32,260.31,143.32,259.7Z"
      style="fill: rgb(255, 255, 255); transform-origin: 144.625px 260.352px"
      id="elq0bpfri6pk"
      class="animable"></path>
    <path
      d="M121.08,265.56c1,.11.23,2.21-1.61,2C117.94,267.31,119.81,265.41,121.08,265.56Z"
      style="fill: rgb(255, 255, 255); transform-origin: 120.208px 266.563px"
      id="elnbe9c07z66j"
      class="animable"></path>
    <path
      d="M109,256.36c0,.57-3.89,1.35-3.74.26C105.42,255.72,109.08,255.63,109,256.36Z"
      style="fill: rgb(255, 255, 255); transform-origin: 107.129px 256.52px"
      id="ell9wr262crec"
      class="animable"></path>
    <path
      d="M114.29,253.35c-.51.55-4.25.38-3.22-.66C111.94,251.83,115,252.64,114.29,253.35Z"
      style="fill: rgb(255, 255, 255); transform-origin: 112.644px 252.988px"
      id="elst0n2uk3nsh"
      class="animable"></path>
    <path
      d="M122,260.49c1.06-.19,2.31,1.63.29,1.94C120.61,262.68,120.65,260.72,122,260.49Z"
      style="fill: rgb(255, 255, 255); transform-origin: 122.187px 261.464px"
      id="eligbv67l7tv"
      class="animable"></path>
    <path
      d="M107,270.06s13.38-8.75,11.86-10.88-23.23.44-23.42,1.87S100.63,269.53,107,270.06Z"
      style="fill: rgb(219, 219, 219); transform-origin: 107.208px 264.214px"
      id="elrwuvqir51d9"
      class="animable"></path>
    <path
      d="M107,248.28s11.05,7.06,15.6,6.2,8.29-12.56,5.7-12.64S110.46,244.83,107,248.28Z"
      style="fill: rgb(219, 219, 219); transform-origin: 118.075px 248.196px"
      id="el9cef4qxq2oj"
      class="animable"></path>
    <path
      d="M148.58,244.49s-15,8-14.06,10.25,22.52,1.18,23.06-.27S154.58,245.47,148.58,244.49Z"
      style="fill: rgb(219, 219, 219); transform-origin: 146.056px 250.263px"
      id="elwm5iyz62aoe"
      class="animable"></path>
    <path
      d="M146.85,265.08s-12-6.6-16.47-5.6-7,12.65-4.33,12.65S143.73,268.59,146.85,265.08Z"
      style="fill: rgb(219, 219, 219); transform-origin: 135.904px 265.753px"
      id="elfed02y0yfeb"
      class="animable"></path>
    <path
      d="M383.9,410.38c-13.29,3.78-31.77,2.49-39.93-6.18-1.76-1.87-1.62-6.49-1.4-8.46.79-7.25,12.3-11.95,22.55-14.84,13-3.68,34-3.73,41.08,4.8,1.72,2.08,3.06,2.85,2.85,5.29C408.51,397.2,400.65,405.61,383.9,410.38Z"
      style="fill: rgb(199, 199, 199); transform-origin: 375.757px 395.465px"
      id="elvrqcpjm09t"
      class="animable"></path>
    <path
      d="M384.66,407.35c-13.29,3.79-31.77,2.49-39.94-6.18s8.12-19.62,21.16-23.3,34-3.72,41.08,4.8C413.09,390.06,408,400.72,384.66,407.35Z"
      style="fill: rgb(219, 219, 219); transform-origin: 375.933px 392.439px"
      id="elilk4px88axm"
      class="animable"></path>
    <path
      d="M383.16,404.59c-10.85,3.09-26,2-32.62-5s6.63-16,17.28-19,27.78-3.05,33.56,3.92C406.39,390.46,402.2,399.17,383.16,404.59Z"
      style="fill: rgb(235, 235, 235); transform-origin: 376.044px 392.445px"
      id="elxfc8nuqo6wd"
      class="animable"></path>
    <path
      d="M389,393.1c0-.56,3.82-1.42,3.74-.34C392.67,393.66,389,393.83,389,393.1Z"
      style="fill: rgb(255, 255, 255); transform-origin: 390.871px 392.9px"
      id="elrbew0my8gpi"
      class="animable"></path>
    <path
      d="M380.3,385.81c-1,0-.88-2.09,1.06-2C383,383.87,381.64,385.84,380.3,385.81Z"
      style="fill: rgb(255, 255, 255); transform-origin: 380.897px 384.809px"
      id="elf4q3ajv3ext"
      class="animable"></path>
    <path
      d="M389.65,383.92c-.72-.33,1.55-2.26,2.86-1.59C393.61,382.89,390.59,384.35,389.65,383.92Z"
      style="fill: rgb(255, 255, 255); transform-origin: 391.13px 383.094px"
      id="elpwddhkdw94d"
      class="animable"></path>
    <path
      d="M394.56,394.9c.78-.47,4,.36,2.45,1.23C395.72,396.86,393.56,395.51,394.56,394.9Z"
      style="fill: rgb(255, 255, 255); transform-origin: 395.865px 395.552px"
      id="elmk537xx0jnf"
      class="animable"></path>
    <path
      d="M372.32,400.76c1,.11.23,2.21-1.61,2C369.18,402.51,371.05,400.61,372.32,400.76Z"
      style="fill: rgb(255, 255, 255); transform-origin: 371.448px 401.763px"
      id="el52f0ah6zl3r"
      class="animable"></path>
    <path
      d="M360.27,391.56c0,.57-3.89,1.35-3.74.26C356.66,390.92,360.32,390.83,360.27,391.56Z"
      style="fill: rgb(255, 255, 255); transform-origin: 358.398px 391.72px"
      id="eltu60eieyuwr"
      class="animable"></path>
    <path
      d="M365.53,388.55c-.51.55-4.26.38-3.22-.66C363.18,387,366.18,387.84,365.53,388.55Z"
      style="fill: rgb(255, 255, 255); transform-origin: 363.875px 388.181px"
      id="elz3lm8xuxu7m"
      class="animable"></path>
    <path
      d="M373.26,395.69c1.06-.19,2.31,1.63.28,1.94C371.85,397.88,371.89,395.92,373.26,395.69Z"
      style="fill: rgb(255, 255, 255); transform-origin: 373.438px 396.664px"
      id="elqhu6kecvgsc"
      class="animable"></path>
    <path
      d="M358.22,405.26s13.38-8.75,11.86-10.88-23.23.44-23.42,1.87S351.87,404.73,358.22,405.26Z"
      style="fill: rgb(219, 219, 219); transform-origin: 358.428px 399.414px"
      id="el75ifi7c0iwx"
      class="animable"></path>
    <path
      d="M358.23,383.48s11,7.06,15.6,6.2,8.29-12.56,5.7-12.64S361.7,380,358.23,383.48Z"
      style="fill: rgb(219, 219, 219); transform-origin: 369.308px 383.396px"
      id="elz2wqbnna0uf"
      class="animable"></path>
    <path
      d="M399.82,379.69s-15,8-14.07,10.25,22.53,1.18,23.07-.27S405.82,380.67,399.82,379.69Z"
      style="fill: rgb(219, 219, 219); transform-origin: 397.291px 385.463px"
      id="elfm609i050m9"
      class="animable"></path>
    <path
      d="M398.09,400.28s-12-6.6-16.47-5.6-7,12.65-4.33,12.65S395,403.79,398.09,400.28Z"
      style="fill: rgb(219, 219, 219); transform-origin: 387.144px 400.953px"
      id="eldg91011hlxl"
      class="animable"></path>
    <path
      d="M416.77,130.34c-13.71-1.64-30.27-10-34.46-21.11-.9-2.4,1-6.6,2-8.34,3.53-6.39,16-6.28,26.53-5,13.44,1.64,32.82,9.68,36.06,20.27.79,2.58,1.72,3.82.59,6C444.57,127.67,434.07,132.39,416.77,130.34Z"
      style="fill: rgb(199, 199, 199); transform-origin: 415.045px 113.012px"
      id="elhtepfc68l1j"
      class="animable"></path>
    <path
      d="M418.64,127.84c-13.72-1.64-30.27-10-34.46-21.11s15.06-15,28.5-13.34,32.81,9.68,36.05,20.27C451.54,122.84,442.7,130.7,418.64,127.84Z"
      style="fill: rgb(219, 219, 219); transform-origin: 416.414px 110.73px"
      id="elumgwi6zta1b"
      class="animable"></path>
    <path
      d="M418.32,124.71c-11.2-1.33-24.73-8.13-28.15-17.24s12.3-12.23,23.28-10.9,26.81,7.91,29.46,16.56C445.2,120.63,438,127.05,418.32,124.71Z"
      style="fill: rgb(235, 235, 235); transform-origin: 416.506px 110.738px"
      id="elyhk7hmpbi9"
      class="animable"></path>
    <path
      d="M428.13,116.35c.21-.51,4.07.17,3.59,1.13C431.32,118.29,427.86,117,428.13,116.35Z"
      style="fill: rgb(255, 255, 255); transform-origin: 429.938px 116.957px"
      id="elhqg9wp6gul"
      class="animable"></path>
    <path
      d="M422.93,106.28c-.95-.41,0-2.28,1.75-1.45C426.16,105.53,424.15,106.83,422.93,106.28Z"
      style="fill: rgb(255, 255, 255); transform-origin: 423.878px 105.516px"
      id="elmyuro2h6o99"
      class="animable"></path>
    <path
      d="M432.28,108.14c-.53-.58,2.3-1.49,3.26-.37C436.34,108.71,433,108.9,432.28,108.14Z"
      style="fill: rgb(255, 255, 255); transform-origin: 433.939px 107.924px"
      id="el43g9onq8v7z"
      class="animable"></path>
    <path
      d="M432.58,120.17c.9-.14,3.53,1.86,1.78,2.07C432.9,122.42,431.42,120.34,432.58,120.17Z"
      style="fill: rgb(255, 255, 255); transform-origin: 433.555px 121.207px"
      id="el5rwu11w9ktf"
      class="animable"></path>
    <path
      d="M409.8,117c.86.48-.65,2.14-2.24,1.19C406.23,117.4,408.68,116.37,409.8,117Z"
      style="fill: rgb(255, 255, 255); transform-origin: 408.612px 117.642px"
      id="el6rm3i0n2zcx"
      class="animable"></path>
    <path
      d="M402.23,103.86c-.25.51-4.11-.26-3.55-1.2C399.15,101.87,402.55,103.2,402.23,103.86Z"
      style="fill: rgb(255, 255, 255); transform-origin: 400.438px 103.22px"
      id="elf8l4djunxe5"
      class="animable"></path>
    <path
      d="M408.24,103.11c-.68.31-4.07-1.29-2.71-1.85C406.66,100.8,409.12,102.71,408.24,103.11Z"
      style="fill: rgb(255, 255, 255); transform-origin: 406.819px 102.169px"
      id="elrxn6hz1qas"
      class="animable"></path>
    <path
      d="M412.63,112.68c1,.24,1.49,2.39-.49,1.9C410.48,114.16,411.27,112.37,412.63,112.68Z"
      style="fill: rgb(255, 255, 255); transform-origin: 412.353px 113.648px"
      id="el4ui9dzxpim6"
      class="animable"></path>
    <path
      d="M395.06,115.71s15.71-2.92,15.13-5.46-21.6-8.56-22.33-7.31S389.4,112.77,395.06,115.71Z"
      style="fill: rgb(219, 219, 219); transform-origin: 398.967px 109.241px"
      id="elv4ctp96e0y"
      class="animable"></path>
    <path
      d="M403.46,95.62s7.47,10.77,12,11.73S428,99,425.6,97.89,408,93.77,403.46,95.62Z"
      style="fill: rgb(219, 219, 219); transform-origin: 414.75px 101.226px"
      id="elj9ovha7qsi"
      class="animable"></path>
    <path
      d="M443.29,108.16s-16.92,1.57-16.92,4,20.33,9.78,21.38,8.65S448.46,111.38,443.29,108.16Z"
      style="fill: rgb(219, 219, 219); transform-origin: 437.3px 114.544px"
      id="eluu7sqvzeff"
      class="animable"></path>
    <path
      d="M433.76,126.49s-8.5-10.71-13-11.52-11.3,9-8.87,10S429.53,128.53,433.76,126.49Z"
      style="fill: rgb(219, 219, 219); transform-origin: 422.57px 121.09px"
      id="elwgkj9up2zb8"
      class="animable"></path>
    <path
      d="M85.27,249.88C71.57,247.81,55.09,237.77,51,224.5c-.88-2.86,1.07-7.83,2-9.89,3.59-7.54,16-7.28,26.57-5.65C93,211,112.33,220.77,115.48,233.37c.77,3.07,1.7,4.55.54,7.1C113.08,247,102.55,252.5,85.27,249.88Z"
      style="fill: rgb(199, 199, 199); transform-origin: 83.6721px 229.286px"
      id="elajpzcc3gu8a"
      class="animable"></path>
    <path
      d="M87.16,246.94C73.45,244.86,57,234.82,52.88,221.55S68.06,203.93,81.49,206s32.73,11.81,35.88,24.42C120.1,241.35,111.19,250.58,87.16,246.94Z"
      style="fill: rgb(219, 219, 219); transform-origin: 85.0797px 226.628px"
      id="elsnwfmznczw"
      class="animable"></path>
    <path
      d="M86.87,243.23c-11.2-1.7-24.66-9.9-28-20.75s12.41-14.39,23.38-12.69,26.74,9.65,29.31,20C113.78,238.66,106.5,246.2,86.87,243.23Z"
      style="fill: rgb(235, 235, 235); transform-origin: 85.1795px 226.636px"
      id="elet9k2kyx09"
      class="animable"></path>
    <path
      d="M96.75,233.41c.21-.61,4.06.24,3.58,1.38C99.91,235.74,96.47,234.21,96.75,233.41Z"
      style="fill: rgb(255, 255, 255); transform-origin: 98.5526px 234.149px"
      id="elt6eavqdzzn"
      class="animable"></path>
    <path
      d="M91.63,221.41c-.94-.51,0-2.71,1.76-1.7C94.86,220.55,92.85,222.06,91.63,221.41Z"
      style="fill: rgb(255, 255, 255); transform-origin: 92.5828px 220.508px"
      id="elr03nmm9kn8"
      class="animable"></path>
    <path
      d="M101,223.71c-.53-.7,2.31-1.75,3.26-.41C105,224.43,101.66,224.62,101,223.71Z"
      style="fill: rgb(255, 255, 255); transform-origin: 102.651px 223.47px"
      id="elov9qsiuampo"
      class="animable"></path>
    <path
      d="M101.16,238c.9-.15,3.52,2.25,1.77,2.48C101.46,240.66,100,238.18,101.16,238Z"
      style="fill: rgb(255, 255, 255); transform-origin: 102.13px 239.241px"
      id="el39f7pylt5ot"
      class="animable"></path>
    <path
      d="M78.41,234c.86.58-.66,2.53-2.25,1.39C74.83,234.42,77.3,233.23,78.41,234Z"
      style="fill: rgb(255, 255, 255); transform-origin: 77.2178px 234.747px"
      id="elo35aqrnrcno"
      class="animable"></path>
    <path
      d="M71,218.33c-.26.59-4.1-.35-3.54-1.46C67.89,215.93,71.28,217.55,71,218.33Z"
      style="fill: rgb(255, 255, 255); transform-origin: 69.2104px 217.548px"
      id="elds5fetaixrp"
      class="animable"></path>
    <path
      d="M77,217.49c-.68.37-4.06-1.57-2.7-2.22C75.41,214.73,77.85,217,77,217.49Z"
      style="fill: rgb(255, 255, 255); transform-origin: 75.5784px 216.362px"
      id="el5bbv98sz4lf"
      class="animable"></path>
    <path
      d="M81.27,228.89c1,.29,1.48,2.86-.5,2.25A1.15,1.15,0,1,1,81.27,228.89Z"
      style="fill: rgb(255, 255, 255); transform-origin: 81.0025px 230.048px"
      id="elbxjhore2k0m"
      class="animable"></path>
    <path
      d="M63.68,232.31S79.42,229,78.86,226s-21.53-10.37-22.27-8.9S58.05,228.77,63.68,232.31Z"
      style="fill: rgb(219, 219, 219); transform-origin: 67.6629px 224.609px"
      id="el7nk4ujwtdas"
      class="animable"></path>
    <path
      d="M72.26,208.55s7.38,12.87,11.9,14,12.56-9.83,10.21-11.13S76.8,206.41,72.26,208.55Z"
      style="fill: rgb(219, 219, 219); transform-origin: 83.5257px 215.265px"
      id="el5qve1r6451g"
      class="animable"></path>
    <path
      d="M112,223.84S95,225.53,95,228.45s20.24,11.81,21.3,10.48S117.11,227.71,112,223.84Z"
      style="fill: rgb(219, 219, 219); transform-origin: 105.903px 231.452px"
      id="eliqyfiw2mttq"
      class="animable"></path>
    <path
      d="M102.29,245.49s-8.41-12.79-12.94-13.8S78,242.25,80.4,243.47,98,247.87,102.29,245.49Z"
      style="fill: rgb(219, 219, 219); transform-origin: 91.0962px 238.989px"
      id="el7x2mm6qdnqq"
      class="animable"></path>
    <path
      d="M400.65,258.72l-.1,3.15a3,3,0,0,0-.07-.74c-1.1-4.95-15.81-8.63-35.17-6-18.42,2.52-30.41,9.32-30.58,14.24l.11-3.15C335,261.32,347,254.52,365.42,252c19.36-2.65,34.07,1,35.17,6A3,3,0,0,1,400.65,258.72Z"
      style="fill: rgb(219, 219, 219); transform-origin: 367.691px 260.241px"
      id="elsryvldp9rif"
      class="animable"></path>
    <path
      d="M364.61,248.34c20.92-2.86,38.69,1.1,40.45,9s-13.25,16.39-34.18,19.25-38.69-1.1-40.45-9S343.69,251.21,364.61,248.34ZM370.07,273c19.36-2.65,31.62-10,30.52-15s-15.81-8.64-35.17-6-31.62,10-30.52,15,15.82,8.63,35.17,6"
      style="fill: rgb(235, 235, 235); transform-origin: 367.745px 262.465px"
      id="elv39iajnbvio"
      class="animable"></path>
    <path
      d="M405.2,258.84l-.11,3.15c-.25,7.48-14.68,15.1-34.31,17.79-20.92,2.86-38.69-1.11-40.45-9a5.64,5.64,0,0,1-.14-1.47l.1-3.15a6.11,6.11,0,0,0,.14,1.47c1.76,7.93,19.54,11.9,40.45,9C390.52,273.93,405,266.32,405.2,258.84Z"
      style="fill: rgb(219, 219, 219); transform-origin: 367.693px 269.773px"
      id="el7637a7govd8"
      class="animable"></path>
    <path
      d="M440.18,236.43l-1.59,3.32a3.76,3.76,0,0,0,.27-.82C440,233.11,426,222.24,404,216c-20.92-6-37-4.33-39.46.86l1.6-3.32c2.49-5.2,18.54-6.84,39.45-.87,22,6.28,36,17.14,34.85,23A3.61,3.61,0,0,1,440.18,236.43Z"
      style="fill: rgb(219, 219, 219); transform-origin: 402.523px 224.307px"
      id="eld9dyy7trm9l"
      class="animable"></path>
    <path
      d="M406.47,208.35c23.75,6.78,40.91,19.39,39.07,28.71s-21.91,11.31-45.66,4.53S359,222.2,360.81,212.87,382.72,201.57,406.47,208.35Zm-5.74,28.94c22,6.27,38.57,4.15,39.73-1.68s-12.87-16.69-34.85-23-38.57-4.14-39.72,1.68,12.86,16.7,34.84,23"
      style="fill: rgb(235, 235, 235); transform-origin: 403.177px 224.967px"
      id="ell9kp24knsye"
      class="animable"></path>
    <path
      d="M445,238.7l-1.6,3.33c-3.79,7.89-22.82,9.25-45.11,2.89-23.75-6.78-40.92-19.4-39.07-28.72a6.74,6.74,0,0,1,.55-1.64l1.59-3.32a6.5,6.5,0,0,0-.54,1.63c-1.85,9.33,15.32,21.94,39.07,28.72C422.17,248,441.2,246.59,445,238.7Z"
      style="fill: rgb(219, 219, 219); transform-origin: 402.042px 230.089px"
      id="elmbjx8u4j3s9"
      class="animable"></path>
    <path
      d="M169.65,358.93l.71,3.62a2.93,2.93,0,0,0-.27-.82c-2.56-5.36-20.3-5.65-41.65,2.5-20.33,7.75-32.18,18.68-31.07,24.34L96.66,385c-1.12-5.66,10.74-16.58,31.06-24.34,21.35-8.15,39.09-7.86,41.66-2.5A3.76,3.76,0,0,1,169.65,358.93Z"
      style="fill: rgb(219, 219, 219); transform-origin: 133.474px 371.44px"
      id="el8jpqfqk91ar"
      class="animable"></path>
    <path
      d="M125.83,356.66c23.08-8.81,44.38-9,48.48-.43s-10.75,22.18-33.83,31-44.38,9-48.49.43S102.76,365.47,125.83,356.66Zm12.75,26.61c21.35-8.15,33.37-19.8,30.8-25.16s-20.31-5.65-41.66,2.5-33.36,19.8-30.79,25.16,20.3,5.65,41.65-2.5"
      style="fill: rgb(235, 235, 235); transform-origin: 133.149px 371.947px"
      id="eldkxwnhlmjj"
      class="animable"></path>
    <path
      d="M174.86,357.87l.71,3.62c1.7,8.59-12.72,21.08-34.38,29.35-23.07,8.81-44.37,9-48.48.43a6.6,6.6,0,0,1-.55-1.64L91.45,386a7,7,0,0,0,.54,1.64c4.11,8.57,25.41,8.38,48.49-.43C162.14,379,176.55,366.46,174.86,357.87Z"
      style="fill: rgb(219, 219, 219); transform-origin: 133.578px 377.722px"
      id="elhv4g3kzzhow"
      class="animable"></path>
  </g>
  <g
    id="freepik--Shadow--inject-145"
    class="animable"
    style="transform-origin: 250px 455.08px">
    <ellipse
      cx="250"
      cy="455.08"
      rx="107.02"
      ry="10.12"
      style="fill: rgb(235, 235, 235); transform-origin: 250px 455.08px"
      id="el5q8z4g4tiac"
      class="animable"></ellipse>
  </g>
  <g
    id="freepik--bread-2--inject-145"
    class="animable"
    style="transform-origin: 255.912px 410.249px">
    <path
      d="M379.37,433.83c-21.08,14.54-70.23,22.39-126.75,18.42s-104.06-18.6-122.9-36c-6.12-9.17-6.45-24.4-1.72-29.46a1.33,1.33,0,0,1,.17-.16l.68-.57c19-16.07,70.35-25,129.89-20.82s109,20.16,125.61,38.68l.79.9a.14.14,0,0,1,.06.07C389.14,410.61,386.69,425.61,379.37,433.83Z"
      style="fill: rgb(29, 149, 243); transform-origin: 255.912px 408.745px"
      id="elsedgygc050f"
      class="animable"></path>
    <g id="elotwcp8k5nc">
      <path
        d="M379.37,433.83c-21.08,14.54-70.23,22.39-126.75,18.42s-104.06-18.6-122.9-36c-6.12-9.17-6.45-24.4-1.72-29.46a1.33,1.33,0,0,1,.17-.16l.68-.57c19-16.07,70.35-25,129.89-20.82s109,20.16,125.61,38.68l.79.9a.14.14,0,0,1,.06.07C389.14,410.61,386.69,425.61,379.37,433.83Z"
        style="opacity: 0.2; transform-origin: 255.912px 408.745px"
        class="animable"></path>
    </g>
    <path
      d="M379.37,433.83a19.52,19.52,0,0,1-2.83,2.64c-17.19,12.88-70.59,22.78-124.14,19s-105-21-120.25-36.19a18.21,18.21,0,0,1-2.43-3c-6.12-9.17-6.45-24.4-1.72-29.46a1.33,1.33,0,0,1,.17-.16l.68-.57c1.49-1.11,3.38-1.32,5.65-.27,11.75,5.37,58.53,30.09,120.37,34.44s111.63-13.59,124-17.27a5.3,5.3,0,0,1,5.46,1l.79.9a.14.14,0,0,1,.06.07C389.14,410.61,386.69,425.61,379.37,433.83Z"
      style="fill: rgb(29, 149, 243); transform-origin: 255.909px 420.71px"
      id="el4fb7qzy7sk4"
      class="animable"></path>
    <g id="el8qmzkcxqcyq">
      <path
        d="M379.37,433.83a19.52,19.52,0,0,1-2.83,2.64c-17.19,12.88-70.59,22.78-124.14,19s-105-21-120.25-36.19a18.21,18.21,0,0,1-2.43-3c-6.12-9.17-6.45-24.4-1.72-29.46a1.33,1.33,0,0,1,.17-.16l.68-.57c1.49-1.11,3.38-1.32,5.65-.27,11.75,5.37,58.53,30.09,120.37,34.44s111.63-13.59,124-17.27a5.3,5.3,0,0,1,5.46,1l.79.9a.14.14,0,0,1,.06.07C389.14,410.61,386.69,425.61,379.37,433.83Z"
        style="opacity: 0.3; transform-origin: 255.909px 420.71px"
        class="animable"></path>
    </g>
    <g id="eld1b71p0sfe6">
      <path
        d="M358.9,405.34A48.17,48.17,0,0,1,376.49,400a36.41,36.41,0,0,1-17.59,5.34Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 367.695px 402.67px;
        "
        class="animable"></path>
    </g>
    <g id="elxnd7lm0z0l">
      <path
        d="M320.18,413a237.38,237.38,0,0,1,32.39-6,91.7,91.7,0,0,1-32.39,6Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 336.375px 410px;
        "
        class="animable"></path>
    </g>
    <g id="elnqak5zazpx">
      <path
        d="M201,403.4c6.44,2.5,13.22,3.94,20,5.3a316.14,316.14,0,0,0,34.12,4.69,377.72,377.72,0,0,0,41.34,1.18l3.45,0c3.32-.16,7.05-.34,10.37-.44a253.46,253.46,0,0,1-82.79-2.61c-9-1.92-18.14-4.05-26.46-8.08Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 255.64px 409.878px;
        "
        class="animable"></path>
    </g>
    <g id="elx21gng0tuu">
      <g
        style="opacity: 0.1; transform-origin: 257.027px 387.518px"
        class="animable">
        <path
          d="M347,397.7c-1.85,2.14-6.44,4.07-17.08,5.18a404.39,404.39,0,0,1-72,1.47,433.26,433.26,0,0,1-72.85-11.26c-16.73-3.94-19.26-7.87-19.49-11.28a5.17,5.17,0,0,1,1.47-4.44c1.83-2.16,6.41-4.1,17.11-5.21a405.07,405.07,0,0,1,72-1.48A434.27,434.27,0,0,1,329,382c16.73,3.93,19.26,7.87,19.48,11.28A5.2,5.2,0,0,1,347,397.7Z"
          id="elgst6id22f7o"
          class="animable"
          style="transform-origin: 257.027px 387.518px"></path>
      </g>
    </g>
  </g>
  <g
    id="freepik--burger-2--inject-145"
    class="animable"
    style="transform-origin: 269.698px 361.332px">
    <path
      d="M392.31,353c-1.88,4.89-7.47,10.19-21.33,15.81-21.34,8.61-52.52,20-95.56,25.89s-76.78,3.27-100.12.7-27.81-9.59-29-16.33c-.6-3.32-.65-6.52.76-9.35,1.84-4.9,7.42-10.22,21.36-15.87,21.34-8.61,52.52-20,95.55-25.89s76.78-3.26,100.14-.7,27.8,9.58,29,16.33C393.69,346.88,393.75,350.11,392.31,353Z"
      style="fill: rgb(29, 149, 243); transform-origin: 269.698px 361.332px"
      id="elmh6vmc57yr"
      class="animable"></path>
    <g id="elb5t5guhb3e">
      <g
        style="opacity: 0.5; transform-origin: 269.698px 361.332px"
        class="animable">
        <path
          d="M392.31,353c-1.88,4.89-7.47,10.19-21.33,15.81-21.34,8.61-52.52,20-95.56,25.89s-76.78,3.27-100.12.7-27.81-9.59-29-16.33c-.6-3.32-.65-6.52.76-9.35,1.84-4.9,7.42-10.22,21.36-15.87,21.34-8.61,52.52-20,95.55-25.89s76.78-3.26,100.14-.7,27.8,9.58,29,16.33C393.69,346.88,393.75,350.11,392.31,353Z"
          id="elci8ifh800pu"
          class="animable"
          style="transform-origin: 269.698px 361.332px"></path>
      </g>
    </g>
    <g id="elfzke01safr5">
      <g
        style="opacity: 0.1; transform-origin: 264.557px 348.69px"
        class="animable">
        <path
          d="M358.79,340.4c-1.44,2.68-5.74,5.7-16.38,9.14A409.37,409.37,0,0,1,269,366.4a436.21,436.21,0,0,1-76.94,3.48c-17.95-.63-21.38-4.28-22.33-7.86a5.78,5.78,0,0,1,.58-5c1.41-2.69,5.69-5.72,16.4-9.18a409.37,409.37,0,0,1,73.41-16.86,436.26,436.26,0,0,1,76.95-3.48c17.94.63,21.37,4.28,22.31,7.86A5.8,5.8,0,0,1,358.79,340.4Z"
          id="el55cn56feuym"
          class="animable"
          style="transform-origin: 264.557px 348.69px"></path>
      </g>
    </g>
    <g id="elavhkv9v1m6r">
      <path
        d="M152.65,369.4a54.47,54.47,0,0,0,7.3.88c17.12,1.19,34.32,1.18,51.46.73s34.3-1.58,51.29-3.69c31.51-4.41,63.08-9.62,93.74-18.22,4.7-1.36,9.38-2.83,13.93-4.63s9.22-3.73,12.57-7.35c-3.2,3.78-7.94,5.84-12.36,7.83-22.8,9.26-47.15,14-71.24,18.56a501.36,501.36,0,0,1-132.08,7.76c-4.87-.45-9.83-.73-14.61-1.87Z"
        style="opacity: 0.2; transform-origin: 267.795px 354.875px"
        class="animable"></path>
    </g>
    <g id="elvyn28d0cqj">
      <g
        style="opacity: 0.2; transform-origin: 266.99px 347.91px"
        class="animable">
        <path
          d="M170.93,359a236.92,236.92,0,0,1,40.95-16.7A237.24,237.24,0,0,1,170.93,359Z"
          id="elp6qn8apzxi"
          class="animable"
          style="transform-origin: 191.405px 350.65px"></path>
        <path
          d="M322.1,352.28a237.28,237.28,0,0,1,40.95-16.71,238.08,238.08,0,0,1-40.95,16.71Z"
          id="el9tk70ywhej"
          class="animable"
          style="transform-origin: 342.575px 343.925px"></path>
        <path
          d="M174.27,365.49a820.54,820.54,0,0,1,76-31,816.66,816.66,0,0,1-76,31Z"
          id="eler67yc9k8qm"
          class="animable"
          style="transform-origin: 212.27px 349.99px"></path>
        <path
          d="M266.48,363.37a820.54,820.54,0,0,1,76-31,816.66,816.66,0,0,1-76,31Z"
          id="elzp9g2fr6gtg"
          class="animable"
          style="transform-origin: 304.48px 347.87px"></path>
        <path
          d="M228.18,366q44.16-20.25,89.92-36.7Q274,349.52,228.18,366Z"
          id="elettx42whb1"
          class="animable"
          style="transform-origin: 273.14px 347.65px"></path>
        <path
          d="M195.45,367.13q46.32-21.11,94.23-38.44-46.31,21.13-94.23,38.44Z"
          id="el9tc68xd0q7"
          class="animable"
          style="transform-origin: 242.565px 347.91px"></path>
      </g>
    </g>
    <g id="ely9zcbj79fbh">
      <g
        style="opacity: 0.5; transform-origin: 267.315px 372.11px"
        class="animable">
        <path
          d="M257.05,388.27c42.48-4.61,85-13.85,123.73-32.32-21,12-44.25,19.85-67.84,25.11a307.1,307.1,0,0,1-55.89,7.21Z"
          style="fill: rgb(255, 255, 255); transform-origin: 318.915px 372.11px"
          id="elo3vd7qh0jp"
          class="animable"></path>
        <path
          d="M153.85,373.75A347.79,347.79,0,0,0,205,376.12c2.45,0,4.88-.18,7.33-.21a195.83,195.83,0,0,1-58.44-2.16Z"
          style="fill: rgb(255, 255, 255); transform-origin: 183.09px 375.454px"
          id="elujg9eg41s7"
          class="animable"></path>
      </g>
    </g>
  </g>
  <g
    id="freepik--cheese-2--inject-145"
    class="animable"
    style="transform-origin: 279.648px 336.878px">
    <path
      d="M405,337.12c-1.2-2.57-30-20.24-60.61-26.34-27.19-5.41-55.05-10-64.25-9.82-8.38.17-32.15,4.87-56.72,10.59-14.5,3.37-29.48,9.15-41.78,14.8a1.62,1.62,0,0,1,1.09.91c.51,1.35-1.43,3.33-4.34,4.43s-5.67.89-6.19-.45a1,1,0,0,1-.07-.31c-10.37,5.27-17.32,9.73-17.84,10.95-1,2.4,36.54-.22,65.49,6.77,30.55,7.36,52.76,24.29,60.5,24.15s33-18,63.22-26.54c2.18-.61,4.4-1.17,6.63-1.69.08-2.26,2.72-4.6,6.39-5.47,4.07-1,7.83.25,8.43,2.72C386.71,338.59,405.83,338.9,405,337.12Zm-193-6.51c-3.22,0-5.86-1.53-5.86-3.44s2.6-3.47,5.84-3.49,5.86,1.53,5.86,3.44S215.22,330.59,212,330.61Zm25.16-1.27c-6.47,1.24-12.21-.35-12.82-3.55s4.15-6.78,10.62-8,12.21.35,12.82,3.55S243.61,328.11,237.14,329.34Zm62,26.07c-1.56.29-3-.3-3.17-1.31s.91-2.09,2.47-2.38,3,.28,3.17,1.3S300.7,355.1,299.14,355.41Zm47.35-36.72c-2.23-.47-3.8-1.93-3.52-3.24s2.31-2,4.53-1.52,3.8,1.92,3.52,3.23S348.71,319.16,346.49,318.69Z"
      style="fill: rgb(29, 149, 243); transform-origin: 279.648px 336.878px"
      id="el8mxvrubrzbb"
      class="animable"></path>
    <g id="ele1qjr8s5ccr">
      <path
        d="M405,337.12c-1.2-2.57-30-20.24-60.61-26.34-27.19-5.41-55.05-10-64.25-9.82-8.38.17-32.15,4.87-56.72,10.59-14.5,3.37-29.48,9.15-41.78,14.8a1.62,1.62,0,0,1,1.09.91c.51,1.35-1.43,3.33-4.34,4.43s-5.67.89-6.19-.45a1,1,0,0,1-.07-.31c-10.37,5.27-17.32,9.73-17.84,10.95-1,2.4,36.54-.22,65.49,6.77,30.55,7.36,52.76,24.29,60.5,24.15s33-18,63.22-26.54c2.18-.61,4.4-1.17,6.63-1.69.08-2.26,2.72-4.6,6.39-5.47,4.07-1,7.83.25,8.43,2.72C386.71,338.59,405.83,338.9,405,337.12Zm-193-6.51c-3.22,0-5.86-1.53-5.86-3.44s2.6-3.47,5.84-3.49,5.86,1.53,5.86,3.44S215.22,330.59,212,330.61Zm25.16-1.27c-6.47,1.24-12.21-.35-12.82-3.55s4.15-6.78,10.62-8,12.21.35,12.82,3.55S243.61,328.11,237.14,329.34Zm62,26.07c-1.56.29-3-.3-3.17-1.31s.91-2.09,2.47-2.38,3,.28,3.17,1.3S300.7,355.1,299.14,355.41Zm47.35-36.72c-2.23-.47-3.8-1.93-3.52-3.24s2.31-2,4.53-1.52,3.8,1.92,3.52,3.23S348.71,319.16,346.49,318.69Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.2;
          transform-origin: 279.648px 336.878px;
        "
        class="animable"></path>
    </g>
    <g id="eljgpim1il97">
      <path
        d="M247.76,321.33c-.61-3.2-6.36-4.78-12.82-3.55-6.25,1.19-10.88,4.58-10.65,7.68-.63-4.79,3.91-7.55,9.36-9.06S247.12,316.84,247.76,321.33Z"
        style="opacity: 0.1; transform-origin: 235.996px 320.678px"
        class="animable"></path>
    </g>
    <g id="elonul1t9bpm">
      <path
        d="M217.82,327.12c0-1.91-2.64-3.45-5.86-3.44s-5.85,1.58-5.84,3.49c0,0-.71-4.13,6-4.55S217.82,327.12,217.82,327.12Z"
        style="opacity: 0.1; transform-origin: 211.986px 324.882px"
        class="animable"></path>
    </g>
    <g id="elco7ir3mvp9">
      <path
        d="M301.52,352.77c-.35-.86-1.67-1.33-3.08-1s-2.67,1.36-2.47,2.38c0,0-.51-1.78,1.83-2.72S301.44,352,301.52,352.77Z"
        style="opacity: 0.1; transform-origin: 298.721px 352.641px"
        class="animable"></path>
    </g>
    <g id="elb9993s0rfn8">
      <path
        d="M350.51,315.55a5.39,5.39,0,0,0-3-1.62c-2.22-.48-4.26.21-4.53,1.52a1.91,1.91,0,0,0,.48,1.59c-1.21-1.12-1.48-3.52,1.41-4.1S350,314,350.51,315.55Z"
        style="opacity: 0.1; transform-origin: 346.554px 314.93px"
        class="animable"></path>
    </g>
    <g id="elu4wutih7m0p">
      <g
        style="opacity: 0.5; transform-origin: 279.865px 342.047px"
        class="animable">
        <path
          d="M204.26,327.75c1.69,4.33,9.93,5.2,13.28,2.43a3.07,3.07,0,0,0,1.24-1.75,3,3,0,0,1-.9,2.1c-3.21,3.81-12.87,2.58-13.62-2.78Z"
          style="fill: rgb(255, 255, 255); transform-origin: 211.52px 330.246px"
          id="elr2j35ir56zk"
          class="animable"></path>
        <path
          d="M223.39,327.15c3.56,6.26,20.58,4.38,24.33-1a6.47,6.47,0,0,0,1.37-3.54,6.31,6.31,0,0,1-1,3.82c-3.73,6.39-21.46,8.22-24.73.74Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 236.231px 327.328px;
          "
          id="elpgfubavzer"
          class="animable"></path>
        <path
          d="M294.51,354.1a4.25,4.25,0,0,0,4,2,7.4,7.4,0,0,0,3.33-1.32c.34-.24.67-.5,1-.73-.25.35-.53.67-.81,1a5.65,5.65,0,0,1-3.5,1.78c-1.72.15-3.87-1-4.09-2.79Z"
          style="fill: rgb(255, 255, 255); transform-origin: 298.64px 355.442px"
          id="elit2mhgatkn"
          class="animable"></path>
        <path
          d="M341,314.92c-.08,2.54,2.42,4.27,4.67,4.78s5.33.84,6.51-1.57a2.62,2.62,0,0,1-1,1.63c-3.47,2.41-11.08.1-10.18-4.84Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 346.553px 317.821px;
          "
          id="eliz07b7ad1pm"
          class="animable"></path>
        <path
          d="M160.17,340.6c20.54.43,41.31.54,61.5,4.76,12,3,23.31,8.32,34.66,13.12l11.3,5c4.31,1.69,8.59,4.12,13.13,4.82a85,85,0,0,0,9.6-3.66l11.37-4.8c15.2-6.25,30.46-12.76,46.55-16.33a177.19,177.19,0,0,0-23.44,7.64c-11.07,4.25-23.18,9.57-34.14,14.29a90.72,90.72,0,0,1-9.44,3.66,4.71,4.71,0,0,1-2.47-.22,122.73,122.73,0,0,1-11.54-4.52c-18.77-7.75-37.45-18.15-57.88-20.3-16.3-2.19-32.77-2.82-49.2-3.43Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 254.225px 354.887px;
          "
          id="el9add0e2icil"
          class="animable"></path>
        <path
          d="M366.85,339.29c10.81-1.61,21.77-2.91,32.71-2.41-10.9.87-21.77,2.15-32.71,2.41Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 383.205px 338.031px;
          "
          id="elic3jqmxbx9"
          class="animable"></path>
      </g>
    </g>
  </g>
  <g
    id="freepik--Onions--inject-145"
    class="animable"
    style="transform-origin: 250px 319.811px">
    <path
      d="M180.45,316.23,178.59,320a4.45,4.45,0,0,0,.32-.93c1.38-6.67-14.59-19.25-39.72-26.64-23.91-7-42.31-5.3-45.22.63l1.86-3.8c2.91-5.93,21.31-7.66,45.22-.63,25.13,7.4,41.1,20,39.72,26.64A4.34,4.34,0,0,1,180.45,316.23Z"
      style="fill: rgb(29, 149, 243); transform-origin: 137.411px 302.023px"
      id="elddertd8e6o"
      class="animable"></path>
    <g id="el2hohujjjq8q">
      <g
        style="opacity: 0.4; transform-origin: 137.411px 302.023px"
        class="animable">
        <path
          d="M180.45,316.23,178.59,320a4.45,4.45,0,0,0,.32-.93c1.38-6.67-14.59-19.25-39.72-26.64-23.91-7-42.31-5.3-45.22.63l1.86-3.8c2.91-5.93,21.31-7.66,45.22-.63,25.13,7.4,41.1,20,39.72,26.64A4.34,4.34,0,0,1,180.45,316.23Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 137.411px 302.023px;
          "
          id="el6lb0jvibyl3"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M142.07,283.74c27.16,8,46.71,22.61,44.51,33.26s-25.21,12.76-52.37,4.77S87.5,299.17,89.7,288.5,114.92,275.75,142.07,283.74Zm-6.84,33.11c25.12,7.39,44.16,5.12,45.54-1.56s-14.59-19.24-39.72-26.64-44.16-5.1-45.54,1.56,14.59,19.25,39.72,26.64"
      style="fill: rgb(29, 149, 243); transform-origin: 138.14px 302.75px"
      id="elog3yzvtd2k"
      class="animable"></path>
    <g id="elrdaeh51zo2o">
      <g
        style="opacity: 0.7; transform-origin: 138.14px 302.75px"
        class="animable">
        <path
          d="M142.07,283.74c27.16,8,46.71,22.61,44.51,33.26s-25.21,12.76-52.37,4.77S87.5,299.17,89.7,288.5,114.92,275.75,142.07,283.74Zm-6.84,33.11c25.12,7.39,44.16,5.12,45.54-1.56s-14.59-19.24-39.72-26.64-44.16-5.1-45.54,1.56,14.59,19.25,39.72,26.64"
          style="fill: rgb(255, 255, 255); transform-origin: 138.14px 302.75px"
          id="elo7wzuvus6lb"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M185.93,318.88l-1.86,3.79c-4.41,9-26.24,10.4-51.72,2.9-27.15-8-46.71-22.6-44.51-33.27a7.94,7.94,0,0,1,.64-1.87l1.86-3.8a8.1,8.1,0,0,0-.64,1.87c-2.2,10.67,17.36,25.29,44.51,33.27C159.7,329.27,181.52,327.89,185.93,318.88Z"
      style="fill: rgb(29, 149, 243); transform-origin: 136.801px 308.521px"
      id="elqp850lmq4e"
      class="animable"></path>
    <g id="el2pi94pmti7d">
      <g
        style="opacity: 0.4; transform-origin: 136.801px 308.521px"
        class="animable">
        <path
          d="M185.93,318.88l-1.86,3.79c-4.41,9-26.24,10.4-51.72,2.9-27.15-8-46.71-22.6-44.51-33.27a7.94,7.94,0,0,1,.64-1.87l1.86-3.8a8.1,8.1,0,0,0-.64,1.87c-2.2,10.67,17.36,25.29,44.51,33.27C159.7,329.27,181.52,327.89,185.93,318.88Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 136.801px 308.521px;
          "
          id="eluptxpsxr7re"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M335.66,312.06l-.3,4.21a3.57,3.57,0,0,0-.05-1c-1.24-6.7-20.77-12.33-46.83-9.7-24.8,2.51-41.19,11.05-41.65,17.64l.3-4.22c.45-6.59,16.84-15.13,41.64-17.63,26.06-2.64,45.59,3,46.83,9.69A4.06,4.06,0,0,1,335.66,312.06Z"
      style="fill: rgb(29, 149, 243); transform-origin: 291.249px 311.953px"
      id="elb50zhx9mmfh"
      class="animable"></path>
    <g id="el110bm03a4mla">
      <g
        style="opacity: 0.4; transform-origin: 291.249px 311.953px"
        class="animable">
        <path
          d="M335.66,312.06l-.3,4.21a3.57,3.57,0,0,0-.05-1c-1.24-6.7-20.77-12.33-46.83-9.7-24.8,2.51-41.19,11.05-41.65,17.64l.3-4.22c.45-6.59,16.84-15.13,41.64-17.63,26.06-2.64,45.59,3,46.83,9.69A4.06,4.06,0,0,1,335.66,312.06Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 291.249px 311.953px;
          "
          id="el2ku3kkspg42"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M287.86,296.44c28.17-2.84,51.78,3.32,53.76,14s-18.52,21.33-46.7,24.17-51.78-3.32-53.76-14S259.7,299.29,287.86,296.44ZM294,329.69c26.06-2.63,42.83-11.92,41.59-18.62s-20.77-12.33-46.83-9.69S245.94,313.31,247.18,320,268,332.32,294,329.69"
      style="fill: rgb(29, 149, 243); transform-origin: 291.39px 315.525px"
      id="elmcu8kef98nq"
      class="animable"></path>
    <g id="elh75lmoox5yv">
      <g
        style="opacity: 0.7; transform-origin: 291.39px 315.525px"
        class="animable">
        <path
          d="M287.86,296.44c28.17-2.84,51.78,3.32,53.76,14s-18.52,21.33-46.7,24.17-51.78-3.32-53.76-14S259.7,299.29,287.86,296.44ZM294,329.69c26.06-2.63,42.83-11.92,41.59-18.62s-20.77-12.33-46.83-9.69S245.94,313.31,247.18,320,268,332.32,294,329.69"
          style="fill: rgb(255, 255, 255); transform-origin: 291.39px 315.525px"
          id="el25tgj7gz1ey"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M341.74,312.44l-.29,4.22c-.7,10-20.38,19.52-46.82,22.19-28.16,2.84-51.78-3.32-53.76-14a8.19,8.19,0,0,1-.12-2l.3-4.22a7.75,7.75,0,0,0,.11,2c2,10.7,25.61,16.87,53.76,14C321.36,332,341.05,322.45,341.74,312.44Z"
      style="fill: rgb(29, 149, 243); transform-origin: 291.237px 325.99px"
      id="el5jd2vns0o8j"
      class="animable"></path>
    <g id="elpl0lssgq0pg">
      <g
        style="opacity: 0.4; transform-origin: 291.237px 325.99px"
        class="animable">
        <path
          d="M341.74,312.44l-.29,4.22c-.7,10-20.38,19.52-46.82,22.19-28.16,2.84-51.78-3.32-53.76-14a8.19,8.19,0,0,1-.12-2l.3-4.22a7.75,7.75,0,0,0,.11,2c2,10.7,25.61,16.87,53.76,14C321.36,332,341.05,322.45,341.74,312.44Z"
          style="fill: rgb(255, 255, 255); transform-origin: 291.237px 325.99px"
          id="elgl08ubzyzu"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M268.21,337l-.59,4.18a4.1,4.1,0,0,0,0-1c-.74-6.76-19.81-13.8-46-13.08-24.92.68-41.89,8-42.83,14.55l.6-4.19c.94-6.54,17.91-13.86,42.83-14.55,26.18-.72,45.25,6.32,46,13.09A3.65,3.65,0,0,1,268.21,337Z"
      style="fill: rgb(29, 149, 243); transform-origin: 223.52px 332.255px"
      id="el3ahnywidf2b"
      class="animable"></path>
    <g id="elw59uxajuqvj">
      <g
        style="opacity: 0.4; transform-origin: 223.52px 332.255px"
        class="animable">
        <path
          d="M268.21,337l-.59,4.18a4.1,4.1,0,0,0,0-1c-.74-6.76-19.81-13.8-46-13.08-24.92.68-41.89,8-42.83,14.55l.6-4.19c.94-6.54,17.91-13.86,42.83-14.55,26.18-.72,45.25,6.32,46,13.09A3.65,3.65,0,0,1,268.21,337Z"
          style="fill: rgb(255, 255, 255); transform-origin: 223.52px 332.255px"
          id="elwutfs1eas58"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M221.69,317.9c28.3-.78,51.4,7.09,52.59,17.9s-20,19.92-48.34,20.7-51.4-7.09-52.59-17.92S193.39,318.68,221.69,317.9Zm3.7,33.6c26.18-.72,43.59-8.76,42.84-15.53s-19.81-13.81-46-13.09-43.59,8.77-42.84,15.54,19.81,13.81,46,13.08"
      style="fill: rgb(29, 149, 243); transform-origin: 223.815px 337.2px"
      id="el5e0q8lj4xh7"
      class="animable"></path>
    <g id="elfawqlv6ug9o">
      <g
        style="opacity: 0.7; transform-origin: 223.815px 337.2px"
        class="animable">
        <path
          d="M221.69,317.9c28.3-.78,51.4,7.09,52.59,17.9s-20,19.92-48.34,20.7-51.4-7.09-52.59-17.92S193.39,318.68,221.69,317.9Zm3.7,33.6c26.18-.72,43.59-8.76,42.84-15.53s-19.81-13.81-46-13.09-43.59,8.77-42.84,15.54,19.81,13.81,46,13.08"
          style="fill: rgb(255, 255, 255); transform-origin: 223.815px 337.2px"
          id="eltzmyv1lqtq"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M274.25,337.78l-.6,4.19c-1.42,9.93-21.75,18-48.31,18.71-28.29.78-51.4-7.09-52.59-17.91a7.28,7.28,0,0,1,0-2l.6-4.18a7.22,7.22,0,0,0,0,2c1.19,10.83,24.3,18.7,52.59,17.92C252.5,355.77,272.83,347.72,274.25,337.78Z"
      style="fill: rgb(29, 149, 243); transform-origin: 223.465px 348.662px"
      id="eluns9b7vjor"
      class="animable"></path>
    <g id="elq9ghjlt8t3g">
      <g
        style="opacity: 0.4; transform-origin: 223.465px 348.662px"
        class="animable">
        <path
          d="M274.25,337.78l-.6,4.19c-1.42,9.93-21.75,18-48.31,18.71-28.29.78-51.4-7.09-52.59-17.91a7.28,7.28,0,0,1,0-2l.6-4.18a7.22,7.22,0,0,0,0,2c1.19,10.83,24.3,18.7,52.59,17.92C252.5,355.77,272.83,347.72,274.25,337.78Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 223.465px 348.662px;
          "
          id="elsadwxqmtqi"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M406.12,330.91l-1.41,4a4.1,4.1,0,0,0,.21-1c.6-6.77-16.72-17.42-42.54-21.84-24.57-4.21-42.64-.36-44.84,5.87l1.41-4c2.19-6.22,20.27-10.08,44.84-5.87,25.81,4.42,43.13,15.07,42.54,21.85A3.92,3.92,0,0,1,406.12,330.91Z"
      style="fill: rgb(29, 149, 243); transform-origin: 361.942px 320.607px"
      id="el7g8g53qnq3k"
      class="animable"></path>
    <g id="elsor0n0zqv5f">
      <g
        style="opacity: 0.4; transform-origin: 361.942px 320.607px"
        class="animable">
        <path
          d="M406.12,330.91l-1.41,4a4.1,4.1,0,0,0,.21-1c.6-6.77-16.72-17.42-42.54-21.84-24.57-4.21-42.64-.36-44.84,5.87l1.41-4c2.19-6.22,20.27-10.08,44.84-5.87,25.81,4.42,43.13,15.07,42.54,21.85A3.92,3.92,0,0,1,406.12,330.91Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 361.942px 320.607px;
          "
          id="elaxu0utmp7g6"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M364.23,303.1c27.9,4.79,49,17,48.07,27.87s-23.56,15.6-51.46,10.82-49-17-48.07-27.87S336.33,298.32,364.23,303.1Zm-3,33.69c25.81,4.42,44.45-.05,45.05-6.84s-16.73-17.43-42.54-21.85-44.46.06-45.05,6.84,16.72,17.42,42.54,21.85"
      style="fill: rgb(29, 149, 243); transform-origin: 362.535px 322.445px"
      id="eltrnuky5xcyd"
      class="animable"></path>
    <g id="elcqboas5ev55">
      <g
        style="opacity: 0.7; transform-origin: 362.535px 322.445px"
        class="animable">
        <path
          d="M364.23,303.1c27.9,4.79,49,17,48.07,27.87s-23.56,15.6-51.46,10.82-49-17-48.07-27.87S336.33,298.32,364.23,303.1Zm-3,33.69c25.81,4.42,44.45-.05,45.05-6.84s-16.73-17.43-42.54-21.85-44.46.06-45.05,6.84,16.72,17.42,42.54,21.85"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 362.535px 322.445px;
          "
          id="el945mfl6pbb"
          class="animable"></path>
      </g>
    </g>
    <path
      d="M411.88,332.91l-1.41,4c-3.34,9.47-24.86,13.38-51,8.89-27.9-4.78-49-17-48.07-27.88a7.82,7.82,0,0,1,.42-1.93l1.41-4a7.9,7.9,0,0,0-.42,1.94c-.95,10.85,20.17,23.09,48.07,27.87C387,346.28,408.54,342.37,411.88,332.91Z"
      style="fill: rgb(29, 149, 243); transform-origin: 361.625px 329.824px"
      id="el8qfqnem6sg5"
      class="animable"></path>
    <g id="elrh3n58jwc7">
      <g
        style="opacity: 0.4; transform-origin: 361.625px 329.824px"
        class="animable">
        <path
          d="M411.88,332.91l-1.41,4c-3.34,9.47-24.86,13.38-51,8.89-27.9-4.78-49-17-48.07-27.88a7.82,7.82,0,0,1,.42-1.93l1.41-4a7.9,7.9,0,0,0-.42,1.94c-.95,10.85,20.17,23.09,48.07,27.87C387,346.28,408.54,342.37,411.88,332.91Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 361.625px 329.824px;
          "
          id="eljmmes6w5pk"
          class="animable"></path>
      </g>
    </g>
  </g>
  <g
    id="freepik--burger-1--inject-145"
    class="animable"
    style="transform-origin: 250.001px 285.523px">
    <path
      d="M371.45,304.49c-2.91,4.35-9.54,8.29-24.3,10.71-22.71,3.68-55.65,7.95-98.91,4.15s-75.61-13.77-97.81-21.43-25-15.49-24.72-22.34c.15-3.37.81-6.51,2.81-8.95,2.88-4.37,9.49-8.34,24.34-10.76,22.71-3.69,55.65-8,98.9-4.15s75.61,13.77,97.82,21.42,25,15.5,24.71,22.34C374.14,298.88,373.47,302,371.45,304.49Z"
      style="fill: rgb(29, 149, 243); transform-origin: 250.001px 285.523px"
      id="elj85upcas11j"
      class="animable"></path>
    <g id="eli8djcfqo198">
      <g
        style="opacity: 0.5; transform-origin: 250.001px 285.523px"
        class="animable">
        <path
          d="M371.45,304.49c-2.91,4.35-9.54,8.29-24.3,10.71-22.71,3.68-55.65,7.95-98.91,4.15s-75.61-13.77-97.81-21.43-25-15.49-24.72-22.34c.15-3.37.81-6.51,2.81-8.95,2.88-4.37,9.49-8.34,24.34-10.76,22.71-3.69,55.65-8,98.9-4.15s75.61,13.77,97.82,21.42,25,15.5,24.71,22.34C374.14,298.88,373.47,302,371.45,304.49Z"
          id="elg1t03a5lx4q"
          class="animable"
          style="transform-origin: 250.001px 285.523px"></path>
      </g>
    </g>
    <g id="elfmqba5pech">
      <g
        style="opacity: 0.1; transform-origin: 255.915px 270.754px"
        class="animable">
        <path
          d="M340.27,281.91c-1.73,1.69-6,3.11-16,3.68a436.53,436.53,0,0,1-67.51-1.09A471.26,471.26,0,0,1,188.44,273c-15.68-3.74-18.06-7-18.28-9.81a3.74,3.74,0,0,1,1.38-3.57c1.7-1.7,6-3.13,16-3.7A437.81,437.81,0,0,1,255.07,257a472.66,472.66,0,0,1,68.32,11.49c15.69,3.74,18.07,7,18.28,9.81A3.78,3.78,0,0,1,340.27,281.91Z"
          id="ellgapzs7qeo9"
          class="animable"
          style="transform-origin: 255.915px 270.754px"></path>
      </g>
    </g>
    <g id="eluxyu5hgfazg">
      <path
        d="M134.07,267.61a55.48,55.48,0,0,0,6.93,2.47c16.43,4.94,33.2,8.73,50,12.07s33.8,6,50.83,7.73c31.71,2.66,63.65,4.56,95.45,2.94,4.89-.3,9.78-.69,14.62-1.45s9.8-1.6,13.87-4.39c-3.95,3-9,3.94-13.78,4.91-24.28,4-49.08,3.21-73.58,2.37a501.8,501.8,0,0,1-130.53-21.6,104,104,0,0,1-13.84-5.05Z"
        style="opacity: 0.2; transform-origin: 249.905px 281.276px"
        class="animable"></path>
    </g>
    <g id="el4hczjbwldk5">
      <g
        style="opacity: 0.2; transform-origin: 250.475px 271.265px"
        class="animable">
        <path
          d="M154.2,261.46a237,237,0,0,1,43.63-7.26,236.9,236.9,0,0,1-43.63,7.26Z"
          id="el0y80s0v73w9f"
          class="animable"
          style="transform-origin: 176.015px 257.83px"></path>
        <path
          d="M303.12,288.33a237.87,237.87,0,0,1,43.63-7.25,238,238,0,0,1-43.63,7.25Z"
          id="el0euxjie3uuu4"
          class="animable"
          style="transform-origin: 324.935px 284.705px"></path>
        <path
          d="M156,268.57a818.81,818.81,0,0,1,81-13.46,818.81,818.81,0,0,1-81,13.46Z"
          id="el9d2djy9jd19"
          class="animable"
          style="transform-origin: 196.5px 261.84px"></path>
        <path
          d="M246.43,286.87a818.12,818.12,0,0,1,81-13.46q-40.14,8.76-81,13.46Z"
          id="el64mpl4dfiyf"
          class="animable"
          style="transform-origin: 286.93px 280.14px"></path>
        <path
          d="M208.49,280.92q47.55-10,95.82-15.93-47.55,10-95.82,15.93Z"
          id="eltrjngdi33s"
          class="animable"
          style="transform-origin: 256.4px 272.955px"></path>
        <path
          d="M176.32,274.85q49.83-10.37,100.39-16.69-49.83,10.38-100.39,16.69Z"
          id="elvwqdk0rb20m"
          class="animable"
          style="transform-origin: 226.515px 266.505px"></path>
      </g>
    </g>
    <g id="elu6m0wuly1sk">
      <g
        style="opacity: 0.5; transform-origin: 224.235px 286.104px"
        class="animable">
        <path
          d="M137.79,273c14.88,5.7,30.42,9.52,46,13a535.48,535.48,0,0,0,63,9.48c5.29.57,10.6.83,15.91,1.35-2.66,0-5.33-.06-8-.13A355.72,355.72,0,0,1,199,291c-20.9-3.94-41.85-9.1-61.21-18Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 200.245px 284.915px;
          "
          id="ellt3gc0wh55d"
          class="animable"></path>
        <path
          d="M269.23,297c13.61-.21,27.84.21,41.45.94a126.76,126.76,0,0,1-41.45-.94Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 289.955px 298.077px;
          "
          id="eluqmewe32fmj"
          class="animable"></path>
      </g>
    </g>
  </g>
  <g
    id="freepik--cheese-1--inject-145"
    class="animable"
    style="transform-origin: 249.983px 261.072px">
    <path
      d="M307.29,232.05c-13-3.56-25.67-6.81-36-9.16a.83.83,0,0,1,0,.22c0,.91-1.18,1.64-2.65,1.64S266,224,266,223.11a1.44,1.44,0,0,1,.86-1.2A123.24,123.24,0,0,0,251,219.23a127.75,127.75,0,0,0-16.87.89,3.29,3.29,0,0,1,.56,1.83c0,2.41-2.73,4.39-6.11,4.39s-6.12-2-6.12-4.39a2.5,2.5,0,0,1,.05-.55c-10.92,1.32-23.56,3.13-36.08,5.1-30.87,4.89-60.31,21.41-61.6,23.92-1.2,2.3,32.76,2.36,61.08,11.57,29.89,9.7,54.4,40.47,62.13,40.93s30.59-27.52,61.4-33.66c29.2-5.83,66.62-1.75,65.71-4.18C374.09,262.23,339.69,240.94,307.29,232.05ZM184,234.32c-2.24.38-4.24-.39-4.47-1.71s1.41-2.71,3.65-3.09,4.24.38,4.48,1.7S186.28,233.93,184,234.32Zm49.09,39.3c-.24,1-1.68,1.54-3.22,1.18s-2.61-1.46-2.37-2.48,1.67-1.53,3.22-1.18S233.34,272.62,233.12,273.62Zm38.28,6.45c-4.3,1.69-8.77.62-10-2.4s1.34-6.86,5.66-8.56,8.76-.62,10,2.41S275.72,278.37,271.4,280.07Zm13.23-13.39c-3.22-.14-5.77-1.8-5.69-3.71s2.76-3.34,6-3.2,5.77,1.8,5.69,3.71S287.86,266.82,284.63,266.68Zm28.51-22.31c-3.23-.14-5.78-1.81-5.7-3.72s2.78-3.34,6-3.2,5.78,1.81,5.7,3.71S316.36,244.5,313.14,244.37Zm41.57,12.27c-.24,1-1.68,1.55-3.23,1.18s-2.6-1.46-2.37-2.47,1.68-1.54,3.22-1.18S354.94,255.64,354.71,256.64Z"
      style="fill: rgb(29, 149, 243); transform-origin: 249.983px 261.072px"
      id="elbh0sje07756"
      class="animable"></path>
    <g id="eltdttj2bj5sn">
      <path
        d="M307.29,232.05c-13-3.56-25.67-6.81-36-9.16a.83.83,0,0,1,0,.22c0,.91-1.18,1.64-2.65,1.64S266,224,266,223.11a1.44,1.44,0,0,1,.86-1.2A123.24,123.24,0,0,0,251,219.23a127.75,127.75,0,0,0-16.87.89,3.29,3.29,0,0,1,.56,1.83c0,2.41-2.73,4.39-6.11,4.39s-6.12-2-6.12-4.39a2.5,2.5,0,0,1,.05-.55c-10.92,1.32-23.56,3.13-36.08,5.1-30.87,4.89-60.31,21.41-61.6,23.92-1.2,2.3,32.76,2.36,61.08,11.57,29.89,9.7,54.4,40.47,62.13,40.93s30.59-27.52,61.4-33.66c29.2-5.83,66.62-1.75,65.71-4.18C374.09,262.23,339.69,240.94,307.29,232.05ZM184,234.32c-2.24.38-4.24-.39-4.47-1.71s1.41-2.71,3.65-3.09,4.24.38,4.48,1.7S186.28,233.93,184,234.32Zm49.09,39.3c-.24,1-1.68,1.54-3.22,1.18s-2.61-1.46-2.37-2.48,1.67-1.53,3.22-1.18S233.34,272.62,233.12,273.62Zm38.28,6.45c-4.3,1.69-8.77.62-10-2.4s1.34-6.86,5.66-8.56,8.76-.62,10,2.41S275.72,278.37,271.4,280.07Zm13.23-13.39c-3.22-.14-5.77-1.8-5.69-3.71s2.76-3.34,6-3.2,5.77,1.8,5.69,3.71S287.86,266.82,284.63,266.68Zm28.51-22.31c-3.23-.14-5.78-1.81-5.7-3.72s2.78-3.34,6-3.2,5.78,1.81,5.7,3.71S316.36,244.5,313.14,244.37Zm41.57,12.27c-.24,1-1.68,1.55-3.23,1.18s-2.6-1.46-2.37-2.47,1.68-1.54,3.22-1.18S354.94,255.64,354.71,256.64Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.2;
          transform-origin: 249.983px 261.072px;
        "
        class="animable"></path>
    </g>
    <g id="el5acak14qsz">
      <path
        d="M187.62,231c-.43-1.17-2.31-1.81-4.41-1.45s-3.88,1.77-3.65,3.09c-.67-1.71.82-3.22,3.32-3.81S187.27,229.21,187.62,231Z"
        style="opacity: 0.1; transform-origin: 183.51px 230.649px"
        class="animable"></path>
    </g>
    <g id="eldx1dwf7usad">
      <path
        d="M233.12,273.62c.22-1-.84-2.11-2.37-2.48s-3,.18-3.22,1.18c-.07-2.56,2.27-2.55,3.95-1.92A2.75,2.75,0,0,1,233.12,273.62Z"
        style="opacity: 0.1; transform-origin: 230.366px 271.834px"
        class="animable"></path>
    </g>
    <g id="elkru7i55ajtf">
      <path
        d="M275.73,269.74c-1.93-1.61-5.33-1.94-8.63-.63-4.32,1.7-6.85,5.54-5.66,8.56,0,.11.08.2.13.31-1.59-2.55-3.08-7.26,3.79-10.45C270.42,265.18,274,267.78,275.73,269.74Z"
        style="opacity: 0.1; transform-origin: 267.98px 272.289px"
        class="animable"></path>
    </g>
    <g id="elsh0kxw65bz">
      <path
        d="M290.51,262.71c-.52-1.56-2.8-2.82-5.57-2.94-3.24-.14-5.91,1.3-6,3.2a1.58,1.58,0,0,0,0,.38c-.61-2.18.58-4.39,4.65-4.59S290.63,261,290.51,262.71Z"
        style="opacity: 0.1; transform-origin: 284.652px 261.049px"
        class="animable"></path>
    </g>
    <g id="eluyz2aksnhhm">
      <path
        d="M318.7,239.74c-.86-1.25-2.88-2.18-5.26-2.29-3.22-.14-5.91,1.3-6,3.2a2.46,2.46,0,0,0,.68,1.72c-1.69-1.56-2.42-4.56,1.65-5.61S317.89,237.19,318.7,239.74Z"
        style="opacity: 0.1; transform-origin: 312.735px 239.394px"
        class="animable"></path>
    </g>
    <g id="elal0jtacjze8">
      <path
        d="M354.46,255.5a3.51,3.51,0,0,0-2.13-1.33c-1.54-.36-3,.17-3.22,1.18a1.42,1.42,0,0,0,.13.94c-.77-1.22-.59-2.44,1.24-3A3.32,3.32,0,0,1,354.46,255.5Z"
        style="opacity: 0.1; transform-origin: 351.635px 254.739px"
        class="animable"></path>
    </g>
    <g id="el7g0fhl1g0sq">
      <g
        style="opacity: 0.5; transform-origin: 251.49px 261.007px"
        class="animable">
        <path
          d="M132.75,249.35a211.34,211.34,0,0,1,32.41,3.32c15.91,3,32.41,6.78,45.76,16.44,8.94,6.22,17,13.59,25.12,20.81a91.82,91.82,0,0,0,9.4,7.68c1,.58,2.13,1.46,3.33,1,4.11-2,9.49-6.49,13.32-9.12,4.41-3.18,8.88-6.28,13.5-9.18,22.74-14.43,35.85-15.72,62.11-16.68,7.9-.24,16.53-.11,24.46.08l4.07.11,2,0a9.07,9.07,0,0,0,2-.09,27.32,27.32,0,0,1-4.06.11l-4.08-.05c-8-.06-16.54-.07-24.43.29-26.16,1.12-39.11,2.41-61.69,16.9-4.58,2.9-9,6-13.4,9.22-4,2.75-9.17,7.22-13.52,9.31a3,3,0,0,1-2.32-.13A23.88,23.88,0,0,1,241.6,296c-2.11-1.73-4.15-3.54-6.17-5.35-8.06-7.26-16.06-14.64-24.94-20.89-13.24-9.68-29.58-13.47-45.42-16.57a211.59,211.59,0,0,0-32.32-3.8Z"
          style="fill: rgb(255, 255, 255); transform-origin: 251.49px 274.512px"
          id="el9h7tewy1dzv"
          class="animable"></path>
        <path
          d="M226.47,272.31a4.32,4.32,0,0,0,3.35,2.95c1.43.2,3.48.42,4.13-1.23a1.72,1.72,0,0,1-.48,1.2,4.69,4.69,0,0,1-6.11-.51,4.33,4.33,0,0,1-.89-2.41Z"
          style="fill: rgb(255, 255, 255); transform-origin: 230.21px 274.219px"
          id="el26sano4j0it"
          class="animable"></path>
        <path
          d="M260.88,279.57c5.16,4.32,14,2.24,16.84-3.83a10,10,0,0,0,.88-2.71c-.06,8.45-12.16,12.78-17.72,6.54Z"
          style="fill: rgb(255, 255, 255); transform-origin: 269.74px 277.778px"
          id="elljw0pfq34y"
          class="animable"></path>
        <path
          d="M278.24,265.11c3.12,3.39,11.8,3.71,13.74-1.08a3.87,3.87,0,0,1-.8,1.9c-3.16,3.58-10.32,3.22-12.94-.82Z"
          style="fill: rgb(255, 255, 255); transform-origin: 285.11px 266.212px"
          id="el758qchjx4ql"
          class="animable"></path>
        <path
          d="M177.94,233.43c2.32,3.59,11.3,2.62,11.41-2.29.93,5.65-9.62,7-11.41,2.29Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 183.674px 233.714px;
          "
          id="elijef6u3cx1j"
          class="animable"></path>
        <path
          d="M221.05,223.19a8.47,8.47,0,0,0,8.1,4.73c3.25-.11,6.94-1.91,7.07-5.58a4.83,4.83,0,0,1-.38,2.56c-2.06,4.07-8.16,4.83-11.72,2.38-1.37-1-2.81-2.35-3.07-4.09Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 228.654px 225.508px;
          "
          id="elw9yfqbb5459"
          class="animable"></path>
        <path
          d="M305,239.93c.91,4.72,6.52,6,10.67,5.58,1.68-.16,3.59-.56,4.6-2a3.64,3.64,0,0,0,.63-2.51,3.6,3.6,0,0,1-.32,2.7c-1.55,2.52-5,2.7-7.61,2.6-3.55-.12-7.91-2.39-8-6.35Z"
          style="fill: rgb(255, 255, 255); transform-origin: 313px 243.125px"
          id="elzw4xh15ojvr"
          class="animable"></path>
        <path
          d="M347.83,256.29c1.55,2.72,5.95,3.79,8.24,1.43-1.58,3.43-7.67,2.13-8.24-1.43Z"
          style="fill: rgb(255, 255, 255); transform-origin: 351.95px 258.007px"
          id="elvt512sbzn1a"
          class="animable"></path>
      </g>
    </g>
  </g>
  <g
    id="freepik--Lettuce--inject-145"
    class="animable"
    style="transform-origin: 254.198px 231.352px">
    <path
      d="M250.45,190.39c-4.7-4.13-9.14-5.64-12.14-5.23s-4.06,3.85-6.25,4.61-9.7,3-12.46,2.19-9.08-2.14-12.47-2.74-2.73-.29-9.34,1-22.12,3.42-25.71,2.3-10.68-4.79-13.37-4.52-2.71,5.08-6.17,5.78-6.83-1.87-13.83.83-6,4.2-7.85,6.9-2.42,2.07-6.46,6.31.42,13.88,1,15.67,4.59,6.92,7.15,6.92,2-4.13,8.59-2.78-.36,6.78,5,10.41,6,4.5,8.22,3.61,2.15-3.75,6.38-2.4,1.65,3.16,5.86,4.25,3.8,2,8.28,2.18,1.29,5.13,6.28,5.79,8.65.47,11.14-.57,4.65.41,4.9,1.83-3.08,4.19.25,6.23-2.31,1.29,6.94,3.8,13.94,3.32,16.91-.08,5.28-7.28,8.94-7.34,1.53,11.23,12,11.09,13.86-5.26,17.69-5.32,0,10.52,9,10.46,10-7.79,14.47-8.28.57,8.81,13.49,8.81,9.46-6.73,15.91-8.22,10.95,6.42,21.32,6.37,9.13-4.28,14.07-4.4,7.05,5.36,8.78,7.48,1.94,5.23,6.48,4,3.85-9.56,7-12.32,4.74-.63,7.9-3.65,8.39-3.55,7.13-8.16-8.32-5.3-8.7-11.13,6.22-6,7.26-13-4.71-6.78-8.78-10.6-9.19,1.12-11.92-4.2.46-5.23-5.7-9.07-13.43,2.11-19-.08-14.21-9.93-18.79-11.65-7,3.47-9.83,3.36S301,192,295,192.55s-6.52,2.8-10.66,3-11-1.65-14.22-2.79-5.59,0-9.74,0S252.29,192,250.45,190.39Z"
      style="fill: rgb(69, 90, 100); transform-origin: 254.198px 231.352px"
      id="eln1j53khnl2p"
      class="animable"></path>
    <g id="elqvaq4jdnaso">
      <g
        style="opacity: 0.1; transform-origin: 254.337px 230.489px"
        class="animable">
        <path
          d="M286.83,257.75c2.72,3.55,3.81,6.22,6.4,6.89s15.27.95,12.16-3-8.4-39.24-11.23-41.8S285,255.42,286.83,257.75Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 296.191px 242.377px;
          "
          id="elup6tubk7w0l"
          class="animable"></path>
        <path
          d="M325.87,264.37c3,1.9,14.89,1.83,16.77-1.32s-9.69-8.16-13.19-12.38S319.09,231.74,316.74,231s-4-.16-6,2-1.21,7.51-1.23,10.56S313,256,314.21,257.21,323,262.55,325.87,264.37Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 326.109px 248.167px;
          "
          id="eli0gp21xsun9"
          class="animable"></path>
        <path
          d="M131.67,224.82c-1.63-.81-5.74-10.09-5-13s15.87-3.32,20.58-3.27,27.49-1.9,32.34-2.28,20.79.8,18.92,2.28-8.15,3.5-14.43,3.76-14.55,4.65-21.45,5.95S134.9,226.41,131.67,224.82Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 162.622px 215.628px;
          "
          id="elixtztsjfvxf"
          class="animable"></path>
        <path
          d="M201.35,256.42a52.3,52.3,0,0,0,11.83,3.95c2.27.25,7.11-1.14,9.8-3.95s11.42-9.69,14.41-12.85,9.2-19.23,9.2-19.23-7.28,5.61-11.05,7-11,5.83-14.3,7.12-11.16.13-15.92-.8-5.89,1.66-12.84,1.57a58.51,58.51,0,0,0-16.5,2.62c-1.61.61,3,.7,3.59,2.17s.77,3.64,2.9,4.14a24.72,24.72,0,0,0,10.27-.29c3.16-1,7.42.68,8.61,1.37S198.81,255.09,201.35,256.42Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 211.115px 242.369px;
          "
          id="elblyvmf1lmk7"
          class="animable"></path>
        <path
          d="M192.48,194.46c1.62-.5,10.34-4,13.24-3.58s11,3.29,16.55,4.85,18.65,5.41,21.17,6.67,11.66,5.89,10,7.07-7.67,0-10.83-.92-9.39-1.69-18.47-2.73-20.52-.9-24.94-2.8-13.57-3.18-12.31-4.77S192.48,194.46,192.48,194.46Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 220.206px 200.405px;
          "
          id="eloefwxsnbsx"
          class="animable"></path>
        <path
          d="M278.42,262.7c-3,.69-2.69,3.66-5.8,4s-5.15.5-6.34-1.71-2.27-7.66-4.79-7.75a29.77,29.77,0,0,0-9.32,1.71c-2.63.95-5.28,4-8,2.32s-4.47-2.56-5.43-4-1.91-3.38,0-5.82,7.75-3.08,9.85-5.84,7.26-10.5,7.73-13.36,8.56-3.79,10.44-6.4,8.51-4.74,9.44-4.31,1.53,3.71,2.19,6,3.13,7.81,3.44,10.16-1.71,13.07-.75,16.68S280.86,262.13,278.42,262.7Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 259.749px 244.192px;
          "
          id="el37hlso0myjp"
          class="animable"></path>
        <path
          d="M266.79,195.73c4-2.12,7,2.78,10.94,3.08s9.27-1.67,12.12-1.59,4.77,3.05,4.89,4.53-3.84,5-4.31,6.39-1.47,10.93-3.6,10.92-2.94-3-6.06-3.88-8.11,1.88-11.36,3.08-5.4,4-6,2,4.14-10.4,4.28-11.88.92-2-.9-5.31S265.74,196.29,266.79,195.73Z"
          style="fill: rgb(255, 255, 255); transform-origin: 279.05px 208.08px"
          id="eldtvd4e8eb9m"
          class="animable"></path>
        <path
          d="M360.06,270.13c2.34-.43,6-7,7.28-8.29s4-1.88,6.37-2.85,7.28-.66,7.16-3.49-9.55-5.87-9.09-11.91a16.68,16.68,0,0,1,5.11-11.28c1.71-1.46,7.74.52,4-4.79s-4.09-8.09-8.3-7.5-3.86,3.37-10.35,2.95-8.41-.08-15.35-.59a38.91,38.91,0,0,0-15.8,1.61c-4.55,1.4-8.82,2.34-6.63,4.54s13.71,4.36,19.38,4.48,14,.55,14.77,2.78-3.76,7.49-8.31,4.46-3.69-3.57-10.15-4.1-9.77-1.79-10-.36-3.54,1.69,0,3.55,8.25,6.17,10,7.77,6.86,5.85,6.06,6.39S358.55,270.42,360.06,270.13Z"
          style="fill: rgb(255, 255, 255); transform-origin: 352.98px 245.038px"
          id="ellz7xj48itd"
          class="animable"></path>
        <path
          d="M355.52,216.19c1.6-2.16,3.41-6.69-1.26-7.79s-20.68,3.76-26.2,3.1-2.4-.35-11.71-1.92-13.94-1.75-15.52-.66-3,4.79-1.11,7.27.65,9.95,5.08,8.15,8.3-5.09,13.65-6.12a32.58,32.58,0,0,1,15.16.34C338.69,219.93,354.49,217.58,355.52,216.19Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 328.031px 216.422px;
          "
          id="el3oy643nt2rl"
          class="animable"></path>
      </g>
    </g>
    <g id="ellx9f3xzv8xb">
      <path
        d="M339.37,268.37c2.56-.53,4.09-2.71,6.48-3.6a9.88,9.88,0,0,1,3.78-.49c4,.35,6.41,4.22,8.37,7.23,2.11,3.43,4.5,6.39,6.66,1,2.75-7.51,1.91-9.61,10.67-11.47a4,4,0,0,0,1.59-.68,28.28,28.28,0,0,1,3.27-1.85,7.31,7.31,0,0,0,2.91-2.2c-1.25,2.14-4.08,2.88-5.85,4.41-.89.81-2.48,1-3.55,1.37a16.5,16.5,0,0,0-3.36,1.35c-3.31,1.68-3.36,6.27-4.79,9.42-1,2.95-3.56,5.22-6.12,2.26-3.1-3.53-4.72-9.32-9.86-10.18a9.74,9.74,0,0,0-3.56.32c-2.32.7-4.12,2.86-6.64,3.14Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 361.235px 266.408px;
        "
        class="animable"></path>
    </g>
    <g id="eluzpyjym0vjs">
      <path
        d="M313.91,261.58c4-.17,7.6,2,11.1,3.5s7,3.25,10.91,3.64c-3.94.42-7.82-1.11-11.33-2.73-3.09-1.41-7.29-3.95-10.68-4.41Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 324.915px 265.18px;
        "
        class="animable"></path>
    </g>
    <g id="elv67fhbwb61">
      <path
        d="M280.54,263.09c2.4-1.82,6.77-2.36,8.22.93.73,1.38,1.09,2.89,2.08,3.82a7,7,0,0,0,3.92,1.32,20.25,20.25,0,0,0,8.72-.88c1.36-.43,2.12-1.82,3.1-3a9.61,9.61,0,0,1,3.52-2.88A10.29,10.29,0,0,0,307,265.6a15.69,15.69,0,0,1-2.13,2.75,11.83,11.83,0,0,1-5.56,1.77c-3,.22-6.6.45-9.1-1.61-1.21-1.25-1.52-2.85-2.11-4.16-1.2-3.08-5.1-2.54-7.53-1.26Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 295.32px 265.947px;
        "
        class="animable"></path>
    </g>
    <g id="el7mh9uwvz5w4">
      <path
        d="M247,264.05c3.54-1.51,14-8.23,16.42-2.86,1.09,2.69.24,6.11,2.29,8.33,2.42,2,5.68.2,7.87-1.35,1.38-1,2.68-2,4-3.05-2.65,2.06-5.06,4.7-8.45,5.52-4.61.91-5.38-3.36-5.72-6.84-.38-3-1.3-4.53-4.53-3.68-4.09.89-7.72,3.12-11.92,3.93Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 262.27px 264.954px;
        "
        class="animable"></path>
    </g>
    <g id="elzthoavj6z6i">
      <path
        d="M180.85,246.42c.17,1.81,2,2.73,3.64,2.89,4.66.51,9.59-1.76,14.08.25.61.28,1.43.92,1.39,1.71,0,1.63-.89,3.71-1.05,5.31a6.38,6.38,0,0,0,1,.72c1.43.9,2.47,2.19,4.13,2.87,3.71,1.64,8,3.05,12.07,2.27,5.21-1.15,7.31-7.14,12.29-8.79,4.77-.94,6.2,1.75,7.63,5.59a10.1,10.1,0,0,0,8.08,5.35,10.17,10.17,0,0,1-8.51-5.11c-1.5-3.51-2.59-6.06-7-5.09-4.72,1.85-7,7.83-12.26,9-4.37.91-8.76-.58-12.69-2.37-1.57-.62-2.78-2-4.23-3a4.47,4.47,0,0,1-1.24-1.06,2.42,2.42,0,0,1,.08-1.41l.66-2.48c.12-.65.5-1.64.21-2.19-.93-1.12-2.94-1.43-4.39-1.55-3.72-.36-13,2.44-13.83-2.92Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 212.48px 255.5px;
        "
        class="animable"></path>
    </g>
    <g id="elvngztx8i27">
      <path
        d="M131.89,228c1.15-.38,2.13-1.1,3.24-1.59a10.31,10.31,0,0,1,7.2-.33,12,12,0,0,1,3.45,1.39,1.52,1.52,0,0,1,.67.92c.45,2-.19,4.32.53,6.2a13.72,13.72,0,0,0,5.21,5.55c.27,0,.13.11.42,0,1.48-1,2.78-2.28,4.4-3.16,2.24-1.31,5-.34,7.34.35a5.15,5.15,0,0,1,2.53,1.44c.43.86-.33,1.9.32,2.56a17,17,0,0,0,8.3,2.77,17.09,17.09,0,0,1-7.06-1.46,4.22,4.22,0,0,1-1.66-.94c-.81-.74-.22-1.82-.49-2.62a5.7,5.7,0,0,0-2.18-1c-2.15-.5-4.6-1.48-6.66-.29-1.64.9-2.93,2.37-4.58,3.34a1.09,1.09,0,0,1-.74.08c-1.63-.53-2.79-1.93-3.91-3.11a11.65,11.65,0,0,1-2.08-3.12c-.77-2.14-.14-4.21-.46-6.32a.71.71,0,0,0-.35-.51,14.26,14.26,0,0,0-6.65-2c-2.45-.26-4.53,1.28-6.79,1.93Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 153.695px 234.843px;
        "
        class="animable"></path>
    </g>
    <g id="elv8zu6t4pj3q">
      <path
        d="M175.64,236.4a114.53,114.53,0,0,1,16.23-3.82c3.36-.61,6.65.75,10,1a51.48,51.48,0,0,0,9.83.55A42.75,42.75,0,0,0,221,231c2-.86,4.05-1.8,6.06-2.73-4.84,2.68-9.77,5.58-15.26,6.76a57.4,57.4,0,0,1-16.62-1.62c-6.53-.13-13.09,1.93-19.54,3Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 201.35px 232.34px;
        "
        class="animable"></path>
    </g>
    <g id="elkbloc9q7vv">
      <path
        d="M329.63,245.42a38.23,38.23,0,0,0,9.32,7.77,44.11,44.11,0,0,1,8.15,7.09,3.66,3.66,0,0,1,.83,1.3,4.71,4.71,0,0,0-1-1.12c-2.72-2.27-5.6-4.44-8.51-6.44a24.82,24.82,0,0,1-8.77-8.6Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 338.78px 253.5px;
        "
        class="animable"></path>
    </g>
    <g id="elxp6l9pyjjjl">
      <path
        d="M377.08,236.35a5.93,5.93,0,0,0-1.84,4c-.3,3,.38,5.93,2.92,7.78,1.16,1,2.4,1.87,3.68,2.75-2.83-1.12-6.19-2.74-7.15-5.88-.83-2.91-.55-7,2.39-8.6Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 378.062px 243.615px;
        "
        class="animable"></path>
    </g>
    <g id="el4f0vxc6d0hi">
      <path
        d="M249.09,197.85c-3.3-2.5-6.43-3.41-8.55-3.14s-2.89,2.38-4.43,2.86-6.87,1.87-8.81,1.41-6.4-1.26-8.79-1.62-1.92-.16-6.6.69-15.63,2.22-18.15,1.55-7.52-2.88-9.42-2.7S182.4,200,180,200.47s-4.8-1.1-9.76.59-4.22,2.61-5.58,4.27-1.72,1.28-4.59,3.9.22,8.51.63,9.6,3.21,4.22,5,4.2,1.41-2.54,6.09-1.75-.29,4.16,3.46,6.35,4.23,2.73,5.77,2.17,1.54-2.3,4.52-1.5,1.15,1.93,4.12,2.57,2.67,1.22,5.83,1.29.88,3.14,4.4,3.52,6.1.24,7.86-.41,3.29.23,3.46,1.1-2.2,2.58.13,3.81-1.63.8,4.89,2.3,9.82,2,11.93-.14,3.76-4.5,6.35-4.55,1,6.87,8.45,6.73,9.81-3.3,12.51-3.36,0,6.45,6.28,6.37,7.12-4.83,10.25-5.15.36,5.39,9.48,5.32,6.71-4.17,11.27-5.12,7.69,3.87,15,3.79,6.48-2.67,10-2.77,4.95,3.24,6.16,4.53,1.34,3.2,4.55,2.4,2.77-5.88,5-7.59,3.35-.41,5.59-2.28,5.94-2.22,5.08-5-5.85-3.2-6.08-6.77,4.42-3.74,5.19-8-3.28-4.13-6.14-6.45-6.49.74-8.39-2.51.35-3.2-4-5.53-9.49,1.37-13.41.06-10-6-13.2-7-4.92,2.16-7,2.11-6.39-2.94-10.65-2.56-4.61,1.76-7.53,1.91-7.73-1-10-1.63-3.94,0-6.87,0S250.37,198.83,249.09,197.85Z"
        style="opacity: 0.1; transform-origin: 251.56px 222.692px"
        class="animable"></path>
    </g>
  </g>
  <g
    id="freepik--Tomatoes--inject-145"
    class="animable"
    style="transform-origin: 250.001px 183.341px">
    <path
      d="M230.49,240.83c19.32,3.92,46.87-1.37,59.88-17,2.8-3.37,3.05-10.93,2.94-14.13-.44-11.77-17-17.5-31.93-20.48-19-3.79-50.08-.27-61.45,14.94-2.77,3.7-4.84,5.21-4.77,9.16C195.32,223.43,206.12,235.88,230.49,240.83Z"
      style="fill: rgb(29, 149, 243); transform-origin: 244.244px 214.943px"
      id="el3271uskgq3u"
      class="animable"></path>
    <g id="elc5h556fp1su">
      <path
        d="M230.49,240.83c19.32,3.92,46.87-1.37,59.88-17,2.8-3.37,3.05-10.93,2.94-14.13-.44-11.77-17-17.5-31.93-20.48-19-3.79-50.08-.27-61.45,14.94-2.77,3.7-4.84,5.21-4.77,9.16C195.32,223.43,206.12,235.88,230.49,240.83Z"
        style="opacity: 0.2; transform-origin: 244.244px 214.943px"
        class="animable"></path>
    </g>
    <path
      d="M229.67,236c19.32,3.93,46.87-1.37,59.88-17s-10-30.81-29-34.61-50.07-.27-61.44,14.94C189.27,212.49,195.77,229.1,229.67,236Z"
      style="fill: rgb(29, 149, 243); transform-origin: 244.142px 210.113px"
      id="elc8mui5lajzg"
      class="animable"></path>
    <g id="elw8eds2x50b">
      <path
        d="M229.67,236c19.32,3.93,46.87-1.37,59.88-17s-10-30.81-29-34.61-50.07-.27-61.44,14.94C189.27,212.49,195.77,229.1,229.67,236Z"
        style="opacity: 0.1; transform-origin: 244.142px 210.113px"
        class="animable"></path>
    </g>
    <path
      d="M232.18,231.2c15.78,3.21,38.29-1.12,48.91-13.9S272.9,192.13,257.41,189s-40.9-.22-50.19,12.2C199.17,212,204.49,225.57,232.18,231.2Z"
      style="fill: rgb(29, 149, 243); transform-origin: 243.996px 210.027px"
      id="elnegyjz23pz"
      class="animable"></path>
    <path
      d="M224.7,213.34c.07-.92-5.51-1.67-5.52.08C219.18,214.89,224.62,214.53,224.7,213.34Z"
      style="fill: rgb(255, 255, 255); transform-origin: 221.94px 213.374px"
      id="elpcqdk668ave"
      class="animable"></path>
    <path
      d="M238.34,199.89c1.54-.22,1.53-3.6-1.36-3.13C234.55,197.16,236.35,200.16,238.34,199.89Z"
      style="fill: rgb(255, 255, 255); transform-origin: 237.586px 198.311px"
      id="elnnobgowtxqr"
      class="animable"></path>
    <path
      d="M224.66,198.38c1.11-.67-2-3.45-4.07-2.12C218.9,197.37,223.23,199.25,224.66,198.38Z"
      style="fill: rgb(255, 255, 255); transform-origin: 222.549px 197.254px"
      id="el9vxucwa4wei"
      class="animable"></path>
    <path
      d="M216.25,217.26c-1.1-.64-5.94,1.27-3.75,2.43C214.33,220.67,217.68,218.08,216.25,217.26Z"
      style="fill: rgb(255, 255, 255); transform-origin: 214.267px 218.52px"
      id="el2b86njwax84"
      class="animable"></path>
    <path
      d="M248.65,223c-1.46.35-.56,3.68,2.18,3C253.13,225.37,250.55,222.59,248.65,223Z"
      style="fill: rgb(255, 255, 255); transform-origin: 249.846px 224.525px"
      id="elicm8t5h4v8k"
      class="animable"></path>
    <path
      d="M267.47,205.88c0,.92,5.63,1.54,5.52-.22C272.89,204.2,267.48,204.68,267.47,205.88Z"
      style="fill: rgb(255, 255, 255); transform-origin: 270.231px 205.768px"
      id="elsmcxdi3ywwj"
      class="animable"></path>
    <path
      d="M260,201.84c.7.81,6.27-.12,4.84-1.63C263.62,198.93,259.08,200.78,260,201.84Z"
      style="fill: rgb(255, 255, 255); transform-origin: 262.476px 200.957px"
      id="elj78rk7j8ngq"
      class="animable"></path>
    <path
      d="M247.77,214.88c-1.55-.12-3.58,3.08-.61,3.23C249.64,218.24,249.78,215,247.77,214.88Z"
      style="fill: rgb(255, 255, 255); transform-origin: 247.4px 216.495px"
      id="eluibq6btwmdq"
      class="animable"></path>
    <path
      d="M269.1,228s-18.94-12.07-16.47-15.82,34.41-3.27,34.56-.95S278.58,226.05,269.1,228Z"
      style="fill: rgb(29, 149, 243); transform-origin: 269.8px 218.713px"
      id="el1n48ooyla99"
      class="animable"></path>
    <g id="elqp4p74tdev">
      <path
        d="M269.1,228s-18.94-12.07-16.47-15.82,34.41-3.27,34.56-.95S278.58,226.05,269.1,228Z"
        style="opacity: 0.1; transform-origin: 269.8px 218.713px"
        class="animable"></path>
    </g>
    <path
      d="M271.33,192.26s-17.12,13.49-23.79,12.86-11-19.2-7.15-19.78S266.54,187.19,271.33,192.26Z"
      style="fill: rgb(29, 149, 243); transform-origin: 255.137px 195.199px"
      id="el30azfucahdd"
      class="animable"></path>
    <g id="el86h5yjpg5yp">
      <path
        d="M271.33,192.26s-17.12,13.49-23.79,12.86-11-19.2-7.15-19.78S266.54,187.19,271.33,192.26Z"
        style="opacity: 0.1; transform-origin: 255.137px 195.199px"
        class="animable"></path>
    </g>
    <path
      d="M210,193.19s21.45,10.51,19.81,14.4-33.54,5.81-34.19,3.53S201,195.83,210,193.19Z"
      style="fill: rgb(29, 149, 243); transform-origin: 212.736px 202.644px"
      id="elur2q1anntsr"
      class="animable"></path>
    <g id="elcdyzfm8ayam">
      <path
        d="M210,193.19s21.45,10.51,19.81,14.4-33.54,5.81-34.19,3.53S201,195.83,210,193.19Z"
        style="opacity: 0.1; transform-origin: 212.736px 202.644px"
        class="animable"></path>
    </g>
    <path
      d="M210.47,226.68s18.43-12.89,25-12,9,19.58,5.12,20S214.73,231.92,210.47,226.68Z"
      style="fill: rgb(29, 149, 243); transform-origin: 226.459px 224.676px"
      id="el5zmyc23z8ig"
      class="animable"></path>
    <g id="ell8sywzce2">
      <path
        d="M210.47,226.68s18.43-12.89,25-12,9,19.58,5.12,20S214.73,231.92,210.47,226.68Z"
        style="opacity: 0.1; transform-origin: 226.459px 224.676px"
        class="animable"></path>
    </g>
    <path
      d="M186.5,201.49c18.58-7.13,37.54-28.12,37.42-51.07,0-4.94-5-12.59-7.33-15.71-8.48-11.43-26.06-7.65-40.36-2.1-18.18,7-41.3,28.31-40.13,49.9.28,5.26-.38,7.94,2.4,11.83C145.62,204.29,163.08,210.48,186.5,201.49Z"
      style="fill: rgb(29, 149, 243); transform-origin: 179.989px 166.561px"
      id="elxxls44ounqd"
      class="animable"></path>
    <g id="elhawz5nep5st">
      <path
        d="M186.5,201.49c18.58-7.13,37.54-28.12,37.42-51.07,0-4.94-5-12.59-7.33-15.71-8.48-11.43-26.06-7.65-40.36-2.1-18.18,7-41.3,28.31-40.13,49.9.28,5.26-.38,7.94,2.4,11.83C145.62,204.29,163.08,210.48,186.5,201.49Z"
        style="opacity: 0.2; transform-origin: 179.989px 166.561px"
        class="animable"></path>
    </g>
    <path
      d="M182.49,197.15C201.07,190,220,169,219.92,146.08c-.12-23.11-29.51-24.87-47.7-17.81s-41.3,28.31-40.13,49.9C133.1,196.88,149.91,209.66,182.49,197.15Z"
      style="fill: rgb(29, 149, 243); transform-origin: 175.984px 163.374px"
      id="el1gaexkx56sv"
      class="animable"></path>
    <g id="elmvztf71fyy8">
      <path
        d="M182.49,197.15C201.07,190,220,169,219.92,146.08c-.12-23.11-29.51-24.87-47.7-17.81s-41.3,28.31-40.13,49.9C133.1,196.88,149.91,209.66,182.49,197.15Z"
        style="opacity: 0.1; transform-origin: 175.984px 163.374px"
        class="animable"></path>
    </g>
    <path
      d="M181.24,191c15.18-5.82,30.67-23,30.58-41.72s-24.11-20.31-39-14.55-33.74,23.13-32.79,40.77C140.89,190.75,154.62,201.19,181.24,191Z"
      style="fill: rgb(29, 149, 243); transform-origin: 175.908px 163.417px"
      id="el21j3esyaypx"
      class="animable"></path>
    <path
      d="M162.78,177.5c-.58-1-5.68,1.49-4.47,3.23C159.32,182.19,163.53,178.73,162.78,177.5Z"
      style="fill: rgb(255, 255, 255); transform-origin: 160.497px 179.177px"
      id="el6a0zw3r0lrl"
      class="animable"></path>
    <path
      d="M164.69,156.36c1.11-1.09-1.22-4.45-3.27-2.33C159.7,155.81,163.25,157.77,164.69,156.36Z"
      style="fill: rgb(255, 255, 255); transform-origin: 162.968px 155.092px"
      id="elqy37j2zda4"
      class="animable"></path>
    <path
      d="M152.42,162.67c.45-1.29-4.07-2.25-4.81.22C147,165,151.84,164.36,152.42,162.67Z"
      style="fill: rgb(255, 255, 255); transform-origin: 150.004px 162.79px"
      id="elgmgbr71ruxk"
      class="animable"></path>
    <path
      d="M158.54,186.21c-1.34,0-4,4.66-1.4,4.56C159.32,190.7,160.29,186.22,158.54,186.21Z"
      style="fill: rgb(255, 255, 255); transform-origin: 157.743px 188.491px"
      id="elcg8l46sqgdg"
      class="animable"></path>
    <path
      d="M189.14,173.46c-1,1.18,2.08,4,3.82,1.68C194.43,173.22,190.39,171.92,189.14,173.46Z"
      style="fill: rgb(255, 255, 255); transform-origin: 191.111px 174.405px"
      id="elvoweg53r9ye"
      class="animable"></path>
    <path
      d="M192.75,145.68c.63.91,5.68-1.69,4.38-3.36C196,140.91,191.92,144.49,192.75,145.68Z"
      style="fill: rgb(255, 255, 255); transform-origin: 194.991px 143.932px"
      id="elg8p78bbkv8p"
      class="animable"></path>
    <path
      d="M183.81,145.94c1.13.42,5.07-3.69,2.84-4.38C184.79,141,182.34,145.41,183.81,145.94Z"
      style="fill: rgb(255, 255, 255); transform-origin: 185.341px 143.741px"
      id="elumsf8klwh7"
      class="animable"></path>
    <path
      d="M182.78,165.86c-1.35.77-.81,5.11,1.73,3.56C186.64,168.13,184.54,164.87,182.78,165.86Z"
      style="fill: rgb(255, 255, 255); transform-origin: 183.735px 167.714px"
      id="ell3yqpk2mvn"
      class="animable"></path>
    <path
      d="M209.36,166.72s-23.88-1.17-24.44-6.3,26-22.9,27.71-20.68S215.79,159.36,209.36,166.72Z"
      style="fill: rgb(29, 149, 243); transform-origin: 199.499px 153.135px"
      id="elled8mxpaha"
      class="animable"></path>
    <g id="elcq0kre3wrhv">
      <path
        d="M209.36,166.72s-23.88-1.17-24.44-6.3,26-22.9,27.71-20.68S215.79,159.36,209.36,166.72Z"
        style="opacity: 0.1; transform-origin: 199.499px 153.135px"
        class="animable"></path>
    </g>
    <path
      d="M186.51,130s-4.74,23.17-10.65,26.35-22.29-12.78-19.52-15.55S179.08,127.66,186.51,130Z"
      style="fill: rgb(29, 149, 243); transform-origin: 171.269px 143.213px"
      id="el8b7kyje6uc9"
      class="animable"></path>
    <g id="eluxncligbhqs">
      <path
        d="M186.51,130s-4.74,23.17-10.65,26.35-22.29-12.78-19.52-15.55S179.08,127.66,186.51,130Z"
        style="opacity: 0.1; transform-origin: 171.269px 143.213px"
        class="animable"></path>
    </g>
    <path
      d="M136.81,165.88s24.88-1.82,26.21,3-23.53,24.91-25.63,23S131.24,173.64,136.81,165.88Z"
      style="fill: rgb(29, 149, 243); transform-origin: 148.532px 178.775px"
      id="elzh9pfz0snrh"
      class="animable"></path>
    <g id="elsg1tgwnz2y">
      <path
        d="M136.81,165.88s24.88-1.82,26.21,3-23.53,24.91-25.63,23S131.24,173.64,136.81,165.88Z"
        style="opacity: 0.1; transform-origin: 148.532px 178.775px"
        class="animable"></path>
    </g>
    <path
      d="M160.3,198.87s6.24-23.32,12.24-26.21,20.92,14.29,18,17S167.41,201.64,160.3,198.87Z"
      style="fill: rgb(29, 149, 243); transform-origin: 175.607px 185.857px"
      id="eljsr9lqdnuoq"
      class="animable"></path>
    <g id="el4aiqjabew7k">
      <path
        d="M160.3,198.87s6.24-23.32,12.24-26.21,20.92,14.29,18,17S167.41,201.64,160.3,198.87Z"
        style="opacity: 0.1; transform-origin: 175.607px 185.857px"
        class="animable"></path>
    </g>
    <path
      d="M324.78,229.36c-18.14-2.77-40-16.08-45.37-33.67-1.17-3.79,1.43-10.37,2.72-13.09,4.76-10,21.22-9.63,35.2-7.46,17.78,2.77,43.34,15.69,47.5,32.39,1,4.07,2.23,6,.71,9.4C361.63,225.58,347.67,232.85,324.78,229.36Z"
      style="fill: rgb(29, 149, 243); transform-origin: 322.684px 202.071px"
      id="elbmwovp8atr4"
      class="animable"></path>
    <g id="el0yvm8ods5ai">
      <path
        d="M324.78,229.36c-18.14-2.77-40-16.08-45.37-33.67-1.17-3.79,1.43-10.37,2.72-13.09,4.76-10,21.22-9.63,35.2-7.46,17.78,2.77,43.34,15.69,47.5,32.39,1,4.07,2.23,6,.71,9.4C361.63,225.58,347.67,232.85,324.78,229.36Z"
        style="opacity: 0.2; transform-origin: 322.684px 202.071px"
        class="animable"></path>
    </g>
    <path
      d="M327.29,225.46c-18.15-2.77-40-16.09-45.38-33.67s20.14-23.32,37.92-20.55,43.34,15.69,47.5,32.38C370.93,218.09,359.13,230.31,327.29,225.46Z"
      style="fill: rgb(29, 149, 243); transform-origin: 324.567px 198.559px"
      id="elbvwg9y61u0m"
      class="animable"></path>
    <g id="elzk2cfzkfhn">
      <path
        d="M327.29,225.46c-18.15-2.77-40-16.09-45.38-33.67s20.14-23.32,37.92-20.55,43.34,15.69,47.5,32.38C370.93,218.09,359.13,230.31,327.29,225.46Z"
        style="opacity: 0.1; transform-origin: 324.567px 198.559px"
        class="animable"></path>
    </g>
    <path
      d="M326.91,220.54c-14.83-2.26-32.65-13.14-37.07-27.51s16.45-19,31-16.78,35.41,12.81,38.8,26.45C362.56,214.52,352.92,224.51,326.91,220.54Z"
      style="fill: rgb(29, 149, 243); transform-origin: 324.69px 198.574px"
      id="el1e4f6x8x3dm"
      class="animable"></path>
    <path
      d="M340,207.55c.29-.81,5.39.32,4.74,1.83C344.2,210.65,339.64,208.61,340,207.55Z"
      style="fill: rgb(255, 255, 255); transform-origin: 342.388px 208.532px"
      id="el77arza0kgtk"
      class="animable"></path>
    <path
      d="M333.24,191.65c-1.24-.67,0-3.58,2.34-2.25C337.53,190.51,334.86,192.52,333.24,191.65Z"
      style="fill: rgb(255, 255, 255); transform-origin: 334.508px 190.459px"
      id="eleu5dn9v0pyl"
      class="animable"></path>
    <path
      d="M345.61,194.71c-.7-.93,3.07-2.32,4.32-.54C351,195.66,346.53,195.91,345.61,194.71Z"
      style="fill: rgb(255, 255, 255); transform-origin: 347.809px 194.39px"
      id="eltkwy4gdljz"
      class="animable"></path>
    <path
      d="M345.85,213.61c1.19-.2,4.66,3,2.34,3.29C346.24,217.16,344.31,213.87,345.85,213.61Z"
      style="fill: rgb(255, 255, 255); transform-origin: 347.132px 215.258px"
      id="el8chnqhsig8p"
      class="animable"></path>
    <path
      d="M315.72,208.28c1.13.77-.88,3.35-3,1.85C311,208.87,314.25,207.29,315.72,208.28Z"
      style="fill: rgb(255, 255, 255); transform-origin: 314.138px 209.28px"
      id="elmlz2o0dtxs"
      class="animable"></path>
    <path
      d="M305.86,187.54c-.34.79-5.44-.47-4.69-1.94C301.8,184.37,306.3,186.51,305.86,187.54Z"
      style="fill: rgb(255, 255, 255); transform-origin: 303.492px 186.506px"
      id="elytl739mqyb"
      class="animable"></path>
    <path
      d="M313.83,186.45c-.9.48-5.37-2.1-3.57-2.95C311.77,182.79,315,185.82,313.83,186.45Z"
      style="fill: rgb(255, 255, 255); transform-origin: 311.959px 184.951px"
      id="ely1i5vtj9b5"
      class="animable"></path>
    <path
      d="M319.52,201.55c1.38.39,2,3.79-.67,3A1.52,1.52,0,1,1,319.52,201.55Z"
      style="fill: rgb(255, 255, 255); transform-origin: 319.176px 203.091px"
      id="elqv6faq4kpsk"
      class="animable"></path>
    <path
      d="M296.21,206.05s20.85-4.35,20.11-8.36-28.49-13.76-29.48-11.81S288.75,201.36,296.21,206.05Z"
      style="fill: rgb(29, 149, 243); transform-origin: 301.496px 195.838px"
      id="elagwtqy41rd"
      class="animable"></path>
    <g id="elt63tm5jxwm">
      <path
        d="M296.21,206.05s20.85-4.35,20.11-8.36-28.49-13.76-29.48-11.81S288.75,201.36,296.21,206.05Z"
        style="opacity: 0.1; transform-origin: 301.496px 195.838px"
        class="animable"></path>
    </g>
    <path
      d="M307.6,174.6s9.76,17.05,15.75,18.62,16.65-13,13.54-14.73S313.62,171.76,307.6,174.6Z"
      style="fill: rgb(29, 149, 243); transform-origin: 322.523px 183.542px"
      id="el3g6oncxl3os"
      class="animable"></path>
    <g id="eltrt7i0mksg">
      <path
        d="M307.6,174.6s9.76,17.05,15.75,18.62,16.65-13,13.54-14.73S313.62,171.76,307.6,174.6Z"
        style="opacity: 0.1; transform-origin: 322.523px 183.542px"
        class="animable"></path>
    </g>
    <path
      d="M360.2,194.9s-22.45,2.21-22.48,6.08,26.81,15.67,28.21,13.91S367,200,360.2,194.9Z"
      style="fill: rgb(29, 149, 243); transform-origin: 352.158px 204.984px"
      id="elre85lpovphd"
      class="animable"></path>
    <g id="el8c3eaf4m5kq">
      <path
        d="M360.2,194.9s-22.45,2.21-22.48,6.08,26.81,15.67,28.21,13.91S367,200,360.2,194.9Z"
        style="opacity: 0.1; transform-origin: 352.158px 204.984px"
        class="animable"></path>
    </g>
    <path
      d="M347.34,223.56s-11.12-16.95-17.13-18.29-15.08,14-11.87,15.59S341.71,226.71,347.34,223.56Z"
      style="fill: rgb(29, 149, 243); transform-origin: 332.506px 214.939px"
      id="elgf2cvybzem4"
      class="animable"></path>
    <g id="elpzcomh0siq8">
      <path
        d="M347.34,223.56s-11.12-16.95-17.13-18.29-15.08,14-11.87,15.59S341.71,226.71,347.34,223.56Z"
        style="opacity: 0.1; transform-origin: 332.506px 214.939px"
        class="animable"></path>
    </g>
  </g>
  <g
    id="freepik--bread-1--inject-145"
    class="animable"
    style="transform-origin: 263.426px 122.746px">
    <path
      d="M383.47,193.41c-27.3,26.41-115.44-.13-141.75-9.5s-110.66-44.25-109.67-80,81.79-84.59,170.22-53.09S410.77,167,383.47,193.41Z"
      style="fill: rgb(29, 149, 243); transform-origin: 263.426px 122.746px"
      id="elf25c0510qr4"
      class="animable"></path>
    <g id="elnrgojukeenk">
      <path
        d="M383.47,193.41c-27.3,26.41-115.44-.13-141.75-9.5s-110.66-44.25-109.67-80,81.79-84.59,170.22-53.09S410.77,167,383.47,193.41Z"
        style="opacity: 0.2; transform-origin: 263.426px 122.746px"
        class="animable"></path>
    </g>
    <g id="elp6p4u1scuz9">
      <path
        d="M308.06,91c-10.58,13.82-38.87,15.69-64.13,12-12.66-1.85-50.85-14.63-52.83-30.91s28.57-36.25,70.78-28S318.64,77.16,308.06,91Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.1;
          transform-origin: 251.193px 73.4094px;
        "
        class="animable"></path>
    </g>
    <g id="el4570vyqqvxh">
      <path
        d="M383.47,193.41c-27.3,26.41-115.44-.13-141.74-9.5s-110.67-44.25-109.68-80c.4-14.56,14-31.27,36-43.86-14.38,17.7-27.51,37.38-18.32,53.13,15.91,27.25,137,63.43,194.26,61.23,46.69-1.79,47.62-29.92,41.47-52.17C399,151.24,397.4,179.93,383.47,193.41Z"
        style="opacity: 0.1; transform-origin: 263.426px 132.424px"
        class="animable"></path>
    </g>
    <path
      d="M273.67,134.27c-1.65-.48-6.87,5.69-3.65,6.48C272.71,141.4,275.8,134.9,273.67,134.27Z"
      style="fill: rgb(255, 255, 255); transform-origin: 271.686px 137.52px"
      id="eljg50ka39ax8"
      class="animable"></path>
    <path
      d="M333.47,76.56c.82,1.5,8.88.82,7.16-2C339.18,72.19,332.41,74.6,333.47,76.56Z"
      style="fill: rgb(255, 255, 255); transform-origin: 337.114px 75.456px"
      id="elinm73ro6iri"
      class="animable"></path>
    <path
      d="M318,135.51c-1.56.71-1.43,8.8,1.51,7.28C322,141.51,320,134.58,318,135.51Z"
      style="fill: rgb(255, 255, 255); transform-origin: 318.84px 139.202px"
      id="el1xkghqcmcbi"
      class="animable"></path>
    <path
      d="M183.06,59.71c-.44-1.66-8.43-2.91-7.43.25C176.47,62.6,183.63,61.86,183.06,59.71Z"
      style="fill: rgb(255, 255, 255); transform-origin: 179.318px 59.8294px"
      id="elxeprbl9q9a"
      class="animable"></path>
    <path
      d="M233.14,103.77c-.87-1.48-8.9-.51-7.08,2.25C227.59,108.34,234.28,105.69,233.14,103.77Z"
      style="fill: rgb(255, 255, 255); transform-origin: 229.531px 105.003px"
      id="el0qaz127dlk4"
      class="animable"></path>
    <path
      d="M350.94,107c-1.12,1.3,2.35,8.6,4.39,6C357,110.85,352.4,105.36,350.94,107Z"
      style="fill: rgb(255, 255, 255); transform-origin: 353.206px 110.127px"
      id="elj496xgg1a7o"
      class="animable"></path>
    <path
      d="M339.19,120.89c-1.71.17-4.2,7.87-.92,7.37C341,127.85,341.4,120.66,339.19,120.89Z"
      style="fill: rgb(255, 255, 255); transform-origin: 338.64px 124.584px"
      id="ela3jr5vuguze"
      class="animable"></path>
    <path
      d="M316.69,78.17c-1.24,1.19,1.55,8.78,3.82,6.37C322.41,82.52,318.29,76.63,316.69,78.17Z"
      style="fill: rgb(255, 255, 255); transform-origin: 318.693px 81.463px"
      id="elguq40emtzyk"
      class="animable"></path>
    <path
      d="M178.35,52.35c-.63-1.59-8.71-1.89-7.35,1.12C172.15,56,179.17,54.42,178.35,52.35Z"
      style="fill: rgb(255, 255, 255); transform-origin: 174.631px 52.9724px"
      id="elzgm7mkffagm"
      class="animable"></path>
    <path
      d="M354.94,157.7c-1.69.26-3.79,8.07-.55,7.41C357.11,164.56,357.14,157.37,354.94,157.7Z"
      style="fill: rgb(255, 255, 255); transform-origin: 354.605px 161.419px"
      id="elwcux6yh8umd"
      class="animable"></path>
    <path
      d="M384.62,133.64c-1.21,1.21,1.74,8.74,4,6.29C390.44,137.87,386.19,132.06,384.62,133.64Z"
      style="fill: rgb(255, 255, 255); transform-origin: 386.702px 136.894px"
      id="elln5dt366w9e"
      class="animable"></path>
    <path
      d="M192.08,122.14c-1.4-1-8.32,3.19-5.52,5C188.9,128.58,193.9,123.42,192.08,122.14Z"
      style="fill: rgb(255, 255, 255); transform-origin: 189.185px 124.69px"
      id="eltihztln2ro"
      class="animable"></path>
    <path
      d="M239.6,56.29c.33-1.68-6.28-6.33-6.78-3.06C232.41,56,239.16,58.47,239.6,56.29Z"
      style="fill: rgb(255, 255, 255); transform-origin: 236.207px 54.6084px"
      id="elts27dtr2gk"
      class="animable"></path>
    <path
      d="M276.65,62.83c-1-1.35-8.89.63-6.73,3.14C271.73,68.07,278,64.59,276.65,62.83Z"
      style="fill: rgb(255, 255, 255); transform-origin: 273.193px 64.5081px"
      id="ela3t18obx96o"
      class="animable"></path>
    <path
      d="M294.34,158.69c-1.68.36-3.28,8.29-.08,7.43C296.93,165.39,296.51,158.21,294.34,158.69Z"
      style="fill: rgb(255, 255, 255); transform-origin: 294.268px 162.426px"
      id="elnex0jzsvmu"
      class="animable"></path>
    <path
      d="M187.51,81c-1-1.41-8.92.1-6.91,2.73C182.28,85.94,188.77,82.84,187.51,81Z"
      style="fill: rgb(255, 255, 255); transform-origin: 183.973px 82.4777px"
      id="el8xincgkw9jg"
      class="animable"></path>
    <path
      d="M283.84,168.58c-1.71-.09-5.35,7.14-2,7.15C284.58,175.73,286.06,168.69,283.84,168.58Z"
      style="fill: rgb(255, 255, 255); transform-origin: 282.694px 172.155px"
      id="el5k7s7kxggch"
      class="animable"></path>
    <g id="elfsz6z03boy5">
      <path
        d="M153.25,90c-4.2,14.81,10.12,26.3,20.92,33.78,15.45,10.12,32.63,17.15,49.71,24.09-17.75-5.32-35.29-12.15-50.8-22.47-11-7.61-25.56-20.57-19.83-35.4Z"
        style="
          fill: rgb(255, 255, 255);
          opacity: 0.5;
          transform-origin: 187.92px 118.935px;
        "
        class="animable"></path>
    </g>
  </g>
  <defs>
    <filter id="active" height="200%">
      <feMorphology
        in="SourceAlpha"
        result="DILATED"
        operator="dilate"
        radius="2"></feMorphology>
      <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
      <feComposite
        in="PINK"
        in2="DILATED"
        operator="in"
        result="OUTLINE"></feComposite>
      <feMerge>
        <feMergeNode in="OUTLINE"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
    <filter id="hover" height="200%">
      <feMorphology
        in="SourceAlpha"
        result="DILATED"
        operator="dilate"
        radius="2"></feMorphology>
      <feFlood
        flood-color="#ff0000"
        flood-opacity="0.5"
        result="PINK"></feFlood>
      <feComposite
        in="PINK"
        in2="DILATED"
        operator="in"
        result="OUTLINE"></feComposite>
      <feMerge>
        <feMergeNode in="OUTLINE"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
      <feColorMatrix
        type="matrix"
        values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
    </filter>
  </defs>
</svg>
