import { createAction, props } from '@ngrx/store'
import {
  CheckoutForm,
  Customer
} from 'src/app/checkout/interface/checkout.model'

export const ResetCheckout = createAction('[Checkout] Reset')

export const UpdateCheckout = createAction(
  '[Checkout] Update',
  props<{ payload: CheckoutForm }>()
)

export const UpdateCheckoutCustomer = createAction(
  '[Checkout Customer] Update With Prop',
  props<{ customer: Customer | any; withProp: string }>()
)

export const UpdateBulkCheckoutCustomer = createAction(
  '[Checkout Customer] Bulk Update ',
  props<{ customer: Customer }>()
)
