import { createReducer, on } from '@ngrx/store'
import { countOfCartUpdate } from './count-of-cart.actions'

export interface CounterState {
  count: number
}

export const initialState: CounterState = {
  count: 0
}

export const countOfCartReducer = createReducer(
  initialState,
  on(countOfCartUpdate, (state, counterOfCart) => ({
    ...state,
    ...counterOfCart
  }))
)
