import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-il-burger',
  templateUrl: './il-burger.component.html',
  standalone: true
})
export class IlBurgerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
