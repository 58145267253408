<div
  class="dropdown"
  [class.dropdown-show]="isShow"
  [class.dropdown-hide]="!isShow">
  <ng-container *ngFor="let lang of languages">
    <div
      class="dropdown-item"
      (click)="setLanguage(lang.code)"
      [class.active]="currentLanguage === lang.code">
      {{ lang.text }}
    </div>
  </ng-container>
</div>
