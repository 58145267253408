import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ToastService } from '../../data-access/toast/toast.service'

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  eventServiceSubscription?: Subscription
  message: string = ''
  showToast = false
  currentTimeout?: NodeJS.Timeout

  constructor(private eventService: ToastService) {}

  runToast(): void {
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout)
    }
    this.showToast = true
    this.currentTimeout = setTimeout(() => {
      this.showToast = false
    }, 2000)
  }

  ngOnInit() {
    this.eventServiceSubscription = this.eventService.subscribe(
      'SHOW_TOAST',
      (payload: any) => {
        this.message = payload
        this.runToast()
      }
    )
  }

  ngOnDestroy(): void {
    if (this.eventServiceSubscription) {
      this.eventServiceSubscription.unsubscribe()
    }
  }
}
