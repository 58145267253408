import { createReducer, on } from '@ngrx/store'
import { Address } from '@profile/interface/profile.model'
import * as AddressActions from './address-info.actions'

const initialState: Address = {
  name: '',
  address: '',
  addressNote: '',
  primary: false
}

const newState = (state: Address, newData: Address) => {
  return Object.assign({}, state, newData)
}

export const addressInfoReducer = createReducer(
  initialState,
  on(AddressActions.UpdateAddress, (state, { payload }) =>
    newState(state, payload)
  ),
  on(AddressActions.ResetAddress, state => initialState)
)
