import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ToastService } from '../../data-access/toast/toast.service'
import { ToastComponent } from './toast.component'

@NgModule({
  declarations: [ToastComponent],
  exports: [ToastComponent],
  imports: [CommonModule, TranslateModule],
  providers: [ToastService]
})
export class ToastModule {}
