import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule, TransferState } from '@angular/platform-browser'

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastService } from '@data-access/toast/toast.service'
import { InitialLanguageLoader } from '@data-access/translate/initial-language-loader.service'
import { TranslateBrowserLoader } from '@data-access/translate/translate-browser-loader.service'
import { ViewService } from '@data-access/view/view.service'
import { AuthNotVerifyGuard } from '@guard/auth-not-verify.guard'
import { AuthVerifyGuard } from '@guard/auth-verify.guard'
import { ChooseOrderServiceGuard } from '@guard/choose-order-service.guard'
import { AuthInterceptor } from '@interceptor/auth.interceptor'
import { ErrorResponseInterceptor } from '@interceptor/error-handler.interceptor'
import { makeId } from '@models/common'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core'
import { addressInfoReducer } from '@state-management/address/address-info.reducer'
import { checkoutInfoReducer } from '@state-management/checkout/checkout-info.reducer'
import { countOfCartReducer } from '@state-management/count-of-cart/count-of-cart.reducer'
import { customMenuSelectReducer } from '@state-management/custom-menu/custom-menu-select.reducer'
import { qrInfoReducer } from '@state-management/qr/qr-info.reducer'
import { storeInfoReducer } from '@state-management/store/store-info.reducer'
import { IlBurgerComponent } from '@ui-svg/il-burger/il-burger.component'
import { ContactWaitersComponent } from '@ui/contact-waiters/contact-waiters.component'
import { LanguageModalComponent } from '@ui/language-modal/language-modal.component'
import { StoreClosedComponent } from '@ui/store-closed/store-closed.component'
import { StoreHolidayComponent } from '@ui/store-holiday/store-holiday.component'
import { ToastModule } from '@ui/toast/toast.module'
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image'
import { NgxSpinnerModule } from 'ngx-spinner'
import { environment } from 'src/environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LogService } from './shared/log/log.service';

export function exportTranslateStaticLoader(
  http: HttpClient,
  transferState: TransferState
) {
  return new TranslateBrowserLoader(
    '/assets/i18n/',
    `.json?${makeId(5)}`,
    transferState,
    http
  )
}

export function appInitializerFactory(
  translate: TranslateService,
  viewService: ViewService
) {
  return new InitialLanguageLoader(translate, viewService)
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastModule,
    HttpClientModule,
    NgxSpinnerModule.forRoot(),
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    StoreModule.forRoot({
      countOfCart: countOfCartReducer,
      storeInfo: storeInfoReducer,
      qrInfo: qrInfoReducer,
      customMenuSelect: customMenuSelectReducer,
      checkoutInfo: checkoutInfoReducer,
      addressInfo: addressInfoReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.local // Restrict extension to log-only mode
    }),
    StoreHolidayComponent,
    StoreClosedComponent,
    IlBurgerComponent,
    LanguageModalComponent,
    ContactWaitersComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, ViewService],
      multi: true
    },
    ToastService,
    AuthVerifyGuard,
    AuthNotVerifyGuard,
    ChooseOrderServiceGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: LogService
    }
  ]
})
export class AppModule {}
