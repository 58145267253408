import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class EventService {
  showModalLanguage: Subject<boolean> = new Subject()
  public mergedEvent: Subject<{isMerged: boolean, openBillData: any}> = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  scrollingEvent(type: string) {
    let htmlBodyEl = this.document.querySelector('html body') as HTMLElement
    if (type === 'scroll') htmlBodyEl.style.overflow = 'auto'
    if (type === 'not-scroll') htmlBodyEl.style.overflow = 'hidden'
  }

  mergedEventHandler(isMerged: boolean, openBillData: any) {
    this.mergedEvent.next({
      isMerged,
      openBillData
    });
  }
}
