import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { TimeZone } from '@models/utility.model'
import { Observable, catchError, of, tap } from 'rxjs'
import { Response } from '../../models/response'

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string
  ) {}

  getPhoneCountries(): Observable<Response<PhoneCountries[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http
      .get<Response<PhoneCountries[]>>(`/api/utilities/country`, {
        headers: headers
      })
      .pipe(
        tap(response => {
          return response.data.filter(phoneCountry => {
            if (
              phoneCountry.code !== 'ID' &&
              phoneCountry.code !== 'SG' &&
              phoneCountry.code !== 'KH'
            ) {
              phoneCountry
            }
          })
        }),
        catchError(this.handleError<Response<PhoneCountries[]>>('getCountries'))
      )
  }

  /**
   *
   * @returns
   */
  getTimeZoneList(): Observable<Response<TimeZone[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.get<Response<TimeZone[]>>('/api/utilities/timezone', {
      headers
    })
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }

  log(arg0: string) {
    console.log(arg0)
  }
}

interface PhoneCountries {
  code: string
  name: string
  phone: string
}
