import { createReducer, on } from '@ngrx/store'
import { CustomMenu } from '../../models/custom-menu.model'
import { customMenuUpdate } from './custom-menu-select.actions'

export const initialState: CustomMenu | null = Object.create(null)

export const customMenuSelectReducer = createReducer(
  initialState,
  on(customMenuUpdate, (state, customMenu) => ({
    ...state,
    ...customMenu
  }))
)
