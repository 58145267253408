import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StoreInfo } from '@models/home.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService extends ErrorHandler {
  private logEndpoint = `/private/idefood-log` // Update with your server URL

  storeInfo!: StoreInfo

  constructor(
    private http: HttpClient, 
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    super();

    if (isPlatformBrowser(this.platformId)) {
      this.storeInfo = JSON.parse(localStorage.getItem('storeInfo') ?? '{}') ?? {};
    }
  }

  /**
   * Log Info
   * @param data
   * Required: 
   * userAgent, name, store, storeSlug, qr, qrType, deviceId, customerDeviceId, serviceType
   */
  log(data: LogPayload): void {
    if (environment.showLog) console.log(`[INFO] ${JSON.stringify(data)}`)
    if (environment.recordLog) this.saveLogToServer(data)
  }

  trace(data: LogPayload): void {
    if (environment.showLog) console.log(`[TRACE] ${JSON.stringify(data)}`)
    if (environment.recordLog) this.saveLogToServer(data)
  }

  /**
   * Log Error
   * @param data
   */
  error(data: LogPayload): void {
    if (environment.showLog) console.error(`[ERROR] ${JSON.stringify(data)}`)
    if (environment.recordLog) this.saveLogToServer(data, true)
  }

  /**
   *
   * @param data
   * @param isError
   */
  private saveLogToServer(data: LogPayload, isError = false): void {
    if (isError) data.type = 'ERROR'

    this.http.post(this.logEndpoint, data).subscribe({
      next: response => console.log('Log saved successfully:', response),
      error: error => console.error('Error saving log:', error)
    })
  }

  override handleError(error: Error) {
    this.error({
      type: "ERROR",
      merchantId: this.storeInfo?.merchantId,
      storeId: this.storeInfo?.storeId,
      qr: this.storeInfo?.qr,
      qrType: this.storeInfo?.qrType ?? '',
      body: JSON.stringify(error.stack),
      dateTime: `${new Date()}`
    })
  }
}

interface LogPayload {
  type: string,
  body: string,
  qr: string,
  qrType: string,
  dateTime: string,
  merchantId: number,
  storeId : number
}