import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})
export class error404Component implements OnInit {
  constructor(private _title: Title) {
    this._title.setTitle('404 | IDEFOOD')
  }

  environment = environment
  ngOnInit() {}
}
