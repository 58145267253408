import { Injectable, OnDestroy } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { ViewService } from '@data-access/view/view.service'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { ToastService } from '../data-access/toast/toast.service'
import { StoreInfo } from '../models/home.model'
import { selectStoreInfo } from '../state-management/store/store-info.selector'

@Injectable()
export class ChooseOrderServiceGuard implements CanActivate, OnDestroy {
  storeInfo$: Observable<StoreInfo> = this._store.select(selectStoreInfo)
  storeInfo$Subs: Subscription = new Subscription()

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _toastService: ToastService,
    private _store: Store,
    private _viewService: ViewService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable(observer => {
      this.storeInfo$Subs = this.storeInfo$.subscribe(storeInfo => {
        if (storeInfo?.storeSlug) {
          const orderService = this._viewService.getServiceType(
            storeInfo?.storeSlug
          )
          let configService = [...storeInfo?.configService]
          // const indexOfTakeout = configService.indexOf('TAKEOUT');
          // if (indexOfTakeout !== -1) {
          //   // CHANGE TAKEOUT to PICK_UP
          //   configService[indexOfTakeout] = 'PICK_UP';
          // }

          // If order service in storage available in configService
          let orderServiceAvailable = configService.includes(orderService)
          if (orderServiceAvailable) {
            observer.next(true)
          }

          // If order service not available in storage and check configService length more than 1
          // then redirect to choose order service
          if (!orderServiceAvailable && configService?.length > 1) {
            if (route.queryParams['qr']) {
              this.router.navigate([`order-service`], {
                queryParams: route.queryParams
              })
            } else {
              this.router.navigate([`${storeInfo?.storeSlug}/order-service`])
            }
            this._toastService.broadcast(
              'SHOW_TOAST',
              'SELECT_ORDER_SERVICE_FIRST'
            )
            observer.next(true)
          }

          // If Order Service not available in storage and configService length equals is 1
          // and then set storage and redirect to home
          if (!orderServiceAvailable && configService.length === 1) {
            this._viewService.setServiceType(
              configService[0],
              storeInfo?.storeSlug
            )

            if (route.queryParams['qr']) {
              this.router.navigate([`home`], {
                queryParams: route.queryParams
              })
            } else {
              this.router.navigate([`${storeInfo?.storeSlug}/home`])
            }
            observer.next(true)
          }

          observer.next(true)
        }
      })

      observer.next(true)
    })
  }

  ngOnDestroy(): void {
    this.storeInfo$Subs?.unsubscribe()
  }
}
