<ng-container *ngIf="isShowModal">
  <div class="modal-overlay"></div>
  <div class="modal">
    <div class="ill-delivery-info">
      <app-il-store-holiday></app-il-store-holiday>
    </div>

    <h3>{{ 'STORE_HOLIDAY' | translate }}</h3>
    <p>
      {{ 'REOPEN_ON' | translate }}
      {{ storeInfo?.shopHolidayOpenAt | date: 'fullDate' }}
    </p>
  </div>
</ng-container>
