import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { EventService } from '@data-access/event/event.service'
import { ToastService } from '@data-access/toast/toast.service'
import { ViewService } from '@data-access/view/view.service'
import { StoreInfo } from '@models/home.model'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class LanguageModalComponent implements OnChanges {
  @Input() isShow: boolean = false
  @Input() storeInfo!: StoreInfo

  currentLanguage: string = ''

  languages = [
    {
      code: 'en-US',
      country: 'SG',
      text: 'English'
    },
    {
      code: 'zh-CN',
      country: 'CN',
      text: 'Simplified Chinese'
    },
    {
      code: 'zh-TW',
      country: 'TW',
      text: 'Traditional Chinese'
    },
    {
      code: 'id-ID',
      country: 'ID',
      text: 'Indonesia'
    },
    {
      code: 'km-KH',
      country: 'KH',
      text: 'Khmer'
    }
  ]

  constructor(
    private toastService: ToastService,
    private _viewService: ViewService,
    private _translateService: TranslateService,
    private _eventService: EventService
  ) {
    this.currentLanguage =
      (this._viewService.get('langCode') as string) ?? 'en-US'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isShow']) {
      this.isShow = changes['isShow'].currentValue
    }

    if (changes['storeInfo']) {
      this._reOrderPositionLanguage(changes['storeInfo'].currentValue)
    }
  }

  setLanguage(langCode: string) {
    this._viewService.set('langCode', langCode)
    this._translateService.use(langCode)
    this.isShow = false
    this.currentLanguage = langCode
    this._eventService.showModalLanguage.next(this.isShow)
    this.toastService.broadcast('SHOW_TOAST', 'LANGUAGE_CHANGED')
  }

  _reOrderPositionLanguage(storeInfo: StoreInfo) {
    const storageLangCode = this._viewService.get('langCode')

    // If Singapore Remove language Khmer
    if (storeInfo?.country === 'SG') {
      this.languages = this.languages.filter(
        language => language.code !== 'km-KH'
      )
    }

    // if (storeInfo?.country) {
    //   this.languages.sort((a, b) =>
    //     a.country === storeInfo?.country
    //       ? -1
    //       : b.country === storeInfo?.country
    //       ? 1
    //       : 0
    //   )
    // }

    if (storageLangCode) {
      const isExist = this.languages.find(
        language => language.code === storageLangCode
      )

      if (!isExist) this.setLanguage('en-US')
    }
  }
}
