<ng-container *ngIf="isShowModal">
  <div class="modal-overlay"></div>
  <div class="modal">
    <div class="ill-delivery-info">
      <app-il-store-closed></app-il-store-closed>
    </div>

    <h3>{{ 'NOT_OPEN_YET' | translate }}</h3>
    <p *ngIf="storeInfo?.operatingTimingSelected?.twentyFourHour">
      {{ 'OPEN' | translate }} 24 Hours
    </p>

    <div *ngIf="!storeInfo?.operatingTimingSelected?.twentyFourHour && (storeInfo?.operatingTimingSelected?.operationHoursModelList?.length ?? 0) > 0">
      <p *ngFor="let operationItem of storeInfo?.operatingTimingSelected?.operationHoursModelList">
        {{ 'OPEN' | translate }}
        {{ timeFormatter(operationItem.timeStartAt) | date:'h:mm a' }} -
        {{ timeFormatter(operationItem.timeEndAt) | date:'h:mm a'  }}
      </p>
    </div>
  </div>
</ng-container>
