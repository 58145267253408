import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ButtonComponent } from '@ui/button/button.component'
import { StoreInfo } from '../../models/home.model'
import { IlStoreNotOpenYetComponent } from '../../ui-svg/il-store-closed/il-store-closed.component'

@Component({
  selector: 'app-store-closed',
  templateUrl: './store-closed.component.html',
  styleUrls: ['./store-closed.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IlStoreNotOpenYetComponent,
    TranslateModule
  ]
})
export class StoreClosedComponent {
  @Input() isShowModal: boolean = false
  @Input() storeInfo?: StoreInfo

  timeFormatter(time: string): number {
    const timeArray = time.split(':') as string[]
    const hour = Number(timeArray[0]) as number
    const minute = Number(timeArray[1]) as number

    return new Date().setHours(hour, minute, 0, 0)
  }
}
