import { CountryListUtil, RoundingNear } from '../models/common'

export const COUNTRY_INDONESIA: string = 'INDONESIA'
export const COUNTRY_SINGAPORE: string = 'SINGAPORE'
export const COUNTRY_CAMBODIA: string = 'CAMBODIA'

export const CountryList: CountryListUtil[] = [
  {
    name: COUNTRY_INDONESIA,
    countryId: 'ID',
    currencyId: 'IDR',
    langId: 'id_ID',
    currencyLabel: 'IDR (Rp)',
    currencySymbol: 'IDR',
    locale: 'id-ID'
  },
  {
    name: COUNTRY_SINGAPORE,
    countryId: 'SG',
    currencyId: 'SGD',
    langId: 'en_US',
    currencyLabel: 'SGD ($)',
    currencySymbol: 'SGD',
    locale: 'en-SG'
  },
  {
    name: COUNTRY_CAMBODIA,
    countryId: 'KH',
    currencyId: 'KHR',
    langId: 'en_US',
    currencyLabel: 'KHR (៛)',
    currencySymbol: 'KHR',
    locale: 'km-KH'
  }
]

export enum OrderStatus {
  CREATED = 1,
  UNPAID = 2,
  WAITING_SALES_CONFIRM = 3,
  ON_PROCESS = 4,
  WAITING_COURIER = 5,
  ON_SHIPPING = 6,
  ARRIVED = 7,
  READY_TO_PICKUP = 8,
  PICKED_UP = 9,
  REFUND = 10,
  VOID = 11,
  MERGED = 12,
  PAID = 40,
  COMPLETE = 90,
  CANCELED_EXPIRED_PAYMENT = 98,
  CANCELED = 99,
  PENDING = 50,
  READY_TO_SHIP = 51,
  SHIPPED = 52,
  DELIVERED = 53,
  PACKED = 54,
  SHIPPED_BACK_SUCCESS = 55,
}

export const STATUS_ORDER_LIST = [
  { status: OrderStatus.CREATED, info: 'CREATED' },
  { status: OrderStatus.UNPAID, info: 'UNPAID' },
  { status: OrderStatus.WAITING_SALES_CONFIRM, info: 'WAITING_SALES_CONFIRM' },
  { status: OrderStatus.ON_PROCESS, info: 'ON_PROCESS' },
  { status: OrderStatus.WAITING_COURIER, info: 'WAITING_COURIER' },
  { status: OrderStatus.ON_SHIPPING, info: 'ON_SHIPPING' },
  { status: OrderStatus.ARRIVED, info: 'ARRIVED' },
  { status: OrderStatus.READY_TO_PICKUP, info: 'READY_TO_PICKUP' },
  { status: OrderStatus.PICKED_UP, info: 'PICKED_UP' },
  { status: OrderStatus.REFUND, info: 'REFUND' },
  { status: OrderStatus.VOID, info: 'VOID' },
  { status: OrderStatus.MERGED, info: 'MERGED' },
  { status: OrderStatus.PAID, info: 'PAID' },
  { status: OrderStatus.COMPLETE, info: 'COMPLETE' },
  { status: OrderStatus.CANCELED_EXPIRED_PAYMENT, info: 'CANCELED_EXPIRED_PAYMENT' },
  { status: OrderStatus.CANCELED, info: 'CANCELED' },
  { status: OrderStatus.PENDING, info: 'PENDING' },
  { status: OrderStatus.READY_TO_SHIP, info: 'READY_TO_SHIP' },
  { status: OrderStatus.SHIPPED, info: 'SHIPPED' },
  { status: OrderStatus.DELIVERED, info: 'DELIVERED' },
  { status: OrderStatus.PACKED, info: 'PACKED' },
  { status: OrderStatus.SHIPPED_BACK_SUCCESS, info: 'SHIPPED_BACK_SUCCESS' },
];


export const RoundingNearestList: RoundingNear[] = [
  { code: '0.1', name: '.00', divider: 0.1 },
  { code: '1.0', name: '.0', divider: 1 },
  { code: '10.0', name: '0', divider: 10 },
  { code: '100.0', name: '00', divider: 100 },
  { code: '1000.0', name: '000', divider: 1000 }
]

export const ROUNDING_MATH: string = 'MATH'
export const ROUNDING_UP: string = 'ROUNDING_UP'
export const ROUNDING_DOWN: string = 'ROUNDING_DOWN'

export const WEEKDAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const MicroSentryData = {
  AddToCart: {
    message: 'Add to Cart',
    tag: 'add_to_cart'
  }, 
  UpdateToCart: {
    message: 'Update to Cart',
    tag: 'update_to_cart'
  }, 
  DeleteCart: {
    message: 'Delete Cart',
    tag: 'delete_cart'
  }, 
  OrderNow: {
    message: 'Order Now',
    tag: 'order_now'
  },
  OrderDetail: {
    message: 'Order Detail',
    tag: 'order_detail'
  },
  PaymentForm: {
    message: 'Payment Form',
    tag: 'payment_form'
  },
  LogWSMerged: {
    message: 'Log Merged',
    tag: 'log_merged'
  },
  LogWSUpdateFromIDEPOS: {
    message: 'Log Update From IDEPOS',
    tag: 'log_update_from_idepos'
  },
  StoreInfo: {
    message: 'Log Store and qr Info',
    tag: 'log_store_and_qr_info'
  },
  PaymentMethodList: {
    message: 'Log Payment Methode',
    tag: 'log_payment_methode'
  }
}
