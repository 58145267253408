<button
  class="{{ btnClass }}  {{ size }}"
  [class.disabled]="disabled"
  [disabled]="disabled"
  [style.height]="height"
  [style.width]="width"
  [style.padding]="padding"
  [style.borderRadius]="borderRadius">
  <ng-content></ng-content>
</button>
