import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { EventService } from '@data-access/event/event.service';
import { CanComponentDeactivate } from '@guard/can-deactivate.guard';
import { TranslateModule } from '@ngx-translate/core'
import { IlQrlinkExpiredComponent } from '@ui-svg/il-qrlink-expired/il-qrlink-expired.component';
import { IlQrlinkNotHaveServiceComponent } from '@ui-svg/il-qrlink-not-have-service/il-qrlink-not-have-service.component'
import { IlQrlinkPaidComponent } from '@ui-svg/il-qrlink-paid/il-qrlink-paid.component'
import { ButtonComponent } from '@ui/button/button.component'

@Component({
  selector: 'app-contact-waiters',
  templateUrl: './contact-waiters.component.html',
  styleUrls: ['./contact-waiters.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IlQrlinkPaidComponent,
    IlQrlinkNotHaveServiceComponent,
    IlQrlinkExpiredComponent,
    TranslateModule
  ]
})
export class ContactWaitersComponent implements OnChanges, CanComponentDeactivate {
  @Input() isShowModal: boolean = false
  @Input() ill: string = ''
  @Input() title: string = ''
  @Input() desc: string = ''
  @Input() btnClose: boolean = false
  @Input() btnActionFlex: boolean = false
  @Output() close: EventEmitter<boolean> = new EventEmitter()
  @Output() confirm: EventEmitter<boolean> = new EventEmitter()

  preventBackHistory: boolean = false

  public constructor(
    private route: ActivatedRoute, 
    private _eventService: EventService
    ) {
    if (this.route.snapshot.data) {
      this.isShowModal = this.route.snapshot.data['isShowModal']
      this.ill = this.route.snapshot.data['ill']
      this.title = this.route.snapshot.data['title']
      this.desc = this.route.snapshot.data['desc']
      this.preventBackHistory = this.route.snapshot.data['preventBackHistory'] ?? false
    }
  }

  canDeactivate(): boolean {
    if (this.preventBackHistory) {
      return false;
    }
    return true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let showModal = changes['isShowModal']
    if (showModal && showModal.currentValue) this._eventService.scrollingEvent('not-scroll')
    if (showModal && !showModal.currentValue) this._eventService.scrollingEvent('scroll')
  }

  closeModal() {
    this.isShowModal = false;
    this._eventService.scrollingEvent('scroll')
  }

}
