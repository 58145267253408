import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'

interface Message {
  type: string
  payload: any
}

type MessageCallback = (payload: any) => void

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private handler = new Subject<Message>()

  constructor() {}

  broadcast(type: string, payload: any) {
    this.handler.next({ type, payload })
  }

  subscribe(type: string, callback: MessageCallback): Subscription {
    return this.handler.subscribe((result: Message) =>
      result.type === type ? callback(result.payload) : false
    )
  }
}
