import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-il-store-holiday',
  templateUrl: './il-store-holiday.component.html',
  standalone: true
})
export class IlStoreHolidayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
