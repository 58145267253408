import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataStorageService {
  isBrowser: boolean = false;
  isServer: boolean = false;
  private dataMap: Map<string, any> = new Map();

  constructor(
    @Inject(PLATFORM_ID) private platform: any,
    private _router: Router
  ) {
    this.isBrowser = isPlatformBrowser(this.platform);
    this.isServer = isPlatformServer(this.platform);
  }

  /**
   * @localstorage
   */

  setLocalStorage(key: string, value: string | object): void {
    if (this.isBrowser) {
      if (typeof value === 'object') {
        localStorage.setItem(key, JSON.stringify(value));
      } else if (typeof value === 'string') {
        localStorage.setItem(key, value);
      }
    }
  }

  getLocalStorage(key: string, isObject: boolean = false) {
    let result = '';
    if (this.isBrowser) {
      if (isObject) {
        result = JSON.parse(localStorage.getItem(key) ?? '{}') ?? null;
      } else {
        result = localStorage.getItem(key) ?? '';
      }
    }
    return result;
  }

  removeLocalStorage(key: string) {
    if (this.isBrowser) {
      localStorage.removeItem(key)
    }
  }
 
  updateLocalStorage(keyStorage: string, key: string, value: string) {
    let data = this.getLocalStorage(keyStorage, true) as any;
    if (data) {
      data[key] = value;
      this.setLocalStorage(keyStorage, data);
    } else {
      this.setLocalStorage(keyStorage, { [key]: value });
    }
  }

  getLocalStorageByKey(keyStorage: string, key: string) {
    let data = this.getLocalStorage(keyStorage, true) as any;
    if (data) {
      return data[key];
    }
    return null;
  }

  setData(key: string, value: any): void {
    this.dataMap.set(key, value);
  }

  getData(key: string): any {
    return this.dataMap.get(key);
  }

  public redirect(route: string): void {
    const storeSlug = this.getData('storeSlug-param');
    if (storeSlug) {
      this._router.navigate([`/${storeSlug}/${route}`]);
    } else {
      this._router.navigate([`/${route}`], {
        queryParams: { ...this.getData('queryParams') },
      });
    }
  }
}
