import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-il-store-closed',
  templateUrl: './il-store-closed.component.html',
  standalone: true
})
export class IlStoreNotOpenYetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
