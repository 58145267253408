import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { SsrCookieService } from 'ngx-cookie-service-ssr'

@Injectable()
export class AuthNotVerifyGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _cookieService: SsrCookieService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let authToken = this._cookieService.get(`authToken`)
    if (!authToken) return true

    if (route.queryParams['qr']) {
      this.router.navigate([`/profile`], {
        queryParams: route.queryParams
      })
    } else {
      this.router.navigate([`${route.params['storeSlug']}/profile`])
    }
    return false
  }
}
