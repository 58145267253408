import { Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CountryListUtil } from '../models/common';
import {
  CountryList,
  ROUNDING_DOWN,
  ROUNDING_MATH,
  ROUNDING_UP,
  RoundingNearestList,
  STATUS_ORDER_LIST,
  WEEKDAY
} from './const';
import { MerchantSetting } from './merchant-setting';

export function checkValidNumberPhone(value: string) {
  if (value) {
    let codePhone = value.substring(0, 2)
    let codePhonePrefix0 = value.substring(0, 1)
    if (codePhone !== '62' && codePhone !== '65' && codePhonePrefix0 !== '0')
      return true
  }
  return false
}

export function getCountryLocale(country: string): string {
  return CountryList.filter(
    (list: CountryListUtil) => list.countryId === country
  )[0].locale
}

export function getStatusOrder(status: number = 0): string {
  return (
    STATUS_ORDER_LIST.find(statusOrder => statusOrder.status === status)
      ?.info || '-'
  )
}

export function getUserPhone(phoneNumber: string, phoneCodeList?: any) {
  let countryCode = phoneNumber?.split('|')[0] ?? ('' as string)
  let phoneCode = phoneCodeList?.find((p: any) => p.country === countryCode)
    ?.value as string

  /**
   * Example
   * 1 digits code equal is 0
   * 2 digits code country use indonesia
   * 3 digits code country use cambodia
   */

  phoneNumber = phoneNumber?.split('|')[1]

  const firstDigit0 = '0' === phoneNumber?.slice(0, 1)
  const twoDigits = phoneCode === '62' && phoneCode === phoneNumber?.slice(0, 2)
  const threeDigits =
    phoneCode === '855' && phoneCode === phoneNumber?.slice(0, 3)
  if (twoDigits) {
    phoneNumber = phoneNumber?.slice(2)
  } else if (threeDigits) {
    phoneNumber = phoneNumber?.slice(3)
  } else if (firstDigit0) {
    phoneNumber = phoneNumber?.slice(1)
  }

  return {
    phoneNumber,
    phoneCode,
    countryCode
  }
}

/**
 * Rounding Method utility
 * @param value
 * @param roundingMethod
 * @param roundingNearest
 * @returns
 */
export function getRounding(
  value: number,
  roundingMethod: string,
  roundingNearest: string
) {
  const nearestIdx: number = RoundingNearestList.findIndex(
    near => near.code === roundingNearest
  )
  if (nearestIdx === -1) return
  let resultRounding: number = 0
  switch (roundingMethod) {
    case ROUNDING_MATH:
      resultRounding =
        Math.round(value / RoundingNearestList[nearestIdx].divider) *
        RoundingNearestList[nearestIdx].divider
      break
    case ROUNDING_UP:
      resultRounding =
        Math.ceil(value / RoundingNearestList[nearestIdx].divider) *
        RoundingNearestList[nearestIdx].divider
      break
    case ROUNDING_DOWN:
      resultRounding =
        Math.floor(value / RoundingNearestList[nearestIdx].divider) *
        RoundingNearestList[nearestIdx].divider
      break
  }

  return resultRounding
}

export function getDate() {
  const d = new Date()

  const current_time =
    d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()

  const day = WEEKDAY[d.getDay()]

  return {
    day,
    current_time,
    hour: d.getHours(),
    minute: d.getMinutes(),
    second: d.getSeconds(),
    timeStamp: d.getTime()
  }
}

export function frogByEnv() {
  let frogMerchantId = MerchantSetting.frog.prod.merchantId
  if (environment.local || environment.staging) {
    frogMerchantId = MerchantSetting.frog.stg.merchantId
  } else if (environment.uat) {
    frogMerchantId = MerchantSetting.frog.uat.merchantId
  } else if (environment.production) {
    frogMerchantId = MerchantSetting.frog.prod.merchantId
  }

  return frogMerchantId
}

export function gossipBarByEnv() {
  let data = MerchantSetting.gossipBar.prod
  if (environment.local || environment.staging) {
    data = MerchantSetting.gossipBar.stg
  } else if (environment.uat) {
    data = MerchantSetting.gossipBar.uat
  } else if (environment.production) {
    data = MerchantSetting.gossipBar.prod
  }

  return data
}

export function sabarMenantiByEnv(isTest: boolean = false) {
  let data = MerchantSetting.sabarMenanti.prod
  if (environment.local || environment.staging) {
    data = MerchantSetting.sabarMenanti.uat
  } else if (environment.uat) {
    data = MerchantSetting.sabarMenanti.uat
  } else if (environment.production) {
    data = MerchantSetting.sabarMenanti.prod
  } else if (environment.production && isTest) {
    data = MerchantSetting.sabarMenanti.prodTesting
  }
  
  return { ...data }
}

export function chungkyByEnv() {
  let data = MerchantSetting.chungky.prod
  if (environment.local || environment.staging) {
    data = MerchantSetting.chungky.uat
  } else if (environment.uat) {
    data = MerchantSetting.chungky.uat
  } else if (environment.production) {
    data = MerchantSetting.chungky.prod
  }

  return data
}

export function yogiyoByEnv() {
  let yogiyoMerchantId = MerchantSetting.yogiyo.prod.merchantId
  if (environment.local || environment.staging) {
    yogiyoMerchantId = MerchantSetting.yogiyo.uat.merchantId
  } else if (environment.uat) {
    yogiyoMerchantId = MerchantSetting.yogiyo.uat.merchantId
  } else if (environment.production) {
    yogiyoMerchantId = MerchantSetting.yogiyo.prod.merchantId
  }

  return yogiyoMerchantId
}

export function mrEggFiredRice() {
  let mrEggFiredRiceMerchantId = MerchantSetting.mrEggFiredRice.prod.merchantId
  if (environment.uat) {
    mrEggFiredRiceMerchantId = MerchantSetting.mrEggFiredRice.uat.merchantId
  } else if (environment.production) {
    mrEggFiredRiceMerchantId = MerchantSetting.mrEggFiredRice.prod.merchantId
  } else  if (environment.local) {
    mrEggFiredRiceMerchantId = MerchantSetting.mrEggFiredRice.uat.merchantId
  } 

  return mrEggFiredRiceMerchantId
}

export function ivinByEnv() {
  let data = MerchantSetting.ivin.prod
  if (environment.uat) {
    data = MerchantSetting.ivin.uat
  } else if (environment.production) {
    data = MerchantSetting.ivin.prod
  } else  if (environment.local) {
    data = MerchantSetting.ivin.uat
  } 

  return data
}

export function kitchenDelzafFoodByEnv() {
  let data = MerchantSetting.kitchenDelzafFood.prod
  if (environment.uat || environment.local) {
    data = MerchantSetting.kitchenDelzafFood.uat
  } else if (environment.production) {
    data = MerchantSetting.kitchenDelzafFood.prod
  }

  return data
}

export function charcoalgrillsgByEnv() {
  let data = MerchantSetting.charcoalgrillsg.prod
  if (environment.uat || environment.local) {
    data = MerchantSetting.charcoalgrillsg.uat
  } else if (environment.production) {
    data = MerchantSetting.charcoalgrillsg.prod
  }

  return data
}

export function bukchonKoreanRestaurant(merchantId: number) {
  let data = MerchantSetting.bukchonKoreanResto.prod
  if (environment.uat || environment.local) {
    if (merchantId === MerchantSetting.bukchonKoreanResto.uat2.merchantId) {
      data = MerchantSetting.bukchonKoreanResto.uat2
    } else if (merchantId === MerchantSetting.bukchonKoreanResto.uat3.merchantId) {
      data = MerchantSetting.bukchonKoreanResto.uat3
    } else {
      data = MerchantSetting.bukchonKoreanResto.uat1
    }
  } else if (environment.production) {
    data = MerchantSetting.bukchonKoreanResto.prod
  }

  return data
}

export function addScript(
  renderer2: Renderer2,
  dataText: any,
  dataSrc: any,
  async: boolean,
  document: Document,
  isHead: boolean = true
): void {
  let script = renderer2.createElement('script')
  if (dataText) script.text = dataText
  else if (dataSrc) script.src = dataSrc
  if (async) script.async = async

  const isExists = document.querySelector('script[src="' + dataSrc + '"]')

  if (isExists) return

  renderer2.appendChild((isHead ? document.head : document.body), script)
}

export function getEnvText(): string {
  if (environment.local) {
    return 'local'
  } else if (environment.staging) {
    return 'staging'
  } else if (environment.uat) {
    return 'uat'
  } else  {
    return 'production'
  } 
}
