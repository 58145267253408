import { createReducer, on } from '@ngrx/store'
import { QRInfo } from '../../models/domain.model'
import { qrInfoUpdate } from './qr-info.actions'

export const initialState: QRInfo = {
  storeSlug: '',
  template: '',
  serviceType: [],
  tableNumber: '',
  qrType: '',
  alreadyOrder: false,
  isMerged: false,
  isTableManagementActive: false,
}

export const qrInfoReducer = createReducer(
  initialState,
  on(qrInfoUpdate, (state, qrInfo) => ({
    ...state,
    ...qrInfo
  }))
)
