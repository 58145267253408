import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ButtonComponent } from '@ui/button/button.component'
import { StoreInfo } from '../../models/home.model'
import { IlStoreHolidayComponent } from '../../ui-svg/il-store-holiday/il-store-holiday.component'

@Component({
  selector: 'app-store-holiday',
  templateUrl: './store-holiday.component.html',
  styleUrls: ['./store-holiday.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IlStoreHolidayComponent,
    TranslateModule
  ]
})
export class StoreHolidayComponent {
  @Input() isShowModal: boolean = false
  @Input() storeInfo?: StoreInfo
}
