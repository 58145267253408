import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true
})
export class ButtonComponent implements OnInit {
  constructor() {}

  @Input() btnClass = 'btn-primary' // btn-primary, btn-secondary, btn-disabled
  @Input() size = 'medium' // medium, large, small
  @Input() disabled: boolean = false

  // Stylesheet List
  @Input() height: string = ''
  @Input() width: string = ''
  @Input() padding: string = ''
  @Input() borderRadius: string = ''

  ngOnInit(): void {}

  setDisabled(disabled: boolean) {
    this.disabled = disabled
  }
}
