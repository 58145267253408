import { TranslateService } from '@ngx-translate/core'
import { ViewService } from '../view/view.service'

export class InitialLanguageLoader {
  constructor(translate: TranslateService, viewService: ViewService) {
    return () => {
      let langCode = viewService.get('langCode') ?? 'en-US'

      const languages = ['en-US', 'id-ID', 'km-KH', 'zh-TW', 'zh-CN']

      if (!languages.includes(langCode)) {
        langCode = 'en-US'
        viewService.set('langCode', langCode)
      }

      translate.setDefaultLang(langCode)
      return translate.use(langCode).toPromise()
    }
  }
}
